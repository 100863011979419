import { Loader2Icon } from 'lucide-react'
import { useMemo } from 'react'

import { useGetCompany } from '@/api/company.hook'
import { useGetUserById } from '@/api/get-user-by-id.hook'

import { formatDate, timeSince } from '@/lib/date'
import { useIsProjectionDone } from '@/lib/is-projection-done.hook'
import { useLastIrqChange } from '@/lib/last-irq-change.hook'
import { cn } from '@/lib/style-helpers'

import { UserAvatar } from '@/components/user-avatar'

type UserScoreInfoProps = {
  companyId: string
  variant?: 'default' | 'compact'
  className?: string
}

export const UserScoreInfo = ({
  companyId,
  variant = 'default',
  className,
}: UserScoreInfoProps) => {
  const { data } = useGetCompany(companyId)
  const isProjectionDone = useIsProjectionDone(data?.company?.risk, data?.company?.calculatedRisk)
  const { lastIRQChange, isLoading: isLastIrqChangeLoading } = useLastIrqChange(companyId)

  const userResult = useGetUserById(lastIRQChange.userId)
  const isLoadingUser = 'isLoading' in userResult && userResult.isLoading
  const user = userResult.user

  const timeSinceLastScorer = useMemo(() => {
    if (!lastIRQChange.time) return null
    const { value, unit } = timeSince(lastIRQChange.time.toDate())
    return `${value} ${unit} ago`
  }, [lastIRQChange.time])

  if (isLastIrqChangeLoading || isLoadingUser) {
    return <Loader2Icon className='size-4 animate-spin' />
  }

  if (!isProjectionDone) {
    return (
      <div className={cn('flex items-center gap-1.5 text-xs text-gray-500', className)}>
        <Loader2Icon className='size-4 animate-spin duration-2000' />
        <span>Estimating inherent risk</span>
      </div>
    )
  }

  if (variant === 'compact') {
    return (
      <div className={cn('flex flex-col gap-0.5 text-nowrap text-sm', className)}>
        <UserScoreDisplay userId={lastIRQChange.userId} userName={user?.name} />
        <span className='text-gray-500'>{timeSinceLastScorer}</span>
      </div>
    )
  }

  return (
    <span className={cn('flex items-center gap-1.5 text-nowrap text-xs text-gray-500', className)}>
      <span className='text-nowrap'>Scored by</span>
      <UserScoreDisplay userId={lastIRQChange.userId} userName={user?.name} />
      <span className='text-nowrap'>
        on {lastIRQChange.time && formatDate(lastIRQChange.time.toDate(), 'shortDateTime')} (
        {timeSinceLastScorer})
      </span>
    </span>
  )
}

type UserScoreDisplayProps = {
  userId: string
  userName?: string
  className?: string
}

const UserScoreDisplay = ({ userId, userName, className }: UserScoreDisplayProps) => (
  <div className={cn('flex items-center gap-1', className)}>
    <UserAvatar userId={userId} className='!size-3 text-[6px]' withTooltip={false} />
    <span className='font-bold'>{userName}</span>
  </div>
)
