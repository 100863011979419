import { useCallback, useMemo } from 'react'

import { TableViews } from '@/components/table/table.type'

export type ViewOption = {
  name: string
  count: number
  label: string | React.ReactNode
}

export type TableViewsHook<T> = {
  viewAccessor: ((row: T) => boolean) | undefined
  viewOptions: ViewOption[]
}

export const useTableViews = <T>(
  tableViews: TableViews<T> | undefined,
  selectedView: string | undefined,
  dataSourceWithKey: T[],
): TableViewsHook<T> => {
  const getViewAccessor = useCallback(() => {
    if (selectedView && tableViews?.views?.[selectedView]) {
      return tableViews.views[selectedView].accessor
    }
    return undefined
  }, [selectedView, tableViews])

  const viewOptions = useMemo(() => {
    if (!tableViews?.views) return []

    return Object.entries(tableViews.views).map(([name, view]) => ({
      name,
      count: dataSourceWithKey.filter((row) => view.accessor(row)).length,
      label: view.label || name,
    }))
  }, [dataSourceWithKey, tableViews])

  return {
    viewAccessor: getViewAccessor(),
    viewOptions,
  }
}
