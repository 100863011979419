import { MenuItem } from '@/components/select/type'
import { SelectItem, SelectLabel } from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'

type ItemProps<T extends number | string> = {
  item: MenuItem<T>
}

export const Item = <T extends number | string>({ item }: ItemProps<T>) => {
  switch (item.type) {
    case 'menuSelectItem':
      return (
        <SelectItem
          className='whitespace-nowrap'
          value={item.value.toString()}
          description={item.description}
          disabled={item.disabled}
          isPotentialSelection={item.isPotentialSelection}
        >
          {item.label}
        </SelectItem>
      )
    case 'menuLabel':
      return <SelectLabel>{item.label}</SelectLabel>
    case 'menuSeparator':
      return <Separator />
    default:
      return null
  }
}
