import { cva } from 'class-variance-authority'
import { CheckIcon, SparklesIcon, XIcon } from 'lucide-react'

import { useListInherentRiskCategories } from '@/api/company.hook'
import { useOptimisticSetIRQItem } from '@/api/optimistic-set-irq.hook'
import {
  IRQChangeType,
  InherentRiskCategoryEnum,
  InherentRiskSource,
  SetIRQItemRequest,
} from '@/gen/inventory/v1/company_service_pb'

import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { cn } from '@/lib/style-helpers'

import { TextWithIcon } from '@/components/text-with-icon'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'

const subprocessorBadgeVariants = cva(
  'whitespace-nowrap rounded border-gray-200 bg-gray-100 p-1 font-medium text-gray-700',
  {
    variants: {
      kind: {
        default: 'border-none',
        suggested: 'border-dashed bg-white',
      },
      size: {
        default: 'h-5 text-xs',
        lg: 'h-6',
      },
    },
    defaultVariants: {
      kind: 'default',
      size: 'default',
    },
  },
)

export type SubprocessorBadgeProps = {
  kind?: 'default' | 'suggested'
  companyId: string
  size?: 'default' | 'lg'
}

export const SubprocessorBadge = ({
  kind = 'default',
  companyId,
  size = 'default',
}: SubprocessorBadgeProps) => {
  const isSuggested = kind === 'suggested'
  const { isEnabled: suggestedBadgeEnabled } = useFeatureFlagEnabled('suggested-subprocessor-badge')
  const { mutateAsync: updateIrq } = useOptimisticSetIRQItem(companyId)
  const { data: riskCategoryDefinitions } = useListInherentRiskCategories(companyId)

  if (isSuggested && !suggestedBadgeEnabled) {
    return null
  }

  const riskCategory = riskCategoryDefinitions
    ?.find((cat) => cat.categoryEnum === InherentRiskCategoryEnum.DATA_SUBPROCESSOR)
    ?.items.find((item) => item.displayName === 'Yes')

  const handleClick = (isAdd: boolean) => {
    const req: Partial<SetIRQItemRequest> = {
      riskCategoryId: riskCategory?.id,
      companyId,
      changeType: IRQChangeType.IRQ_CHANGE_TYPE_ADD,
      category: InherentRiskCategoryEnum.DATA,
      comment: '',
    }
    if (!isAdd) {
      // Setting the suggestion content to IGNORED hides the suggestion badge
      // This is a special case for the subprocessor badge
      req.content = 'IGNORED'
      req.source = InherentRiskSource.PROJECTED
    }

    updateIrq(req)
  }

  return (
    <Badge
      variant='outline'
      data-testid='subprocessor-badge'
      className={cn(
        subprocessorBadgeVariants({ kind, size }),
        'group/subprocessor flex justify-between transition-all duration-100',
      )}
    >
      <TextWithIcon
        text='Sub-processor'
        iconPosition='start'
        icon={isSuggested ? <SparklesIcon size={12} /> : undefined}
      />
      {isSuggested && (
        <div
          data-testid='subprocessor-badge-actions'
          className={cn(
            'flex items-center justify-end gap-0.5 overflow-hidden',
            'w-0 group-hover/subprocessor:w-8',
            'transition-[width] duration-100 ease-in-out',
          )}
        >
          <Button
            variant='ghost'
            size='icon'
            data-testid='subprocessor-badge-accept'
            className='size-3 justify-end'
            onClick={(e) => {
              e.stopPropagation()
              handleClick(true)
            }}
          >
            <CheckIcon className='text-green-500' size={12} />
          </Button>
          <Button
            variant='ghost'
            size='icon'
            data-testid='subprocessor-badge-reject'
            className='size-3 justify-end'
            onClick={(e) => {
              e.stopPropagation()
              handleClick(false)
            }}
          >
            <XIcon className='text-red-500' size={12} />
          </Button>
        </div>
      )}
    </Badge>
  )
}
