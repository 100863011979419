import { StringNameAvatar } from '@/components/string-name-avatar'
import { TextWithIcon } from '@/components/text-with-icon'

type ProjectBadgeProps = {
  projectName: string
}

export const ProjectBadge = ({ projectName }: ProjectBadgeProps) => {
  return (
    <TextWithIcon
      className='h-6 rounded bg-gray-100 px-2 py-1 text-md'
      icon={<StringNameAvatar name={projectName} className='size-4.5 rounded text-xs' />}
      text={projectName}
    />
  )
}
