import { useListProjects } from '@/api/assessments.hook'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { cn } from '@/lib/style-helpers'

import { ProjectBadge } from '@/components/badge/project'
import { CompanyVerificationBadge } from '@/components/badge/third-party-status'
import { SelectList } from '@/components/select-list'
import { Checkbox } from '@/components/ui/checkbox'

type AssessmentStepProps = {
  selectedStatus: CompanyStatus
  setSelectedStatus: (status: CompanyStatus) => void
  selectedProjectIds: string[]
  setSelectedProjectIds: (projectIds: string[]) => void
}

export const AssessmentStep = ({
  selectedStatus,
  setSelectedStatus,
  selectedProjectIds,
  setSelectedProjectIds,
}: AssessmentStepProps) => {
  const { projects } = useListProjects()

  return (
    <div>
      <div className='my-5'>
        <h2 className='font-semibold'>Set This Third-Party’s New Status</h2>

        <SelectList
          selectedValue={selectedStatus}
          onSelect={(value) => {
            setSelectedStatus(value)
            setSelectedProjectIds([])
          }}
          items={[
            {
              label: <CompanyVerificationBadge status={CompanyStatus.IN_ASSESSMENT} />,
              value: CompanyStatus.IN_ASSESSMENT,
            },
            {
              label: <CompanyVerificationBadge status={CompanyStatus.ASSESSMENT_REQUIRED} />,
              value: CompanyStatus.ASSESSMENT_REQUIRED,
            },
            {
              label: <CompanyVerificationBadge status={CompanyStatus.ASSESSED} />,
              value: CompanyStatus.ASSESSED,
            },
          ]}
        />
      </div>
      {projects.length > 0 && (
        <div className={cn(selectedStatus !== CompanyStatus.IN_ASSESSMENT && 'opacity-50')}>
          <h2 className='mb-2 font-semibold'>Select a project(s) for the assessment</h2>
          <div className='flex flex-col gap-3'>
            {projects.map((project) => (
              <div key={project.id} className='flex items-center gap-2'>
                <Checkbox
                  disabled={selectedStatus !== CompanyStatus.IN_ASSESSMENT}
                  checked={selectedProjectIds.includes(project.id)}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedProjectIds([...selectedProjectIds, project.id])
                    } else {
                      setSelectedProjectIds(selectedProjectIds.filter((id) => id !== project.id))
                    }
                  }}
                />
                <ProjectBadge projectName={project.name} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
