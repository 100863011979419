import { CheckIcon, Loader2Icon, SparklesIcon } from 'lucide-react'
import { useMemo } from 'react'

import { useGetCompany } from '@/api/company.hook'
import { useOptimisticSetIRQItem } from '@/api/optimistic-set-irq.hook'
import { IRQChangeType } from '@/gen/inventory/v1/company_service_pb'

import { useIsProjectionDone } from '@/lib/is-projection-done.hook'
import { useInherentRiskItems } from '@/lib/use-inherent-risk-items'

import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

type FillIrqSuggestedButtonProps = {
  companyId: string
}

export const FillIrqSuggestedButton = ({ companyId }: FillIrqSuggestedButtonProps) => {
  const { data: company } = useGetCompany(companyId)
  const isProjectionDone = useIsProjectionDone(
    company?.company?.risk,
    company?.company?.calculatedRisk,
  )
  const { mutateAsync: setIRQItem } = useOptimisticSetIRQItem(companyId)
  const { irqItems, lemaItems } = useInherentRiskItems(companyId)
  const hasSuggestedItems = useMemo(() => {
    const flatIrqItems = irqItems.flatMap(({ inherentRiskItems }) => inherentRiskItems)
    const flatLemaItems = lemaItems.flatMap(({ inherentRiskItems }) => inherentRiskItems)
    return flatLemaItems.some((item) => !flatIrqItems.some((lemaItem) => lemaItem.id === item.id))
  }, [irqItems, lemaItems])

  return (
    <Button
      size={'sm'}
      disabled={!hasSuggestedItems || !isProjectionDone}
      onClick={() => {
        lemaItems.forEach(({ inherentRiskItems, categoryEnum }) => {
          inherentRiskItems.forEach(({ id }) => {
            setIRQItem({
              changeType: IRQChangeType.IRQ_CHANGE_TYPE_ADD,
              companyId,
              riskCategoryId: id,
              category: categoryEnum,
            })
          })
        })
      }}
      variant={'outline'}
    >
      <TextWithIcon
        icon={
          <FillIrqSuggestedButtonIcon
            hasSuggestedItems={hasSuggestedItems}
            isProjectionDone={isProjectionDone}
          />
        }
        text='Fill Suggested'
      />
    </Button>
  )
}

type FillIrqSuggestedButtonIconProps = {
  hasSuggestedItems: boolean
  isProjectionDone: boolean
}

const FillIrqSuggestedButtonIcon = ({
  hasSuggestedItems,
  isProjectionDone,
}: FillIrqSuggestedButtonIconProps) => {
  if (!isProjectionDone) return <Loader2Icon className='animate-spin duration-2000' size={14} />
  return hasSuggestedItems ? <SparklesIcon size={14} /> : <CheckIcon size={14} />
}
