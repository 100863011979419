import { Company } from '@/gen/inventory/v1/company_service_pb'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { EmptyInventoryTable } from '@/pages/third-party-inventory/empty-inventory-table'
import { inventoryColumnsWithoutStatus } from '@/pages/third-party-inventory/inventory-columns'

import { Table } from '@/components/table/table'

export type DiscoveryTableProps = {
  inventory: Company[]
  onClick?: (thirdPartyId: string) => void
}

export const DiscoveryTable = ({ inventory, onClick }: DiscoveryTableProps) => {
  const onRow = ({ id }: Company) => ({
    onClick: () => onClick && id && onClick(id),
  })

  return (
    <Table
      pagination={{}}
      narrowFilterBar
      locale={{
        emptyText: <EmptyInventoryTable />,
      }}
      columns={inventoryColumnsWithoutStatus}
      dataSource={inventory.filter((company) => company.status === CompanyStatus.DISCOVERY)}
      onRow={onRow}
    />
  )
}
