import { type VariantProps, cva } from 'class-variance-authority'
import { useEffect, useState } from 'react'

import { cn } from '@/lib/style-helpers'
import { useUrlParams } from '@/lib/url-param.hook'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

import { TabItem, TabsProps, getTabItemIndex } from './types'

const triggerVariant = cva('w-full rounded-none border-transparent p-2', {
  variants: {
    variant: {
      default:
        'data-[state=active]:border-purple-600 border-r-2 data-[state=active]:text-purple-600',
      secondary: 'data-[state=active]:border-gray-600 data-[state=active]:bg-gray-100 rounded',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

const tabsListVariant = cva('flex-col items-start gap-4 rounded-none', {
  variants: {
    variant: {
      default: 'border-r',
      secondary: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

type VerticalTabsItem = string | TabItem

type VerticalTabsProps = TabsProps<VerticalTabsItem> &
  React.ComponentProps<typeof TabsList> &
  VariantProps<typeof triggerVariant>

export const VerticalTabs = ({
  items,
  urlParamName,
  labelAsTitle,
  titleSuffix,
  actionItem,
  variant,
  ...props
}: VerticalTabsProps) => {
  const { updateParam, urlParams } = useUrlParams(true)
  const defaultItem = items.find(
    (item): item is TabItem => typeof item !== 'string' && !item.itemDisabled,
  )
  const defaultIndex = defaultItem && getTabItemIndex(defaultItem)
  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined)

  // If the selected tab removed from the list, set the default tab
  useEffect(() => {
    if (
      selectedValue !== undefined &&
      !items
        .filter((item) => typeof item !== 'string')
        .some((item) => {
          const index = getTabItemIndex(item)
          return index === selectedValue
        })
    ) {
      setSelectedValue(defaultIndex)
    }
  }, [items, selectedValue, defaultIndex])

  return (
    <Tabs
      onValueChange={(value) => {
        setSelectedValue(value)
      }}
      value={(urlParamName && urlParams[urlParamName]) || selectedValue}
      defaultValue={defaultIndex}
    >
      {items.map((tabItem) => {
        if (typeof tabItem === 'string') {
          return null
        }
        const index = getTabItemIndex(tabItem)
        return (
          (labelAsTitle || titleSuffix) && (
            <TabsContent className='w-full' key={index} value={index}>
              <h2 className='mb-6 text-xl font-bold'>
                {labelAsTitle && tabItem.label}
                {titleSuffix}
              </h2>
            </TabsContent>
          )
        )
      })}
      <div className='flex items-start gap-12'>
        <TabsList {...props} className={cn(tabsListVariant({ variant }), props.className)}>
          {items.map((tabItem) => {
            if (typeof tabItem === 'string') {
              return (
                <div
                  key={tabItem}
                  className='px-2 text-xs font-bold uppercase tracking-widest text-gray-500'
                >
                  {tabItem}
                </div>
              )
            }
            const index = getTabItemIndex(tabItem)

            const { label, itemDisabled, labelIcon } = tabItem
            return (
              <TabsTrigger
                onClick={() => urlParamName && index && updateParam(urlParamName, index)}
                className={cn(triggerVariant({ variant }), { 'ml-5': tabItem.indented })}
                key={index}
                disabled={itemDisabled}
                value={index}
              >
                {labelIcon && <span className='mr-2'>{labelIcon}</span>}
                {label}
              </TabsTrigger>
            )
          })}
          {actionItem}
        </TabsList>
        {items.map((tabItem) => {
          if (typeof tabItem === 'string') {
            return null
          }
          const index = getTabItemIndex(tabItem)
          return (
            <TabsContent className='w-full' key={index} value={index}>
              {tabItem.component}
            </TabsContent>
          )
        })}
      </div>
    </Tabs>
  )
}
