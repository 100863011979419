import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  createProject,
  deleteProject,
  finalizeAssessment,
  getAssessment,
  getProject,
  getRoomByAssessmentId,
  listAssessmentCadenceSettings,
  listAssessments,
  listOngoingAssessments,
  listProjects,
  setAssessmentCadenceSetting,
  startAssessment,
  updateProjectName,
} from '@/gen/inventory/v1/assessment_service-AssessmentService_connectquery'
import { AssessmentInfo, Project } from '@/gen/inventory/v1/assessment_service_pb'
import {
  getCompany,
  getCompanyActivityLog,
  listCompanies,
} from '@/gen/inventory/v1/company_service-CompanyInventoryService_connectquery'
import { Company } from '@/gen/inventory/v1/company_service_pb'
import { listQuestionnaire } from '@/gen/questionnaire/v1/assessor_service-AssessorService_connectquery'

export const useListAssessmentCadenceSettings = () => {
  const queryState = useQuery(listAssessmentCadenceSettings.useQuery())

  return {
    ...queryState,
    settings: queryState.data?.settings || [],
  }
}

export const useSetAssessmentCadence = () => {
  const queryClient = useQueryClient()

  return useMutation(setAssessmentCadenceSetting.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getCompany.getQueryKey())
      queryClient.invalidateQueries(listCompanies.getQueryKey())
      queryClient.invalidateQueries(listAssessmentCadenceSettings.getQueryKey())
    },
  })
}
export const useFinalizeAssessment = () => {
  const queryClient = useQueryClient()

  return useMutation(
    finalizeAssessment.useMutation({
      callOptions: {
        timeoutMs: 30000, // 30 seconds
      },
    }).mutationFn,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(listAssessments.getQueryKey())
        queryClient.invalidateQueries(listOngoingAssessments.getQueryKey())
        queryClient.invalidateQueries(getCompany.getQueryKey())
        queryClient.invalidateQueries(listCompanies.getQueryKey())
        queryClient.invalidateQueries(getCompanyActivityLog.getQueryKey())
        queryClient.invalidateQueries(getRoomByAssessmentId.getQueryKey())
        queryClient.invalidateQueries(listQuestionnaire.getQueryKey())
      },
    },
  )
}

export const useStartAssessment = (companyId: Company['id']) => {
  const queryClient = useQueryClient()

  return useMutation(startAssessment.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listOngoingAssessments.getQueryKey({ companyId }))
      queryClient.invalidateQueries(listCompanies.getQueryKey())
    },
  })
}

export const useListOngoingAssessments = (companyId: Company['id']) => {
  const queryState = useQuery(listOngoingAssessments.useQuery({ companyId }))

  return {
    ...queryState,
    ongoingAssessments: queryState.data?.ongoingAssessments || [],
  }
}
export const useListAssessments = (companyId: string) => {
  const queryState = useQuery(listAssessments.useQuery({ companyId }))

  return {
    ...queryState,
    assessments: queryState.data?.assessments || [],
  }
}

export const useGetAssessment = (assessmentId?: AssessmentInfo['id']) => {
  const queryState = useQuery(getAssessment.useQuery({ assessmentId }))

  return {
    ...queryState,
    assessment: queryState.data?.assessment,
  }
}

export const useGetRoomByAssessmentId = (assessmentId: AssessmentInfo['id']) => {
  const queryState = useQuery(getRoomByAssessmentId.useQuery({ assessmentId }))
  return {
    ...queryState,
    roomId: queryState.data?.roomId,
  }
}

export const useListProjects = (withDeleted = false) => {
  const queryState = useQuery(listProjects.useQuery({ withDeleted }))

  return {
    ...queryState,
    projects: queryState.data?.projects || [],
  }
}

export const useGetProject = (projectId?: Project['id']) => {
  const queryState = useQuery({
    ...getProject.useQuery({ projectId }),
    enabled: !!projectId,
  })

  return {
    ...queryState,
    project: queryState.data?.project,
    controlScopes: queryState.data?.controlScopes,
    members: queryState.data?.project?.members,
  }
}

export const useCreateProject = () => {
  const queryClient = useQueryClient()

  return useMutation(createProject.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listProjects.getQueryKey())
      queryClient.invalidateQueries(listOngoingAssessments.getQueryKey())
    },
  })
}

export const useUpdateProjectName = () => {
  const queryClient = useQueryClient()

  return useMutation(updateProjectName.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listProjects.getQueryKey())
      queryClient.invalidateQueries(getProject.getQueryKey())
    },
  })
}

export const useDeleteProject = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteProject.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listProjects.getQueryKey())
      queryClient.invalidateQueries(getProject.getQueryKey())
    },
  })
}
