import { cva } from 'class-variance-authority'

export const buttonVariants = cva(
  'ring-offset-background focus-visible:ring-ring inline-flex items-center justify-center rounded font-light transition-colors focus-visible:outline-none focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'text-primary-foreground bg-gray-700 hover:bg-gray-700/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'active:bg-gray-200 bg-transparent hover:bg-accent border border-gray-300 hover:text-gray-700',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        text: 'hover:text-opacity-50 font-medium text-md text-gray-700',
        highlight:
          'text-white bg-gray-700 rounded-lg m-4 border-2 border-white drop-shadow-lg shadow-xl hover:bg-gray-950',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'rounded h-fit px-2 py-1 text-sm',
        md: 'py-1 h-8 px-3.75',
        lg: 'h-14 px-8 font-semibold',
        icon: 'size-10',
      },
      position: {
        default: '',
        sticky: 'sticky bottom-8 z-max',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      position: 'default',
    },
    compoundVariants: [
      {
        variant: 'text',
        size: 'default',
        className: 'p-0',
      },
    ],
  },
)
