import { createPromiseClient } from '@connectrpc/connect'
import { useTransport } from '@connectrpc/connect-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  AnalyzerService,
  getRecipe,
  listFactTypes,
  listRecipes,
  listSubcategories,
  updateArtifactSubcategory,
} from '@/gen/artifacts/analyzer/v1/analyzer_service-AnalyzerService_connectquery'
import { AnalyzeArtifactRequest } from '@/gen/artifacts/analyzer/v1/analyzer_service_pb'
import { listCompanyArtifact } from '@/gen/inventory/v1/artifact_service-ArtifactService_connectquery'
import { listUserSourcedArtifact } from '@/gen/inventory/v1/artifact_service-ArtifactService_connectquery'
import { listControls } from '@/gen/inventory/v1/control_service-ControlInventoryService_connectquery'

export const useListSubcategories = () => {
  return useQuery(listSubcategories.useQuery().queryKey, listSubcategories.useQuery().queryFn)
}

export const useListFactTypes = () => {
  return useQuery(listFactTypes.useQuery().queryKey, listFactTypes.useQuery().queryFn)
}

export const useListRecipes = () => {
  return useQuery(listRecipes.useQuery().queryKey, listRecipes.useQuery().queryFn)
}

export const useUpdateArtifactSubcategory = () => {
  const queryClient = useQueryClient()
  const opts = updateArtifactSubcategory.useMutation()
  return useMutation({
    ...opts,
    onSuccess: () => {
      queryClient.invalidateQueries(listUserSourcedArtifact.getQueryKey())
      queryClient.invalidateQueries(listCompanyArtifact.getQueryKey())
      queryClient.invalidateQueries(listControls.getQueryKey())
    },
  })
}

export const ManualRecipeId = 'Other...'
export const useGetRecipe = (recipeId: string) => {
  const queryState = useQuery({
    ...getRecipe.useQuery({ recipeId }),
    enabled: recipeId !== ManualRecipeId,
  })
  return {
    ...queryState,
    data: queryState.data?.recipe || null,
  }
}

export const useAnalyzeArtifact = () => {
  const transport = useTransport()
  const client = createPromiseClient(AnalyzerService, transport)

  return useMutation({
    mutationFn: async (request: Partial<AnalyzeArtifactRequest>) => {
      return client.analyzeArtifact(request)
    },
  })
}
