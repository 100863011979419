import { useState } from 'react'

export type TotalResultsContext = {
  totalResults: number
  setTotalResults: (totalResults: number) => void
}

export const useTotalResults = (): TotalResultsContext => {
  const [totalResults, setTotalResults] = useState<number>(0)

  return {
    totalResults,
    setTotalResults,
  }
}
