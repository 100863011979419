import { DotIcon, Loader2Icon, MessageCircle, MinusIcon } from 'lucide-react'

import { useOngoingQuestionnairesSummary } from '@/api/questionnaire-assessor.hook'

import { formatDate, relativeTimeFormatted } from '@/lib/date'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { AssessmentFlowTab } from '@/pages/assessment/assessment-flow-step-enum'
import { CompanyDrawerTabs } from '@/pages/company-drawer/company-drawer-tabs-enum'

type QuestionnairesProgressSummaryProps = {
  companyId: string
}

export const QuestionnairesProgressSummary = ({
  companyId,
}: QuestionnairesProgressSummaryProps) => {
  const { summary, isInitialLoading } = useOngoingQuestionnairesSummary(companyId)
  const { replaceParams } = useUrlParams()

  if (isInitialLoading) {
    return <Loader2Icon className='size-4 animate-spin' />
  }

  if (!summary) {
    return <MinusIcon className='text-gray-400' />
  }

  return (
    <div
      className='flex cursor-pointer items-center gap-4 rounded px-4 py-2 duration-200 hover:bg-gray-100'
      onClick={() =>
        replaceParams({
          update: [
            [UrlParam.COMPANY_DRAWER_TAB, CompanyDrawerTabs.ASSESSMENT],
            [UrlParam.STEP, AssessmentFlowTab.EVIDENCE],
          ],
        })
      }
    >
      <div className='flex flex-col gap-1'>
        <span className='text-nowrap'>
          {summary?.totalAnswered} / {summary?.totalQuestions} Answered
        </span>
        {summary?.dueDate && (
          <div className='text-nowrap text-sm text-gray-500'>
            Due {formatDate(summary?.dueDate?.toDate())} (
            {relativeTimeFormatted(summary?.dueDate?.toDate())})
          </div>
        )}
      </div>
      <OpenedThreadCount count={summary?.totalWaitingThreads} />
    </div>
  )
}

const OpenedThreadCount = ({ count }: { count: number }) => {
  if (count === 0) {
    return null
  }
  return (
    <div className='flex items-center gap-1 text-yellow-500'>
      <DotIcon size={13} strokeWidth={10} />
      <span className='text-warning-text'>{count}</span>
      <MessageCircle size={13} className='text-warning-icon' />
    </div>
  )
}
