// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/assessment_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration, Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { RiskLevel } from "./risk_pb.js";
import { User } from "../../customer/management/v1/service_pb.js";
import { AssessmentDecision } from "./assessment_decision_pb.js";
import { FindingCountStat, InherentRiskGroup } from "./company_service_pb.js";
import { ControlCountStat, GetControlResponse, GetControlTimelineResponse } from "./control_service_pb.js";
import { Artifact } from "./artifact_service_pb.js";
import { GetFindingResponse, GetFindingTimelineResponse } from "./finding_service_pb.js";

/**
 * @generated from enum inventory.v1.ProjectAttributeChangeType
 */
export enum ProjectAttributeChangeType {
  /**
   * @generated from enum value: PROJECT_ATTRIBUTE_CHANGE_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PROJECT_ATTRIBUTE_CHANGE_TYPE_ADD = 1;
   */
  ADD = 1,

  /**
   * @generated from enum value: PROJECT_ATTRIBUTE_CHANGE_TYPE_REMOVE = 2;
   */
  REMOVE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ProjectAttributeChangeType)
proto3.util.setEnumType(ProjectAttributeChangeType, "inventory.v1.ProjectAttributeChangeType", [
  { no: 0, name: "PROJECT_ATTRIBUTE_CHANGE_TYPE_UNSPECIFIED" },
  { no: 1, name: "PROJECT_ATTRIBUTE_CHANGE_TYPE_ADD" },
  { no: 2, name: "PROJECT_ATTRIBUTE_CHANGE_TYPE_REMOVE" },
]);

/**
 * @generated from enum inventory.v1.ScopeStatus
 */
export enum ScopeStatus {
  /**
   * @generated from enum value: SCOPE_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SCOPE_STATUS_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: SCOPE_STATUS_INACTIVE = 2;
   */
  INACTIVE = 2,

  /**
   * @generated from enum value: SCOPE_STATUS_UNAVAILABLE = 3;
   */
  UNAVAILABLE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ScopeStatus)
proto3.util.setEnumType(ScopeStatus, "inventory.v1.ScopeStatus", [
  { no: 0, name: "SCOPE_STATUS_UNSPECIFIED" },
  { no: 1, name: "SCOPE_STATUS_ACTIVE" },
  { no: 2, name: "SCOPE_STATUS_INACTIVE" },
  { no: 3, name: "SCOPE_STATUS_UNAVAILABLE" },
]);

/**
 * @generated from message inventory.v1.ListAssessmentCadenceSettingsRequest
 */
export class ListAssessmentCadenceSettingsRequest extends Message<ListAssessmentCadenceSettingsRequest> {
  constructor(data?: PartialMessage<ListAssessmentCadenceSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListAssessmentCadenceSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssessmentCadenceSettingsRequest {
    return new ListAssessmentCadenceSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssessmentCadenceSettingsRequest {
    return new ListAssessmentCadenceSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssessmentCadenceSettingsRequest {
    return new ListAssessmentCadenceSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssessmentCadenceSettingsRequest | PlainMessage<ListAssessmentCadenceSettingsRequest> | undefined, b: ListAssessmentCadenceSettingsRequest | PlainMessage<ListAssessmentCadenceSettingsRequest> | undefined): boolean {
    return proto3.util.equals(ListAssessmentCadenceSettingsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListAssessmentCadenceSettingsResponse
 */
export class ListAssessmentCadenceSettingsResponse extends Message<ListAssessmentCadenceSettingsResponse> {
  /**
   * @generated from field: repeated inventory.v1.AssessmentCadenceSetting settings = 1;
   */
  settings: AssessmentCadenceSetting[] = [];

  constructor(data?: PartialMessage<ListAssessmentCadenceSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListAssessmentCadenceSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settings", kind: "message", T: AssessmentCadenceSetting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssessmentCadenceSettingsResponse {
    return new ListAssessmentCadenceSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssessmentCadenceSettingsResponse {
    return new ListAssessmentCadenceSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssessmentCadenceSettingsResponse {
    return new ListAssessmentCadenceSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssessmentCadenceSettingsResponse | PlainMessage<ListAssessmentCadenceSettingsResponse> | undefined, b: ListAssessmentCadenceSettingsResponse | PlainMessage<ListAssessmentCadenceSettingsResponse> | undefined): boolean {
    return proto3.util.equals(ListAssessmentCadenceSettingsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.AssessmentCadenceSetting
 */
export class AssessmentCadenceSetting extends Message<AssessmentCadenceSetting> {
  /**
   * @generated from field: inventory.v1.RiskLevel risk_level = 1;
   */
  riskLevel = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: optional google.protobuf.Duration cadence = 2;
   */
  cadence?: Duration;

  constructor(data?: PartialMessage<AssessmentCadenceSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AssessmentCadenceSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "risk_level", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 2, name: "cadence", kind: "message", T: Duration, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentCadenceSetting {
    return new AssessmentCadenceSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentCadenceSetting {
    return new AssessmentCadenceSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentCadenceSetting {
    return new AssessmentCadenceSetting().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentCadenceSetting | PlainMessage<AssessmentCadenceSetting> | undefined, b: AssessmentCadenceSetting | PlainMessage<AssessmentCadenceSetting> | undefined): boolean {
    return proto3.util.equals(AssessmentCadenceSetting, a, b);
  }
}

/**
 * @generated from message inventory.v1.SetAssessmentCadenceSettingRequest
 */
export class SetAssessmentCadenceSettingRequest extends Message<SetAssessmentCadenceSettingRequest> {
  /**
   * @generated from field: inventory.v1.RiskLevel risk_level = 1;
   */
  riskLevel = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: optional google.protobuf.Duration cadence = 2;
   */
  cadence?: Duration;

  constructor(data?: PartialMessage<SetAssessmentCadenceSettingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.SetAssessmentCadenceSettingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "risk_level", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 2, name: "cadence", kind: "message", T: Duration, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetAssessmentCadenceSettingRequest {
    return new SetAssessmentCadenceSettingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetAssessmentCadenceSettingRequest {
    return new SetAssessmentCadenceSettingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetAssessmentCadenceSettingRequest {
    return new SetAssessmentCadenceSettingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetAssessmentCadenceSettingRequest | PlainMessage<SetAssessmentCadenceSettingRequest> | undefined, b: SetAssessmentCadenceSettingRequest | PlainMessage<SetAssessmentCadenceSettingRequest> | undefined): boolean {
    return proto3.util.equals(SetAssessmentCadenceSettingRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.SetAssessmentCadenceSettingResponse
 */
export class SetAssessmentCadenceSettingResponse extends Message<SetAssessmentCadenceSettingResponse> {
  constructor(data?: PartialMessage<SetAssessmentCadenceSettingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.SetAssessmentCadenceSettingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetAssessmentCadenceSettingResponse {
    return new SetAssessmentCadenceSettingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetAssessmentCadenceSettingResponse {
    return new SetAssessmentCadenceSettingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetAssessmentCadenceSettingResponse {
    return new SetAssessmentCadenceSettingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetAssessmentCadenceSettingResponse | PlainMessage<SetAssessmentCadenceSettingResponse> | undefined, b: SetAssessmentCadenceSettingResponse | PlainMessage<SetAssessmentCadenceSettingResponse> | undefined): boolean {
    return proto3.util.equals(SetAssessmentCadenceSettingResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListOngoingAssessmentsRequest
 */
export class ListOngoingAssessmentsRequest extends Message<ListOngoingAssessmentsRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<ListOngoingAssessmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListOngoingAssessmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOngoingAssessmentsRequest {
    return new ListOngoingAssessmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOngoingAssessmentsRequest {
    return new ListOngoingAssessmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOngoingAssessmentsRequest {
    return new ListOngoingAssessmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListOngoingAssessmentsRequest | PlainMessage<ListOngoingAssessmentsRequest> | undefined, b: ListOngoingAssessmentsRequest | PlainMessage<ListOngoingAssessmentsRequest> | undefined): boolean {
    return proto3.util.equals(ListOngoingAssessmentsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListOngoingAssessmentsResponse
 */
export class ListOngoingAssessmentsResponse extends Message<ListOngoingAssessmentsResponse> {
  /**
   * @generated from field: repeated inventory.v1.OngoingAssessment ongoing_assessments = 1;
   */
  ongoingAssessments: OngoingAssessment[] = [];

  constructor(data?: PartialMessage<ListOngoingAssessmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListOngoingAssessmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ongoing_assessments", kind: "message", T: OngoingAssessment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListOngoingAssessmentsResponse {
    return new ListOngoingAssessmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListOngoingAssessmentsResponse {
    return new ListOngoingAssessmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListOngoingAssessmentsResponse {
    return new ListOngoingAssessmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListOngoingAssessmentsResponse | PlainMessage<ListOngoingAssessmentsResponse> | undefined, b: ListOngoingAssessmentsResponse | PlainMessage<ListOngoingAssessmentsResponse> | undefined): boolean {
    return proto3.util.equals(ListOngoingAssessmentsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.OngoingAssessment
 */
export class OngoingAssessment extends Message<OngoingAssessment> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional string project_id = 2;
   */
  projectId?: string;

  /**
   * @generated from field: optional string project_name = 3;
   */
  projectName?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp completed_at = 4;
   */
  completedAt?: Timestamp;

  /**
   * @generated from field: optional string past_assessment_id = 5;
   */
  pastAssessmentId?: string;

  constructor(data?: PartialMessage<OngoingAssessment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.OngoingAssessment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "project_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "completed_at", kind: "message", T: Timestamp, opt: true },
    { no: 5, name: "past_assessment_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OngoingAssessment {
    return new OngoingAssessment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OngoingAssessment {
    return new OngoingAssessment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OngoingAssessment {
    return new OngoingAssessment().fromJsonString(jsonString, options);
  }

  static equals(a: OngoingAssessment | PlainMessage<OngoingAssessment> | undefined, b: OngoingAssessment | PlainMessage<OngoingAssessment> | undefined): boolean {
    return proto3.util.equals(OngoingAssessment, a, b);
  }
}

/**
 * @generated from message inventory.v1.StartAssessmentRequest
 */
export class StartAssessmentRequest extends Message<StartAssessmentRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * @generated from field: optional string project_id = 2;
   */
  projectId?: string;

  constructor(data?: PartialMessage<StartAssessmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.StartAssessmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartAssessmentRequest {
    return new StartAssessmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartAssessmentRequest {
    return new StartAssessmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartAssessmentRequest {
    return new StartAssessmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartAssessmentRequest | PlainMessage<StartAssessmentRequest> | undefined, b: StartAssessmentRequest | PlainMessage<StartAssessmentRequest> | undefined): boolean {
    return proto3.util.equals(StartAssessmentRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.StartAssessmentResponse
 */
export class StartAssessmentResponse extends Message<StartAssessmentResponse> {
  constructor(data?: PartialMessage<StartAssessmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.StartAssessmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartAssessmentResponse {
    return new StartAssessmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartAssessmentResponse {
    return new StartAssessmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartAssessmentResponse {
    return new StartAssessmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartAssessmentResponse | PlainMessage<StartAssessmentResponse> | undefined, b: StartAssessmentResponse | PlainMessage<StartAssessmentResponse> | undefined): boolean {
    return proto3.util.equals(StartAssessmentResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.SetProjectControlScopeRequest
 */
export class SetProjectControlScopeRequest extends Message<SetProjectControlScopeRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string scope_id = 2;
   */
  scopeId = "";

  /**
   * @generated from field: inventory.v1.ProjectAttributeChangeType change_type = 3;
   */
  changeType = ProjectAttributeChangeType.UNSPECIFIED;

  constructor(data?: PartialMessage<SetProjectControlScopeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.SetProjectControlScopeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "scope_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "change_type", kind: "enum", T: proto3.getEnumType(ProjectAttributeChangeType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetProjectControlScopeRequest {
    return new SetProjectControlScopeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetProjectControlScopeRequest {
    return new SetProjectControlScopeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetProjectControlScopeRequest {
    return new SetProjectControlScopeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetProjectControlScopeRequest | PlainMessage<SetProjectControlScopeRequest> | undefined, b: SetProjectControlScopeRequest | PlainMessage<SetProjectControlScopeRequest> | undefined): boolean {
    return proto3.util.equals(SetProjectControlScopeRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.SetProjectControlScopeResponse
 */
export class SetProjectControlScopeResponse extends Message<SetProjectControlScopeResponse> {
  constructor(data?: PartialMessage<SetProjectControlScopeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.SetProjectControlScopeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetProjectControlScopeResponse {
    return new SetProjectControlScopeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetProjectControlScopeResponse {
    return new SetProjectControlScopeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetProjectControlScopeResponse {
    return new SetProjectControlScopeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetProjectControlScopeResponse | PlainMessage<SetProjectControlScopeResponse> | undefined, b: SetProjectControlScopeResponse | PlainMessage<SetProjectControlScopeResponse> | undefined): boolean {
    return proto3.util.equals(SetProjectControlScopeResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.SetProjectMemberRequest
 */
export class SetProjectMemberRequest extends Message<SetProjectMemberRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: inventory.v1.ProjectAttributeChangeType change_type = 3;
   */
  changeType = ProjectAttributeChangeType.UNSPECIFIED;

  constructor(data?: PartialMessage<SetProjectMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.SetProjectMemberRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "change_type", kind: "enum", T: proto3.getEnumType(ProjectAttributeChangeType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetProjectMemberRequest {
    return new SetProjectMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetProjectMemberRequest {
    return new SetProjectMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetProjectMemberRequest {
    return new SetProjectMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetProjectMemberRequest | PlainMessage<SetProjectMemberRequest> | undefined, b: SetProjectMemberRequest | PlainMessage<SetProjectMemberRequest> | undefined): boolean {
    return proto3.util.equals(SetProjectMemberRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.SetProjectMemberResponse
 */
export class SetProjectMemberResponse extends Message<SetProjectMemberResponse> {
  constructor(data?: PartialMessage<SetProjectMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.SetProjectMemberResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetProjectMemberResponse {
    return new SetProjectMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetProjectMemberResponse {
    return new SetProjectMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetProjectMemberResponse {
    return new SetProjectMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetProjectMemberResponse | PlainMessage<SetProjectMemberResponse> | undefined, b: SetProjectMemberResponse | PlainMessage<SetProjectMemberResponse> | undefined): boolean {
    return proto3.util.equals(SetProjectMemberResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.RemoveProjectMemberRequest
 */
export class RemoveProjectMemberRequest extends Message<RemoveProjectMemberRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  constructor(data?: PartialMessage<RemoveProjectMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.RemoveProjectMemberRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveProjectMemberRequest {
    return new RemoveProjectMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveProjectMemberRequest {
    return new RemoveProjectMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveProjectMemberRequest {
    return new RemoveProjectMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveProjectMemberRequest | PlainMessage<RemoveProjectMemberRequest> | undefined, b: RemoveProjectMemberRequest | PlainMessage<RemoveProjectMemberRequest> | undefined): boolean {
    return proto3.util.equals(RemoveProjectMemberRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.RemoveProjectMemberResponse
 */
export class RemoveProjectMemberResponse extends Message<RemoveProjectMemberResponse> {
  constructor(data?: PartialMessage<RemoveProjectMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.RemoveProjectMemberResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveProjectMemberResponse {
    return new RemoveProjectMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveProjectMemberResponse {
    return new RemoveProjectMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveProjectMemberResponse {
    return new RemoveProjectMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveProjectMemberResponse | PlainMessage<RemoveProjectMemberResponse> | undefined, b: RemoveProjectMemberResponse | PlainMessage<RemoveProjectMemberResponse> | undefined): boolean {
    return proto3.util.equals(RemoveProjectMemberResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateProjectNameRequest
 */
export class UpdateProjectNameRequest extends Message<UpdateProjectNameRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  /**
   * @generated from field: string new_name = 2;
   */
  newName = "";

  constructor(data?: PartialMessage<UpdateProjectNameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateProjectNameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProjectNameRequest {
    return new UpdateProjectNameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProjectNameRequest {
    return new UpdateProjectNameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProjectNameRequest {
    return new UpdateProjectNameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProjectNameRequest | PlainMessage<UpdateProjectNameRequest> | undefined, b: UpdateProjectNameRequest | PlainMessage<UpdateProjectNameRequest> | undefined): boolean {
    return proto3.util.equals(UpdateProjectNameRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.UpdateProjectNameResponse
 */
export class UpdateProjectNameResponse extends Message<UpdateProjectNameResponse> {
  constructor(data?: PartialMessage<UpdateProjectNameResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.UpdateProjectNameResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProjectNameResponse {
    return new UpdateProjectNameResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProjectNameResponse {
    return new UpdateProjectNameResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProjectNameResponse {
    return new UpdateProjectNameResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProjectNameResponse | PlainMessage<UpdateProjectNameResponse> | undefined, b: UpdateProjectNameResponse | PlainMessage<UpdateProjectNameResponse> | undefined): boolean {
    return proto3.util.equals(UpdateProjectNameResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.CreateProjectRequest
 */
export class CreateProjectRequest extends Message<CreateProjectRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  constructor(data?: PartialMessage<CreateProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CreateProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProjectRequest {
    return new CreateProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProjectRequest {
    return new CreateProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProjectRequest {
    return new CreateProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProjectRequest | PlainMessage<CreateProjectRequest> | undefined, b: CreateProjectRequest | PlainMessage<CreateProjectRequest> | undefined): boolean {
    return proto3.util.equals(CreateProjectRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.CreateProjectResponse
 */
export class CreateProjectResponse extends Message<CreateProjectResponse> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<CreateProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.CreateProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateProjectResponse {
    return new CreateProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateProjectResponse {
    return new CreateProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateProjectResponse {
    return new CreateProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateProjectResponse | PlainMessage<CreateProjectResponse> | undefined, b: CreateProjectResponse | PlainMessage<CreateProjectResponse> | undefined): boolean {
    return proto3.util.equals(CreateProjectResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.DeleteProjectRequest
 */
export class DeleteProjectRequest extends Message<DeleteProjectRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<DeleteProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.DeleteProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProjectRequest {
    return new DeleteProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProjectRequest {
    return new DeleteProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteProjectRequest {
    return new DeleteProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteProjectRequest | PlainMessage<DeleteProjectRequest> | undefined, b: DeleteProjectRequest | PlainMessage<DeleteProjectRequest> | undefined): boolean {
    return proto3.util.equals(DeleteProjectRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.DeleteProjectResponse
 */
export class DeleteProjectResponse extends Message<DeleteProjectResponse> {
  constructor(data?: PartialMessage<DeleteProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.DeleteProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProjectResponse {
    return new DeleteProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProjectResponse {
    return new DeleteProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteProjectResponse {
    return new DeleteProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteProjectResponse | PlainMessage<DeleteProjectResponse> | undefined, b: DeleteProjectResponse | PlainMessage<DeleteProjectResponse> | undefined): boolean {
    return proto3.util.equals(DeleteProjectResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListProjectsRequest
 */
export class ListProjectsRequest extends Message<ListProjectsRequest> {
  /**
   * @generated from field: bool with_deleted = 1;
   */
  withDeleted = false;

  constructor(data?: PartialMessage<ListProjectsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListProjectsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "with_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProjectsRequest {
    return new ListProjectsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProjectsRequest {
    return new ListProjectsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProjectsRequest {
    return new ListProjectsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListProjectsRequest | PlainMessage<ListProjectsRequest> | undefined, b: ListProjectsRequest | PlainMessage<ListProjectsRequest> | undefined): boolean {
    return proto3.util.equals(ListProjectsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListProjectsResponse
 */
export class ListProjectsResponse extends Message<ListProjectsResponse> {
  /**
   * @generated from field: repeated inventory.v1.Project projects = 1;
   */
  projects: Project[] = [];

  constructor(data?: PartialMessage<ListProjectsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListProjectsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "projects", kind: "message", T: Project, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListProjectsResponse {
    return new ListProjectsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListProjectsResponse {
    return new ListProjectsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListProjectsResponse {
    return new ListProjectsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListProjectsResponse | PlainMessage<ListProjectsResponse> | undefined, b: ListProjectsResponse | PlainMessage<ListProjectsResponse> | undefined): boolean {
    return proto3.util.equals(ListProjectsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetProjectRequest
 */
export class GetProjectRequest extends Message<GetProjectRequest> {
  /**
   * @generated from field: string project_id = 1;
   */
  projectId = "";

  constructor(data?: PartialMessage<GetProjectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetProjectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectRequest {
    return new GetProjectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectRequest {
    return new GetProjectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectRequest {
    return new GetProjectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectRequest | PlainMessage<GetProjectRequest> | undefined, b: GetProjectRequest | PlainMessage<GetProjectRequest> | undefined): boolean {
    return proto3.util.equals(GetProjectRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetProjectResponse
 */
export class GetProjectResponse extends Message<GetProjectResponse> {
  /**
   * @generated from field: inventory.v1.Project project = 1;
   */
  project?: Project;

  /**
   * @generated from field: repeated inventory.v1.ControlScope control_scopes = 2;
   */
  controlScopes: ControlScope[] = [];

  constructor(data?: PartialMessage<GetProjectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetProjectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project", kind: "message", T: Project },
    { no: 2, name: "control_scopes", kind: "message", T: ControlScope, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectResponse {
    return new GetProjectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectResponse {
    return new GetProjectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectResponse {
    return new GetProjectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectResponse | PlainMessage<GetProjectResponse> | undefined, b: GetProjectResponse | PlainMessage<GetProjectResponse> | undefined): boolean {
    return proto3.util.equals(GetProjectResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.Project
 */
export class Project extends Message<Project> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated customer.management.v1.User members = 3;
   */
  members: User[] = [];

  constructor(data?: PartialMessage<Project>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Project";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "members", kind: "message", T: User, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Project {
    return new Project().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Project {
    return new Project().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Project {
    return new Project().fromJsonString(jsonString, options);
  }

  static equals(a: Project | PlainMessage<Project> | undefined, b: Project | PlainMessage<Project> | undefined): boolean {
    return proto3.util.equals(Project, a, b);
  }
}

/**
 * @generated from message inventory.v1.ControlScope
 */
export class ControlScope extends Message<ControlScope> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: inventory.v1.ScopeStatus status = 3;
   */
  status = ScopeStatus.UNSPECIFIED;

  constructor(data?: PartialMessage<ControlScope>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ControlScope";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(ScopeStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ControlScope {
    return new ControlScope().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ControlScope {
    return new ControlScope().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ControlScope {
    return new ControlScope().fromJsonString(jsonString, options);
  }

  static equals(a: ControlScope | PlainMessage<ControlScope> | undefined, b: ControlScope | PlainMessage<ControlScope> | undefined): boolean {
    return proto3.util.equals(ControlScope, a, b);
  }
}

/**
 * @generated from message inventory.v1.FinalizeAssessmentRequest
 */
export class FinalizeAssessmentRequest extends Message<FinalizeAssessmentRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  /**
   * @generated from field: inventory.v1.AssessmentDecision decision = 2;
   */
  decision = AssessmentDecision.UNSPECIFIED;

  /**
   * @generated from field: optional string comment = 3;
   */
  comment?: string;

  /**
   * @generated from field: string assessment_id = 4;
   */
  assessmentId = "";

  constructor(data?: PartialMessage<FinalizeAssessmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FinalizeAssessmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "decision", kind: "enum", T: proto3.getEnumType(AssessmentDecision) },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "assessment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinalizeAssessmentRequest {
    return new FinalizeAssessmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinalizeAssessmentRequest {
    return new FinalizeAssessmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinalizeAssessmentRequest {
    return new FinalizeAssessmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FinalizeAssessmentRequest | PlainMessage<FinalizeAssessmentRequest> | undefined, b: FinalizeAssessmentRequest | PlainMessage<FinalizeAssessmentRequest> | undefined): boolean {
    return proto3.util.equals(FinalizeAssessmentRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.FinalizeAssessmentResponse
 */
export class FinalizeAssessmentResponse extends Message<FinalizeAssessmentResponse> {
  /**
   * @generated from field: bool has_rejected_assessment = 1;
   */
  hasRejectedAssessment = false;

  /**
   * @generated from field: bool is_final_assessment = 2;
   */
  isFinalAssessment = false;

  constructor(data?: PartialMessage<FinalizeAssessmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.FinalizeAssessmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "has_rejected_assessment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "is_final_assessment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FinalizeAssessmentResponse {
    return new FinalizeAssessmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FinalizeAssessmentResponse {
    return new FinalizeAssessmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FinalizeAssessmentResponse {
    return new FinalizeAssessmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FinalizeAssessmentResponse | PlainMessage<FinalizeAssessmentResponse> | undefined, b: FinalizeAssessmentResponse | PlainMessage<FinalizeAssessmentResponse> | undefined): boolean {
    return proto3.util.equals(FinalizeAssessmentResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListAssessmentsRequest
 */
export class ListAssessmentsRequest extends Message<ListAssessmentsRequest> {
  /**
   * @generated from field: string company_id = 1;
   */
  companyId = "";

  constructor(data?: PartialMessage<ListAssessmentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListAssessmentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssessmentsRequest {
    return new ListAssessmentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssessmentsRequest {
    return new ListAssessmentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssessmentsRequest {
    return new ListAssessmentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssessmentsRequest | PlainMessage<ListAssessmentsRequest> | undefined, b: ListAssessmentsRequest | PlainMessage<ListAssessmentsRequest> | undefined): boolean {
    return proto3.util.equals(ListAssessmentsRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.ListAssessmentsResponse
 */
export class ListAssessmentsResponse extends Message<ListAssessmentsResponse> {
  /**
   * @generated from field: repeated inventory.v1.AssessmentInfo assessments = 1;
   */
  assessments: AssessmentInfo[] = [];

  constructor(data?: PartialMessage<ListAssessmentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.ListAssessmentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessments", kind: "message", T: AssessmentInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListAssessmentsResponse {
    return new ListAssessmentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListAssessmentsResponse {
    return new ListAssessmentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListAssessmentsResponse {
    return new ListAssessmentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListAssessmentsResponse | PlainMessage<ListAssessmentsResponse> | undefined, b: ListAssessmentsResponse | PlainMessage<ListAssessmentsResponse> | undefined): boolean {
    return proto3.util.equals(ListAssessmentsResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetAssessmentRequest
 */
export class GetAssessmentRequest extends Message<GetAssessmentRequest> {
  /**
   * @generated from field: string assessment_id = 1;
   */
  assessmentId = "";

  constructor(data?: PartialMessage<GetAssessmentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetAssessmentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssessmentRequest {
    return new GetAssessmentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssessmentRequest {
    return new GetAssessmentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssessmentRequest {
    return new GetAssessmentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssessmentRequest | PlainMessage<GetAssessmentRequest> | undefined, b: GetAssessmentRequest | PlainMessage<GetAssessmentRequest> | undefined): boolean {
    return proto3.util.equals(GetAssessmentRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetAssessmentResponse
 */
export class GetAssessmentResponse extends Message<GetAssessmentResponse> {
  /**
   * @generated from field: inventory.v1.Assessment assessment = 1;
   */
  assessment?: Assessment;

  constructor(data?: PartialMessage<GetAssessmentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetAssessmentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment", kind: "message", T: Assessment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAssessmentResponse {
    return new GetAssessmentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAssessmentResponse {
    return new GetAssessmentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAssessmentResponse {
    return new GetAssessmentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAssessmentResponse | PlainMessage<GetAssessmentResponse> | undefined, b: GetAssessmentResponse | PlainMessage<GetAssessmentResponse> | undefined): boolean {
    return proto3.util.equals(GetAssessmentResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.AssessmentInfo
 */
export class AssessmentInfo extends Message<AssessmentInfo> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string version = 2;
   */
  version = "";

  /**
   * @generated from field: inventory.v1.RiskLevel risk_level = 3;
   */
  riskLevel = RiskLevel.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: inventory.v1.AssessmentDecision decision = 5;
   */
  decision = AssessmentDecision.UNSPECIFIED;

  /**
   * @generated from field: repeated customer.management.v1.User authors = 6;
   */
  authors: User[] = [];

  /**
   * @generated from field: repeated inventory.v1.FindingCountStat findings_by_level = 7;
   */
  findingsByLevel: FindingCountStat[] = [];

  /**
   * @generated from field: repeated inventory.v1.ControlCountStat controls_by_status = 8;
   */
  controlsByStatus: ControlCountStat[] = [];

  /**
   * @generated from field: string company_id = 9;
   */
  companyId = "";

  /**
   * @generated from field: bytes notes_ydoc = 10;
   */
  notesYdoc = new Uint8Array(0);

  /**
   * @generated from field: optional string project_name = 11;
   */
  projectName?: string;

  constructor(data?: PartialMessage<AssessmentInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AssessmentInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "risk_level", kind: "enum", T: proto3.getEnumType(RiskLevel) },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "decision", kind: "enum", T: proto3.getEnumType(AssessmentDecision) },
    { no: 6, name: "authors", kind: "message", T: User, repeated: true },
    { no: 7, name: "findings_by_level", kind: "message", T: FindingCountStat, repeated: true },
    { no: 8, name: "controls_by_status", kind: "message", T: ControlCountStat, repeated: true },
    { no: 9, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "notes_ydoc", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 11, name: "project_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentInfo {
    return new AssessmentInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentInfo {
    return new AssessmentInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentInfo {
    return new AssessmentInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentInfo | PlainMessage<AssessmentInfo> | undefined, b: AssessmentInfo | PlainMessage<AssessmentInfo> | undefined): boolean {
    return proto3.util.equals(AssessmentInfo, a, b);
  }
}

/**
 * @generated from message inventory.v1.Assessment
 */
export class Assessment extends Message<Assessment> {
  /**
   * @generated from field: inventory.v1.AssessmentInfo info = 1;
   */
  info?: AssessmentInfo;

  /**
   * @generated from field: repeated inventory.v1.AssessmentFinding assessment_findings = 2;
   */
  assessmentFindings: AssessmentFinding[] = [];

  /**
   * @generated from field: repeated inventory.v1.AssessmentControl assessment_controls = 3;
   */
  assessmentControls: AssessmentControl[] = [];

  /**
   * @generated from field: repeated inventory.v1.Artifact artifacts = 4;
   */
  artifacts: Artifact[] = [];

  /**
   * @generated from field: repeated inventory.v1.InherentRiskGroup inherent_risk_groups = 5;
   */
  inherentRiskGroups: InherentRiskGroup[] = [];

  constructor(data?: PartialMessage<Assessment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.Assessment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "info", kind: "message", T: AssessmentInfo },
    { no: 2, name: "assessment_findings", kind: "message", T: AssessmentFinding, repeated: true },
    { no: 3, name: "assessment_controls", kind: "message", T: AssessmentControl, repeated: true },
    { no: 4, name: "artifacts", kind: "message", T: Artifact, repeated: true },
    { no: 5, name: "inherent_risk_groups", kind: "message", T: InherentRiskGroup, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Assessment {
    return new Assessment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Assessment {
    return new Assessment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Assessment {
    return new Assessment().fromJsonString(jsonString, options);
  }

  static equals(a: Assessment | PlainMessage<Assessment> | undefined, b: Assessment | PlainMessage<Assessment> | undefined): boolean {
    return proto3.util.equals(Assessment, a, b);
  }
}

/**
 * @generated from message inventory.v1.AssessmentFinding
 */
export class AssessmentFinding extends Message<AssessmentFinding> {
  /**
   * @generated from field: inventory.v1.GetFindingResponse finding = 1;
   */
  finding?: GetFindingResponse;

  /**
   * @generated from field: inventory.v1.GetFindingTimelineResponse timeline = 2;
   */
  timeline?: GetFindingTimelineResponse;

  constructor(data?: PartialMessage<AssessmentFinding>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AssessmentFinding";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "finding", kind: "message", T: GetFindingResponse },
    { no: 2, name: "timeline", kind: "message", T: GetFindingTimelineResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentFinding {
    return new AssessmentFinding().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentFinding {
    return new AssessmentFinding().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentFinding {
    return new AssessmentFinding().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentFinding | PlainMessage<AssessmentFinding> | undefined, b: AssessmentFinding | PlainMessage<AssessmentFinding> | undefined): boolean {
    return proto3.util.equals(AssessmentFinding, a, b);
  }
}

/**
 * @generated from message inventory.v1.AssessmentControl
 */
export class AssessmentControl extends Message<AssessmentControl> {
  /**
   * @generated from field: inventory.v1.GetControlResponse control = 1;
   */
  control?: GetControlResponse;

  /**
   * @generated from field: inventory.v1.GetControlTimelineResponse timeline = 2;
   */
  timeline?: GetControlTimelineResponse;

  constructor(data?: PartialMessage<AssessmentControl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.AssessmentControl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "control", kind: "message", T: GetControlResponse },
    { no: 2, name: "timeline", kind: "message", T: GetControlTimelineResponse },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssessmentControl {
    return new AssessmentControl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssessmentControl {
    return new AssessmentControl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssessmentControl {
    return new AssessmentControl().fromJsonString(jsonString, options);
  }

  static equals(a: AssessmentControl | PlainMessage<AssessmentControl> | undefined, b: AssessmentControl | PlainMessage<AssessmentControl> | undefined): boolean {
    return proto3.util.equals(AssessmentControl, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetRoomByAssessmentIdRequest
 */
export class GetRoomByAssessmentIdRequest extends Message<GetRoomByAssessmentIdRequest> {
  /**
   * @generated from field: string assessment_id = 1;
   */
  assessmentId = "";

  constructor(data?: PartialMessage<GetRoomByAssessmentIdRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetRoomByAssessmentIdRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "assessment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoomByAssessmentIdRequest {
    return new GetRoomByAssessmentIdRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoomByAssessmentIdRequest {
    return new GetRoomByAssessmentIdRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoomByAssessmentIdRequest {
    return new GetRoomByAssessmentIdRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoomByAssessmentIdRequest | PlainMessage<GetRoomByAssessmentIdRequest> | undefined, b: GetRoomByAssessmentIdRequest | PlainMessage<GetRoomByAssessmentIdRequest> | undefined): boolean {
    return proto3.util.equals(GetRoomByAssessmentIdRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetRoomByAssessmentIdResponse
 */
export class GetRoomByAssessmentIdResponse extends Message<GetRoomByAssessmentIdResponse> {
  /**
   * @generated from field: string room_id = 1;
   */
  roomId = "";

  constructor(data?: PartialMessage<GetRoomByAssessmentIdResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetRoomByAssessmentIdResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRoomByAssessmentIdResponse {
    return new GetRoomByAssessmentIdResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRoomByAssessmentIdResponse {
    return new GetRoomByAssessmentIdResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRoomByAssessmentIdResponse {
    return new GetRoomByAssessmentIdResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetRoomByAssessmentIdResponse | PlainMessage<GetRoomByAssessmentIdResponse> | undefined, b: GetRoomByAssessmentIdResponse | PlainMessage<GetRoomByAssessmentIdResponse> | undefined): boolean {
    return proto3.util.equals(GetRoomByAssessmentIdResponse, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetTokenRequest
 */
export class GetTokenRequest extends Message<GetTokenRequest> {
  constructor(data?: PartialMessage<GetTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTokenRequest {
    return new GetTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTokenRequest {
    return new GetTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTokenRequest {
    return new GetTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetTokenRequest | PlainMessage<GetTokenRequest> | undefined, b: GetTokenRequest | PlainMessage<GetTokenRequest> | undefined): boolean {
    return proto3.util.equals(GetTokenRequest, a, b);
  }
}

/**
 * @generated from message inventory.v1.GetTokenResponse
 */
export class GetTokenResponse extends Message<GetTokenResponse> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<GetTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "inventory.v1.GetTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTokenResponse {
    return new GetTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTokenResponse {
    return new GetTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTokenResponse {
    return new GetTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTokenResponse | PlainMessage<GetTokenResponse> | undefined, b: GetTokenResponse | PlainMessage<GetTokenResponse> | undefined): boolean {
    return proto3.util.equals(GetTokenResponse, a, b);
  }
}

