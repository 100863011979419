import { useCallback, useEffect, useRef } from 'react'

import { Filter } from './filter/bar'
import { SetSelectedFn } from './filter/filter.hook'

export function useDefaultFilters(filters: Filter[], setSelected: SetSelectedFn) {
  const defaultFiltersApplied = useRef(false)
  const applyDefaultFilters = useCallback(() => {
    if (defaultFiltersApplied.current || !filters.length) return

    filters.forEach((filter) => {
      if (filter.defaultSelected?.length) {
        filter.defaultSelected.forEach((value) => {
          setSelected(filter.key, value, true, filter.optionRender, filter.filterType)
          defaultFiltersApplied.current = true
        })
      }
    })
  }, [filters, setSelected])

  useEffect(() => {
    applyDefaultFilters()
  }, [applyDefaultFilters])
}
