import { TransportProvider } from '@connectrpc/connect-query'
import { AuthProvider, useDescope, useSession, useUser } from '@descope/react-sdk'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import { Suspense, lazy, useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

import { useTrackUser } from '@/lib/analytics/track-user.hook'
import { useOrgIdFromToken } from '@/lib/auth/orgid.hook'
import { getConnectTransport } from '@/lib/connect-transport'
import { useFeatureFlagEnabled } from '@/lib/featureflag'

import { Tab } from '@/components/navigation-bar/link-tabs'
import { NavigationBar } from '@/components/navigation-bar/navigation-bar'

import { useIsSupportUser } from './lib/support-user'

const QuestionnaireDrawer = lazy(() =>
  import('@/pages/drawers/questionnaire-drawer').then((module) => ({
    default: module.QuestionnaireDrawer,
  })),
)
const ControlDrawer = lazy(() =>
  import('@/pages/drawers/control-drawer').then((module) => ({ default: module.ControlDrawer })),
)
const FindingDrawer = lazy(() =>
  import('@/pages/drawers/finding-drawer').then((module) => ({ default: module.FindingDrawer })),
)
const ThirdPartyActivityLogDrawer = lazy(() =>
  import('@/pages/drawers/third-party-activity-log-drawer').then((module) => ({
    default: module.ThirdPartyActivityLogDrawer,
  })),
)
const ThirdPartyDrawer = lazy(() =>
  import('@/pages/drawers/third-party-drawer').then((module) => ({
    default: module.ThirdPartyDrawer,
  })),
)
const FindingInventory = lazy(() =>
  import('@/pages/finding-inventory/finding-inventory').then((module) => ({
    default: module.FindingInventory,
  })),
)
const PastAssessment = lazy(() =>
  import('@/pages/past-assessment/past-assessment').then((module) => ({
    default: module.PastAssessment,
  })),
)
const QuestionnaireTemplate = lazy(() =>
  import('@/pages/questionnaire-template/questionnaire-template').then((module) => ({
    default: module.QuestionnaireTemplate,
  })),
)
const AssessorQuestionnaire = lazy(() =>
  import('@/pages/questionnaire/assessor-questionnaire').then((module) => ({
    default: module.AssessorQuestionnaire,
  })),
)
const RecipientQuestionnaires = lazy(() =>
  import('@/pages/questionnaire/recipient-questionnaires').then((module) => ({
    default: module.RecipientQuestionnaires,
  })),
)

const LoginPage = lazy(() =>
  import('@/pages/login-page').then((module) => ({ default: module.LoginPage })),
)
const DashboardPage = lazy(() =>
  import('@/pages/dashboard/dashboard-page').then((module) => ({ default: module.DashboardPage })),
)
const QuestionnaireManagementPage = lazy(() =>
  import('@/pages/questionnaire-management/questionnaire-management-page').then((module) => ({
    default: module.QuestionnaireManagementPage,
  })),
)
const RecipeEditorPage = lazy(() =>
  import('@/pages/recipe-editor/recipe-editor-page').then((module) => ({
    default: module.RecipeEditorPage,
  })),
)
const IntegrationSetupPage = lazy(() =>
  import('@/pages/settings-page/integration-section/integration-setup').then((module) => ({
    default: module.IntegrationSetupPage,
  })),
)
const IntegrationsMarketplace = lazy(() =>
  import('@/pages/settings-page/integration-section/integrations-marketplace').then((module) => ({
    default: module.IntegrationsMarketplace,
  })),
)
const SettingsPage = lazy(() =>
  import('@/pages/settings-page/settings-page').then((module) => ({
    default: module.SettingsPage,
  })),
)
const ThirdPartyInventoryPage = lazy(() =>
  import('@/pages/third-party-inventory/inventory-page').then((module) => ({
    default: module.ThirdPartyInventoryPage,
  })),
)

const descopeProjectID = import.meta.env.VITE_DESCOPE_PROJECTID
const descopeBaseUrl = import.meta.env.VITE_DESCOPE_BASE_URL
const googleClientId = import.meta.env.VITE_GOOGLE_DRIVE_WEB_CLIENT_ID
const env = import.meta.env.VITE_CGX_ENV

const suspensifyPage = (page: React.ReactNode) => <Suspense fallback={<></>}>{page}</Suspense>

export const App = () => {
  const { isEnabled: questionnaireModuleEnabled } = useFeatureFlagEnabled('questionnaire-module')
  const { isEnabled: dashboardEnabled } = useFeatureFlagEnabled('dashboard')
  const lemaAppTabs: Tab[] = [
    {
      link: '/dashboard',
      title: 'Dashboard',
      component: suspensifyPage(<DashboardPage />),
      isVisible: dashboardEnabled,
    },
    {
      link: '/inventory',
      title: 'Inventory',
      component: suspensifyPage(<ThirdPartyInventoryPage />),
    },
    {
      link: '/findings',
      title: 'Findings',
      component: suspensifyPage(<FindingInventory />),
    },
    {
      link: '/questionnaires',
      title: 'Questionnaires',
      component: suspensifyPage(<QuestionnaireManagementPage />),
      isVisible: questionnaireModuleEnabled,
    },
  ]

  return (
    <div className='min-w-fit'>
      <Routes>
        <Route
          key={'lema-app'}
          element={
            <LemaAppAuthProviders>
              <LemaAppAuthTrackProvider>
                <>
                  <SupportUserBanner />
                  <Outlet />
                </>
              </LemaAppAuthTrackProvider>
            </LemaAppAuthProviders>
          }
        >
          <Route
            key={'login'}
            path='/login'
            element={suspensifyPage(<LoginPage authenticatedPath='/inventory' />)}
          />
          <Route
            key={'past-assessment'}
            path='past-assessment/:assessmentId'
            element={
              <>
                <RedirectToLogin loginPath='/login' />
                {suspensifyPage(<PastAssessment />)}
              </>
            }
          />
          <Route
            key={'lema-app-authenticated'}
            element={
              <>
                <RedirectToLogin loginPath='/login' />
                <NavigationBar tabs={lemaAppTabs} />
                <Outlet />
                {suspensifyPage(<FindingDrawer />)}
                {suspensifyPage(<ControlDrawer />)}
                {suspensifyPage(<ThirdPartyDrawer />)}
                {suspensifyPage(<ThirdPartyActivityLogDrawer />)}
                {suspensifyPage(<QuestionnaireDrawer />)}
              </>
            }
          >
            {lemaAppTabs.map((tab) => (
              <Route key={tab.link} path={tab.link} element={tab.component} />
            ))}
            <Route key={'settings'} path={'/settings'} element={suspensifyPage(<SettingsPage />)} />
            {env !== 'production' && (
              <Route
                key={'recipe'}
                path={'/recipe'}
                element={suspensifyPage(<RecipeEditorPage />)}
              />
            )}
            <Route
              key={'integrations-marketplace'}
              path='/integrations/marketplace'
              element={suspensifyPage(<IntegrationsMarketplace />)}
            />
            <Route
              key={'integrations'}
              path='integrations/:integrationId'
              element={suspensifyPage(<IntegrationSetupPage />)}
            />
            <Route
              key={'template'}
              path='questionnaires/templates/:templateId'
              element={suspensifyPage(<QuestionnaireTemplate />)}
            />
            <Route
              key={'questionnaires-room'}
              path='questionnaires/:questionnaireId'
              element={suspensifyPage(<AssessorQuestionnaire />)}
            />
          </Route>
        </Route>
        <Route
          key={'questionnaire'}
          path='/q/*'
          element={
            <TransportProvider transport={getConnectTransport({})}>
              {suspensifyPage(<RecipientQuestionnaires />)}
            </TransportProvider>
          }
        />

        {/* Catch-all route - redirect all unmatched routes to /inventory */}
        <Route path='*' element={<Navigate to='/inventory' replace />} />
      </Routes>
    </div>
  )
}

const RedirectToLogin = ({ loginPath }: { loginPath: string }) => {
  const location = useLocation()
  const { isAuthenticated, isSessionLoading } = useSession()
  if (location.pathname === loginPath) {
    return null
  }
  if (!isAuthenticated && !isSessionLoading) {
    return (
      <Navigate
        to={loginPath}
        state={{
          from: { pathname: location.pathname, search: location.search, hash: location.hash },
        }}
      />
    )
  }

  return null
}

const LemaAppAuthProviders = ({ children }: { children: React.ReactElement }) => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <AuthProvider projectId={descopeProjectID} baseUrl={descopeBaseUrl}>
        {children}
      </AuthProvider>
    </GoogleOAuthProvider>
  )
}

const LemaAppAuthTrackProvider = ({ children }: { children: React.ReactElement }) => {
  const { user } = useUser()
  const { sessionToken, isAuthenticated } = useSession()
  const { selectTenant } = useDescope()

  useEffect(() => {
    const selectCurrentTenant = function () {
      if (!sessionToken || !isAuthenticated) {
        return
      }

      const { dct } = jwtDecode<{ dct?: string }>(sessionToken)
      if (!dct && user && user.userTenants && user.userTenants.length > 0) {
        const tenants = user.userTenants.sort((a, b) => a.tenantId.localeCompare(b.tenantId))
        selectTenant(tenants[0].tenantId)
      }
    }

    try {
      // wrapped in a function to make sure uesEffect never returns an object (will try to use it as a cleanup function)
      selectCurrentTenant()
    } catch (e) {
      console.warn('Error selecting tenant', e)
    }
  }, [isAuthenticated, selectTenant, sessionToken, user])

  const orgId = useOrgIdFromToken(user, sessionToken)
  useTrackUser(user, orgId || '')

  return children
}

const SupportUserBanner = () => {
  const isSupportUser = useIsSupportUser()
  if (isSupportUser) {
    return (
      <div className='fixed bottom-0 z-max flex h-12 w-full items-center justify-center border-t-2 border-red-500 bg-red-50 text-center font-bold text-red-500'>
        Support Portal
      </div>
    )
  }
  return null
}
