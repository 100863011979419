import { PartialMessage, PlainMessage } from '@bufbuild/protobuf'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
  getProject,
  listProjects,
  setProjectControlScope,
} from '@/gen/inventory/v1/assessment_service-AssessmentService_connectquery'
import {
  ControlScope,
  GetProjectResponse,
  Project,
  ProjectAttributeChangeType,
  ScopeStatus,
  SetProjectControlScopeRequest,
} from '@/gen/inventory/v1/assessment_service_pb'
import { listControls } from '@/gen/inventory/v1/control_service-ControlInventoryService_connectquery'

export const useOptimisticSetProjectControlScope = (projectId: Project['id']) => {
  const queryClient = useQueryClient()
  const mutation = setProjectControlScope.useMutation()

  return useMutation(mutation.mutationFn, {
    ...mutation,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(getProject.getQueryKey({ projectId }))

      const previousData = queryClient.getQueryData<PlainMessage<GetProjectResponse>>(
        getProject.getQueryKey({ projectId }),
      )

      queryClient.setQueryData<PlainMessage<GetProjectResponse> | undefined>(
        getProject.getQueryKey({ projectId }),
        (oldData) => updateProjectData(oldData, variables),
      )

      return { previousData }
    },
    onError: (_error, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(getProject.getQueryKey({ projectId }), context.previousData)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(getProject.getQueryKey())
      queryClient.invalidateQueries(listProjects.getQueryKey())
      queryClient.invalidateQueries(listControls.getQueryKey())
    },
  })
}

const updateProjectData = (
  oldData: PlainMessage<GetProjectResponse> | undefined,
  variables: PartialMessage<SetProjectControlScopeRequest>,
): PlainMessage<GetProjectResponse> | undefined => {
  if (!oldData) {
    return oldData
  }

  const { scopeId, changeType } = variables

  const newData: PlainMessage<GetProjectResponse> = {
    ...oldData,
    project: oldData.project,
    controlScopes: oldData.controlScopes?.map((scope) => {
      if (scope.id === scopeId) {
        return {
          ...scope,
          status:
            changeType === ProjectAttributeChangeType.ADD
              ? ScopeStatus.ACTIVE
              : ScopeStatus.INACTIVE,
        } as PlainMessage<ControlScope>
      }
      return scope
    }),
  }

  return newData
}
