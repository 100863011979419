import { PlainMessage } from '@bufbuild/protobuf'
import { Avatar } from 'antd'
import _ from 'lodash'
import { MinusIcon, SparklesIcon } from 'lucide-react'
import pluralize from 'pluralize'
import React, { useMemo } from 'react'

import { InherentRiskCategoryEnum, Permission, Source } from '@/gen/inventory/v1/company_service_pb'

import { EventProperties, useTrackCallback } from '@/lib/analytics/events'
import { cn } from '@/lib/style-helpers'

import { Tooltip } from '@/components/ui/tooltip'

type SourcesAvatarGroupProps = {
  sources: (PlainMessage<Source> | undefined)[]
  permissions?: PlainMessage<Permission>[]
  kind?: 'default' | 'inherent-risk'
  claim?: string
  explanation?: string
  trackingProps?: EventProperties
  categoryEnum?: InherentRiskCategoryEnum
} & React.HTMLAttributes<HTMLDivElement>
export const SourcesAvatarGroup = ({
  sources,
  className,
  kind = 'default',
  permissions,
  claim,
  explanation,
  trackingProps,
  categoryEnum,
}: SourcesAvatarGroupProps) => {
  const trackSuggestionHover = useTrackCallback('third-party.irq.suggestions.hover')

  if (sources.length === 0) {
    return <MinusIcon className='text-gray-400' />
  }

  const avatarGroupClassName = cn(
    'gap-0 transition-[gap,opacity] duration-200 hover:gap-2.5',
    className,
  )

  const avatarGroup = (
    <Avatar.Group
      className={avatarGroupClassName}
      max={{
        count: 3,
        style: { width: 24, height: 24 },
      }}
    >
      {_.unionBy(sources, (source) => source && source.name).map((source) => {
        return (
          <SourceAvatar
            key={`source-avatar-${source?.name}`}
            source={source}
            tooltip={kind === 'default' ? source?.name : undefined}
          />
        )
      })}
    </Avatar.Group>
  )

  if (kind === 'default') {
    return avatarGroup
  }

  return (
    <Tooltip
      trigger={avatarGroup}
      triggerClassName={avatarGroupClassName}
      onOpenChange={(open) => {
        if (open) {
          trackSuggestionHover({
            ...trackingProps,
            sources: sources.map((s) => s?.name),
            claim,
            permissions,
            explanation,
          })
        }
      }}
    >
      <CombinedTooltip
        claim={claim}
        permissions={permissions}
        sources={sources || []}
        explanation={explanation}
        categoryEnum={categoryEnum}
      />
    </Tooltip>
  )
}

type SourceAvatarProps = {
  source?: PlainMessage<Source>
  tooltip?: string | React.ReactNode
}

export const SourceAvatar = ({ source, tooltip }: SourceAvatarProps) => {
  if (!source) {
    return null
  }

  const isProjection = source.name === 'Projection'
  if (!source.imgUrl && !isProjection) {
    return null
  }

  const img = isProjection ? (
    <SparklesIcon size={40} className='stroke-[1.5] text-purple-500' />
  ) : (
    <img width={40} height={40} src={source.imgUrl} alt={source.name} />
  )

  const avatar = (
    <Avatar
      size={'small'}
      className={cn(
        'bg-white shadow-md',
        isProjection && 'border border-dashed !border-purple-500',
      )}
    >
      {img}
    </Avatar>
  )

  if (tooltip) {
    return (
      <Tooltip key={`source-avatar-tooltip-${source.name}`} trigger={avatar}>
        {tooltip}
      </Tooltip>
    )
  }

  return avatar
}

type CombinedTooltipProps = {
  permissions?: PlainMessage<Permission>[]
  sources: (PlainMessage<Source> | undefined)[]
  claim?: string
  explanation?: string
  categoryEnum?: InherentRiskCategoryEnum
}

const CombinedTooltip = ({
  permissions,
  sources,
  claim,
  explanation,
  categoryEnum,
}: CombinedTooltipProps) => {
  const phrase = useMemo(() => {
    switch (categoryEnum) {
      case InherentRiskCategoryEnum.LOB:
        return `Third-party was requested through procurement by ${claim}`
      case InherentRiskCategoryEnum.SPEND:
        return `Procurement requests indicate ${claim} spend from last year`
      default:
        return `categoryEnum: ${categoryEnum}`
    }
  }, [categoryEnum, claim])

  return (
    <div className='w-72 space-y-4 p-1'>
      {sources.map((source) => {
        if (!source) {
          return null
        }

        const uniquePermissions = permissions
          ?.filter((permission) => permission.source?.name === source?.name)
          .reduce(
            (acc, permission) => {
              acc[permission.name] = permission
              return acc
            },
            {} as Record<string, PlainMessage<Permission>>,
          )

        const sourcePermissions = Object.values(uniquePermissions || {})
        const isProjection = source.name === 'Projection'
        const isPermission =
          source.name !== 'Projection' &&
          (categoryEnum === InherentRiskCategoryEnum.DATA ||
            categoryEnum === InherentRiskCategoryEnum.ATTACK_SURFACE)

        return (
          <div key={`combined-tooltip-${source.name}-${claim}`}>
            <SourceTitle source={source} />
            <div className='mt-1 space-x-1 text-gray-700'>
              {isPermission ? (
                <IntegrationPermissionTooltip
                  source={source}
                  permissions={sourcePermissions || []}
                  claim={claim!}
                  categoryEnum={categoryEnum}
                />
              ) : (
                <div className='text-gray-700'>
                  <span>
                    {isProjection ? explanation || 'Estimation explenation is loading...' : phrase}
                  </span>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const SourceTitle = ({ source }: { source: PlainMessage<Source> }) => {
  return source.name === 'Projection' ? (
    <div className='flex items-center justify-start gap-2 text-purple-500'>
      <SourceAvatar source={source} />
      <span className='text-md font-semibold'>{'Lema Smart Estimation'}</span>
    </div>
  ) : (
    <div className='flex items-center justify-start gap-2'>
      <SourceAvatar source={source} />
      <span className='text-md font-semibold'>{source.name}</span>
    </div>
  )
}

type IntegrationPermissionTooltipProps = {
  permissions: PlainMessage<Permission>[]
  claim: string
  source: PlainMessage<Source>
  categoryEnum?: InherentRiskCategoryEnum
}
const IntegrationPermissionTooltip = ({
  permissions,
  claim,
  source,
  categoryEnum,
}: IntegrationPermissionTooltipProps) => {
  const phrase = useMemo(() => {
    const isPlural = permissions.length > 1

    switch (categoryEnum) {
      case InherentRiskCategoryEnum.ATTACK_SURFACE:
        return `${isPlural ? 'grant' : 'grants'} ${claim}, increasing your organization's attack surface exposure.`
      case InherentRiskCategoryEnum.DATA:
        return `${isPlural ? 'allow' : 'allows'} to access ${claim}`
      default:
        return `allows ${claim}`
    }
  }, [categoryEnum, claim, permissions.length])

  return (
    <span
      key={`integration-permission-${source.name}-${claim}-${permissions.length}`}
      className='leading-5'
    >
      <span className='mr-1'>{pluralize('Permission', permissions.length)}</span>
      <PermissionTooltip permissions={permissions} shown={3} />
      <span className='ml-1'>{phrase}</span>
    </span>
  )
}

const PermissionTooltip = ({
  permissions,
  shown = 3,
}: {
  permissions: PlainMessage<Permission>[]
  shown?: number
}) => {
  const shownPermissions = permissions.slice(0, shown)
  const hiddenPermissions = permissions.slice(shown)

  return (
    <>
      {shownPermissions?.map((permission, index) => {
        const permissionName = permission.name.replace(/^.*\.com\/auth/, '').replace('/', '')
        return (
          <PermissionBadge
            key={`permission-${permissionName}-${index}`}
            permissionName={permissionName}
            isLast={index === permissions.length - 1}
          />
        )
      })}
      {hiddenPermissions.length > 0 && (
        <PermissionBadge
          permissionName={`+${hiddenPermissions.length} more`}
          isLast={true}
          dir='ltr'
        />
      )}
    </>
  )
}

const PermissionBadge = ({
  permissionName,
  isLast,
  dir = 'rtl',
}: {
  permissionName: string
  isLast: boolean
  dir?: 'rtl' | 'ltr'
}) => {
  return (
    <React.Fragment key={`permission-${permissionName}`}>
      <span
        className='inline-block max-w-40 overflow-hidden text-ellipsis text-nowrap rounded border-y border-gray-100 bg-gray-50 px-0.5 align-text-top font-mono text-xs'
        dir={dir}
      >
        {permissionName}
      </span>
      {!isLast && ', '}
    </React.Fragment>
  )
}
