import { ChevronDownIcon, SlidersHorizontalIcon } from 'lucide-react'
import pluralize from 'pluralize'
import { useContext } from 'react'

import { cn } from '@/lib/style-helpers'

import { FilterProviderContext } from '@/components/table/filter/context'
import { Button } from '@/components/ui/button'

export const ShowFilterTagsToggler = () => {
  const { filteredState, showFilterTags, totalResults, setShowFilterTags } =
    useContext(FilterProviderContext)

  const filterPairs = Object.entries(filteredState)
    .filter(([, values]) => values !== null)
    // flatten all selected values of a given key into an array of objects
    // for simpler display of tags
    .flatMap(([key, values]) =>
      values.value!.map((value) => ({ key, value, valueRender: values.valueRender })),
    )

  const filtersActive = filterPairs.length > 0

  return (
    <div className='flex items-center gap-2'>
      <span
        data-testid='total-results'
        className='whitespace-nowrap text-base font-light text-gray-500'
      >
        {pluralize('Result', totalResults, true)}
      </span>
      <Button
        data-testid='show-filter-tags-toggler'
        disabled={!filtersActive}
        onClick={() => filtersActive && setShowFilterTags(!showFilterTags)}
        className='flex items-center gap-1 whitespace-nowrap rounded-sm text-base font-light text-gray-400'
        variant='ghost'
      >
        <SlidersHorizontalIcon className='w-3.5' />
        {filtersActive ? (
          <>
            {showFilterTags ? 'Hide' : 'Show'} Filters
            <ChevronDownIcon
              className={cn({ 'rotate-180': showFilterTags }, 'w-3.5 duration-200')}
            />
          </>
        ) : (
          'No Filters'
        )}
      </Button>
    </div>
  )
}
