import pluralize from 'pluralize'

import { Company } from '@/gen/inventory/v1/company_service_pb'

import { NextPreAssessmentActionButton } from '@/pages/third-party-inventory/next-pre-assessment-action-button'

import { SubprocessorBadge } from '@/components/badge/subprocessor'

const isSubprocessor = (company: Company) => {
  return company.isSubprocessor
}

const isSuggestedSubprocessor = (company: Company) => {
  return company.isSuggestedSubprocessor
}

interface ThirdPartyColumnCellProps {
  company: Company
}

export const ThirdPartyColumnCell = ({ company }: ThirdPartyColumnCellProps) => {
  const { profile, solutionCount, id, status } = company
  const subprocessor = isSubprocessor(company)
  const suggestedSubprocessor = isSuggestedSubprocessor(company)

  return (
    <div className='relative flex items-center gap-1'>
      <div className='flex min-w-0 flex-1 flex-col gap-1.5'>
        <div className='flex min-w-0 items-center gap-1.5'>
          <span className='min-w-0 truncate font-semibold'>{profile?.name}</span>
          {(subprocessor || suggestedSubprocessor) && (
            <SubprocessorBadge
              companyId={company.id}
              kind={subprocessor ? 'default' : 'suggested'}
            />
          )}
        </div>
        <div className='truncate text-nowrap text-xs'>
          <span className='block max-w-md truncate text-gray-600'>{profile?.shortDescription}</span>
          {solutionCount > 1 && pluralize('Solutions', solutionCount, true)}
        </div>
      </div>
      <div className='invisible absolute right-0 bg-white opacity-0 duration-150 ease-in-out group-hover/row:visible group-hover/row:opacity-100'>
        <NextPreAssessmentActionButton companyId={id} status={status} from='inventory' />
      </div>
    </div>
  )
}
