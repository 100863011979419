import Tag from 'antd/es/tag'
import { XIcon } from 'lucide-react'
import { useContext } from 'react'

import { FilterProviderContext } from '@/components/table/filter/context'
import { valueRender } from '@/components/table/filter/filter.hook'

type FilterTagProps = {
  filterKey: React.Key
  filterValue: string
  valueRender?: valueRender
}

const FilterTag = ({ filterKey: key, filterValue: value, valueRender }: FilterTagProps) => {
  const { setSelected } = useContext(FilterProviderContext)
  return (
    <Tag
      closable
      key={`${key}${value}`}
      closeIcon={<XIcon size={13} className='text-white' />}
      onClose={(e) => {
        e.preventDefault()
        setSelected(key, value, false, valueRender)
      }}
      className='inline-flex items-center bg-gray-700 text-white'
    >
      {key} - {valueRender ? valueRender(value) : value}
    </Tag>
  )
}

export const FilterTags = () => {
  const { filteredState, clearFilters, showFilterTags } = useContext(FilterProviderContext)

  if (!showFilterTags) {
    return <></>
  }

  const filterPairs = Object.entries(filteredState)
    .filter(([, values]) => values !== null)
    // flatten all selected values of a given key into an array of objects
    // for simpler display of tags
    .flatMap(([key, values]) =>
      values.value!.map((value) => ({ key, value, valueRender: values.valueRender })),
    )

  return (
    <div data-testid='facet-tag-list-container' className='border-t bg-gray-50 px-8 py-2'>
      {filterPairs.map(({ key, value, valueRender }) => (
        <FilterTag
          key={`${key}${value}`}
          filterKey={key}
          filterValue={value}
          valueRender={valueRender && ((value) => valueRender(value, true))}
        />
      ))}
      <Tag
        data-testid='clear-all-filters-button'
        closable
        closeIcon={<XIcon size={13} className='text-gray-700' />}
        onClick={(e) => {
          e.preventDefault()
          clearFilters()
        }}
        onClose={(e) => {
          e.preventDefault()
          clearFilters()
        }}
        bordered={false}
        className='inline-flex items-center bg-gray-50 text-gray-700 hover:cursor-pointer'
      >
        clear all
      </Tag>
    </div>
  )
}
