import { LEMA_USER_ID } from '@/const/contact'

import { ONE_YEAR_AGO } from '@/lib/date'
import { useLastIrqChange } from '@/lib/last-irq-change.hook'

export const useIsIrqCompleted = (companyId: string) => {
  const { lastIRQChange } = useLastIrqChange(companyId)

  if (!lastIRQChange.time || lastIRQChange.userId === LEMA_USER_ID) {
    return false
  }

  // If the last IRQ update was updated more than one year ago, we consider it as not completed
  return lastIRQChange.time.toDate() > ONE_YEAR_AGO
}
