import type { ColumnType, TableViews } from './table.type'
import { Table as AntDTable, TableProps as AntDTableProps } from 'antd'
import { AnyObject } from 'antd/es/_util/type'
import { useContext, useEffect, useMemo } from 'react'

import { cn } from '@/lib/style-helpers'

import { FilterBar } from '@/components/table/filter/bar'
import { CheckboxFilterContext } from '@/components/table/filter/checkbox-filter-hook'
import { FilterProvider, FilterProviderContext } from '@/components/table/filter/context'
import { sortIcon } from '@/components/table/sort-icon'
import { applyFilters, generateAntColumn, generateFilter } from '@/components/table/table-generator'
import { useDefaultFilters } from '@/components/table/use-default-filters'
import { ViewBar } from '@/components/table/view/bar'
import { useTableViews } from '@/components/table/view/use-table-views.hook'

import './antd-table.less'

export type TableProps<T> = {
  columns: ColumnType<T>[]
  showFilterBar?: boolean
  showBorder?: boolean
  stickyFilterBar?: boolean
  cellAlignTextTop?: boolean
  narrowFilterBar?: true
  checkboxFilterTitle?: string
  tableViews?: TableViews<T>
  tableName?: string
  selectedView?: string | null
} & Omit<AntDTableProps<T>, 'columns'> &
  CheckboxFilterContext

const InternalTable = <T extends AnyObject>({
  dataSource = [],
  columns,
  narrowFilterBar,
  showFilterBar = true,
  showBorder = true,
  stickyFilterBar = false,
  cellAlignTextTop = false,
  pagination = false,
  checkboxFilterState,
  checkboxFilterTitle,
  tableViews,
  selectedView,
  ...props
}: TableProps<T>) => {
  const { filteredState, searchState, setSelected, clearFilters, setTotalResults } =
    useContext(FilterProviderContext)

  const hasSearch = useMemo(() => columns.some((col) => col.search), [columns])
  const hasCheckboxFilter = checkboxFilterState !== undefined

  const selectedViewColumns = useMemo(() => {
    if (
      selectedView &&
      tableViews?.selectedViewUrlKey &&
      tableViews?.views?.[selectedView]?.columns
    ) {
      const viewColumns = tableViews.views[selectedView].columns
      return viewColumns
    }
    return columns
  }, [columns, tableViews?.views, tableViews?.selectedViewUrlKey, selectedView])

  const antColumns = useMemo(() => {
    return selectedViewColumns.map((col) =>
      generateAntColumn(col, filteredState, searchState, sortIcon),
    )
  }, [selectedViewColumns, filteredState, searchState])

  const filters = useMemo(() => {
    return selectedViewColumns
      .filter((col) => col.filter) // only include columns that have filter enabled
      .map((col) => generateFilter(col, dataSource))
  }, [selectedViewColumns, dataSource])

  const dataSourceWithKey = useMemo(
    () =>
      dataSource.map((row, index) => ({
        key: row.id ? `${row.id}` : `${index}`,
        ...row,
      })),
    [dataSource],
  )

  // Get the view accessor first
  const { viewAccessor, viewOptions } = useTableViews(
    tableViews,
    selectedView || undefined,
    dataSourceWithKey,
  )

  useDefaultFilters(filters, setSelected)

  const filteredDataSource = useMemo(() => {
    const viewFilteredData = viewAccessor
      ? dataSourceWithKey.filter(viewAccessor)
      : dataSourceWithKey

    const filteredData = applyFilters(
      viewFilteredData,
      selectedViewColumns,
      filteredState,
      searchState,
    )

    return filteredData
  }, [dataSourceWithKey, selectedViewColumns, filteredState, searchState, viewAccessor])

  useEffect(() => {
    setTotalResults(filteredDataSource.length)
  }, [filteredDataSource, setTotalResults])

  return (
    <div className='block'>
      <div className='mb-4'>
        {tableViews && viewOptions && (
          <ViewBar
            // Clear filters when view changes
            onViewChange={() => clearFilters()}
            selectedViewUrlKey={tableViews.selectedViewUrlKey}
            views={viewOptions}
          />
        )}
      </div>

      {showFilterBar && (
        <div className={cn('mb-2', { 'sticky top-0 z-50': stickyFilterBar })}>
          <FilterBar
            narrow={narrowFilterBar}
            showSearch={hasSearch}
            filters={filters}
            // Currently we support only one checkbox filter
            checkboxFilterLabel={checkboxFilterTitle}
            showCheckboxFilter={hasCheckboxFilter}
          />
        </div>
      )}

      <div
        className={cn(cellAlignTextTop && 'cells-text-top', 'flex w-full flex-col gap-2', {
          'on-row-active': !!props.onRow || !!props.expandable?.expandRowByClick,
        })}
      >
        <AntDTable
          {...props}
          rowClassName={'group/row'}
          dataSource={filteredDataSource}
          columns={antColumns}
          pagination={pagination}
          className={cn(showBorder && 'rounded-sm border border-solid border-gray-200')}
        />
      </div>
    </div>
  )
}

export const Table = <T extends AnyObject>(props: TableProps<T>) => (
  <FilterProvider
    checkboxFilterState={props.checkboxFilterState}
    setCheckboxFilterState={props.setCheckboxFilterState}
  >
    <InternalTable {...props} />
  </FilterProvider>
)
