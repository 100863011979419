// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file inventory/v1/assessment_decision.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum inventory.v1.AssessmentDecision
 */
export enum AssessmentDecision {
  /**
   * @generated from enum value: ASSESSMENT_DECISION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ASSESSMENT_DECISION_APPROVED = 1;
   */
  APPROVED = 1,

  /**
   * @generated from enum value: ASSESSMENT_DECISION_REJECTED = 2;
   */
  REJECTED = 2,

  /**
   * @generated from enum value: ASSESSMENT_DECISION_CONDITIONALLY_APPROVED = 3;
   */
  CONDITIONALLY_APPROVED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AssessmentDecision)
proto3.util.setEnumType(AssessmentDecision, "inventory.v1.AssessmentDecision", [
  { no: 0, name: "ASSESSMENT_DECISION_UNSPECIFIED" },
  { no: 1, name: "ASSESSMENT_DECISION_APPROVED" },
  { no: 2, name: "ASSESSMENT_DECISION_REJECTED" },
  { no: 3, name: "ASSESSMENT_DECISION_CONDITIONALLY_APPROVED" },
]);

