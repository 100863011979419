import { useListCompanies } from '@/api/company.hook'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { UnknownError } from '@/pages/error-pages/unknown-error'
import { DiscoveryTable } from '@/pages/settings-page/discovery-section/discovery-table'

import { Loading } from '@/components/loading'

export const ThirdPartyDiscoveryPage = () => {
  const { updateParam } = useUrlParams()
  const { data: thirdParties, error, isInitialLoading } = useListCompanies()

  if (isInitialLoading) {
    return <Loading />
  }

  if (error) {
    return <UnknownError />
  }

  return (
    <div>
      <div className='mb-5 flex justify-between'>
        <h2 className='text-3xl font-bold'>Discovery</h2>
      </div>
      <DiscoveryTable
        inventory={thirdParties}
        onClick={(thirdPartyId: string) => {
          updateParam(UrlParam.THIRD_PARTY_ID, thirdPartyId)
        }}
      />
    </div>
  )
}
