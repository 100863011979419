import { TriangleAlertIcon } from 'lucide-react'

import { useListProjects } from '@/api/assessments.hook'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { companyStatusLabel } from '@/const/label'

import { cn } from '@/lib/style-helpers'
import { useIsSupportUser } from '@/lib/support-user'
import { badgeSentimentVariant } from '@/lib/variants'

import { thirdPartyStatusToIcon as companyStatusToIcon } from '@/components/icons/third-party-status'
import { SelectWithComment } from '@/components/select/select-with-comment'
import { MenuItem } from '@/components/select/type'
import { TextWithIcon } from '@/components/text-with-icon'
import { Badge, BadgeProps } from '@/components/ui/badge'
import { Sentiment } from '@/components/variance-percentage/types'

type CompanyStatusBadgeProps = {
  status: CompanyStatus
} & BadgeProps

export const CompanyVerificationBadge = ({
  status,
  className,
  ...props
}: CompanyStatusBadgeProps) => (
  <Badge
    variant='outline'
    className={cn(
      badgeSentimentVariant({
        sentiment: status === CompanyStatus.ASSESSED ? Sentiment.POSITIVE : Sentiment.NEUTRAL,
      }),
      className,
    )}
    {...props}
  >
    {companyStatusToIcon[status]}
    {companyStatusLabel[status]}
  </Badge>
)

type SelectAssessmentStatusProps = {
  status: CompanyStatus
  onValueChange: (value: CompanyStatus, comment: string) => void
}

export const SelectAssessmentStatus = ({ status, onValueChange }: SelectAssessmentStatusProps) => {
  const { projects } = useListProjects()
  const isSupportUser = useIsSupportUser()

  const hasProjects = projects.length > 0

  const menuItems: MenuItem<CompanyStatus>[] = [
    {
      type: 'menuSelectItem',
      value: CompanyStatus.ASSESSED,
      label: <CompanyVerificationBadge status={CompanyStatus.ASSESSED} />,
      warningMessage:
        status !== CompanyStatus.IN_ASSESSMENT
          ? 'Skipping assessment is not recommended.'
          : hasProjects
            ? 'It is not recommended to finalize assessment while project assessment are still in progress.'
            : '',
      warningMessageRenderer: (message) => (
        <TextWithIcon
          text={message}
          icon={<TriangleAlertIcon size={12} />}
          className='text-xs text-yellow-500'
        />
      ),
    },
    {
      type: 'menuLabel',
      label: 'IN ASSESSMENT',
    },
    {
      type: 'menuSelectItem',
      value: CompanyStatus.IN_ASSESSMENT,
      label: <CompanyVerificationBadge status={CompanyStatus.IN_ASSESSMENT} />,
    },
    {
      type: 'menuLabel',
      label: 'ASSESSMENT REQUIRED',
    },
    {
      type: 'menuSelectItem',
      value: CompanyStatus.RE_ASSESSMENT_REQUIRED,
      label: <CompanyVerificationBadge status={CompanyStatus.RE_ASSESSMENT_REQUIRED} />,
    },
    {
      type: 'menuSelectItem',
      value: CompanyStatus.ASSESSMENT_REQUIRED,
      label: <CompanyVerificationBadge status={CompanyStatus.ASSESSMENT_REQUIRED} />,
    },
    {
      type: 'menuLabel',
      label: 'ARCHIVED',
    },
    {
      type: 'menuSelectItem',
      value: CompanyStatus.OFFBOARDED,
      label: <CompanyVerificationBadge status={CompanyStatus.OFFBOARDED} />,
    },
    {
      type: 'menuSelectItem',
      value: CompanyStatus.REJECTED,
      label: <CompanyVerificationBadge status={CompanyStatus.REJECTED} />,
    },
    {
      type: 'menuSelectItem',
      value: CompanyStatus.ARCHIVED,
      label: <CompanyVerificationBadge status={CompanyStatus.ARCHIVED} />,
    },
    {
      type: 'menuLabel',
      label: 'UNSANCTIONED',
    },
    {
      disabled: !isSupportUser,
      type: 'menuSelectItem',
      value: CompanyStatus.UNSANCTIONED,
      label: <CompanyVerificationBadge status={CompanyStatus.UNSANCTIONED} />,
    },
  ]

  if (isSupportUser) {
    menuItems.push({
      disabled: !isSupportUser,
      type: 'menuSelectItem',
      value: CompanyStatus.DISCOVERY,
      label: <CompanyVerificationBadge status={CompanyStatus.DISCOVERY} />,
    })
  }

  return (
    <SelectWithComment
      value={status}
      title="Set This Third-Party's Status"
      triggerAppearance='headless'
      onSave={onValueChange}
      menuItems={menuItems}
    />
  )
}
