import { VariantProps, cva } from 'class-variance-authority'

import { TextWithIcon } from '@/components/text-with-icon'
import { Button, ButtonProps } from '@/components/ui/button'

const actionItemVariants = cva('w-fit rounded', {
  variants: {
    variant: {
      default: 'bg-gray-100 px-1.5',
      link: 'bg-white',
    },
    urgent: {
      true: 'bg-red-50 text-red-500 hover:bg-red-100',
    },
  },
})

export type ActionItemVariant = VariantProps<typeof actionItemVariants>['variant']

type ActionItemProps = {
  text: React.ReactNode
  icon: React.ReactNode
  onClick: ButtonProps['onClick']
  urgent?: boolean
  variant?: ActionItemVariant
}

export const ActionItem = ({
  text,
  icon,
  onClick,
  variant = 'default',
  urgent,
}: ActionItemProps) => {
  return (
    <Button
      size='sm'
      className={actionItemVariants({ variant, urgent })}
      variant={variant === 'default' ? 'text' : 'outline'}
      onClick={(e) => {
        e.stopPropagation()
        onClick?.(e)
      }}
    >
      <TextWithIcon
        text={text}
        iconPosition={variant === 'link' ? 'end' : 'start'}
        icon={variant === 'link' ? null : icon}
      />
    </Button>
  )
}
