import {
  ArchiveIcon,
  CircleAlertIcon,
  CircleCheckIcon,
  CircleEllipsisIcon,
  CircleHelpIcon,
  GhostIcon,
  HandIcon,
  RefreshCcwIcon,
  XIcon,
} from 'lucide-react'
import { ReactElement } from 'react'

import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

export const thirdPartyStatusToIcon: Record<CompanyStatus, ReactElement | null> = {
  [CompanyStatus.IN_ASSESSMENT]: <CircleEllipsisIcon className='w-3.75 text-yellow-500' />,
  [CompanyStatus.RE_ASSESSMENT_REQUIRED]: <RefreshCcwIcon className='w-3.75 text-red-400' />,
  [CompanyStatus.ASSESSMENT_REQUIRED]: <CircleAlertIcon className='w-3.75 text-red-400' />,
  [CompanyStatus.ASSESSED]: <CircleCheckIcon className='w-3.75 text-green-500' />,
  [CompanyStatus.OFFBOARDED]: <HandIcon strokeWidth={1.5} className='w-3.75 text-gray-700' />,
  [CompanyStatus.ARCHIVED]: <ArchiveIcon className='w-3.75 text-gray-700' />,
  [CompanyStatus.REJECTED]: <XIcon className='w-3.75 text-red-400' />,
  [CompanyStatus.UNSANCTIONED]: <GhostIcon className='w-3.75 text-purple-500' />,
  [CompanyStatus.DISCOVERY]: <CircleHelpIcon className='w-3.75 text-gray-700' />,
  [CompanyStatus.UNSPECIFIED]: null,
}
