import { PlainMessage } from '@bufbuild/protobuf'

import { ControlCountStat, ControlStatus } from '@/gen/inventory/v1/control_service_pb'

import { controlStatusLabel } from '@/const/label'

import { CONTROL_STATUS } from '@/lib/proto-types'

import { ControlStatusIcon } from '@/components/icons/control-status'
import { Tooltip } from '@/components/ui/tooltip'

type ControlDistributionProps = {
  controlCountByLevel: PlainMessage<ControlCountStat>[]
  hideValidated?: boolean
}

export const ControlDistribution = ({
  controlCountByLevel,
  hideValidated,
}: ControlDistributionProps) => {
  const countByStatus: {
    status: ControlStatus
    count: number
  }[] = []

  for (const { no: status } of CONTROL_STATUS) {
    const count = controlCountByLevel.find((item) => item.status === status)?.count || 0
    if (count === 0 || (hideValidated && status === ControlStatus.VALIDATED)) {
      continue
    }

    countByStatus.push({
      status,
      count,
    })
  }

  const distributionContent = (
    <div className='flex flex-nowrap gap-4'>
      {countByStatus.length === 0 ? (
        <span className='text-gray-400'>All Validated</span>
      ) : (
        countByStatus.map(({ status, count }) => (
          <div key={status} className='flex flex-nowrap items-center gap-1'>
            <ControlStatusIcon status={status} />
            <span>{count}</span>
          </div>
        ))
      )}
    </div>
  )

  if (countByStatus.length === 0) {
    return distributionContent
  }

  return (
    <Tooltip trigger={distributionContent}>
      {CONTROL_STATUS.map(({ no: status }) => {
        const count = controlCountByLevel.find((item) => item.status === status)?.count || 0

        return (
          <div key={status} className='flex items-center gap-1'>
            <span className='w-4'>{count}</span>
            <span>{controlStatusLabel[status]}</span>
          </div>
        )
      })}
    </Tooltip>
  )
}
