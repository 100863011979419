import { Badge, BadgeProps } from '@/components/ui/badge'

export type DataTypeBadgeProps = Pick<BadgeProps, 'children'>

export const DataTypeBadge = ({ children, ...props }: DataTypeBadgeProps) => {
  return (
    <Badge {...props} variant={'outline'} className='h-6 whitespace-nowrap text-md font-normal'>
      {children}
    </Badge>
  )
}
