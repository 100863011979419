import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { CompanyRiskBadge, SelectCompanyRisk } from '@/components/badge/company-risk'
import { Separator } from '@/components/ui/separator'

type SelectInherentRiskStepProps = {
  suggestedRiskLevel: RiskLevel
  comment: string
  setComment: (comment: string) => void
  setSelectedRiskLevel: (riskLevel: RiskLevel) => void
  selectedRiskLevel: RiskLevel
}

export const SelectInherentRiskStep = ({
  suggestedRiskLevel,
  comment,
  setComment,
  setSelectedRiskLevel,
  selectedRiskLevel,
}: SelectInherentRiskStepProps) => {
  return (
    <div>
      <div className='mt-7 text-center'>Lema suggests this third-party’s inherent risk is</div>
      <div className='mb-8 mt-4 flex justify-center'>
        <CompanyRiskBadge
          className='gap-2 px-7 py-0.5 text-xl'
          variant='highlighted'
          riskLevel={suggestedRiskLevel}
        />
      </div>
      <Separator className='my-4' />
      <h4 className='mb-2 mt-5 font-semibold'>Assign an inherent risk to this third-party:</h4>
      <SelectCompanyRisk
        comment={comment}
        setComment={setComment}
        setSelectedRiskLevel={setSelectedRiskLevel}
        selectedRiskLevel={
          selectedRiskLevel === RiskLevel.UNSPECIFIED ? suggestedRiskLevel : selectedRiskLevel
        }
      />
    </div>
  )
}
