import { DataTypeInfo } from '@/gen/inventory/v1/company_service_pb'

import { DataTypes } from './data-type'

export const DataTypeTableCell = ({ dataTypes }: { dataTypes: DataTypeInfo[] }) => {
  return (
    <div className='flex flex-wrap gap-1'>
      <DataTypes maxDisplay={2} dataTypes={dataTypes.map((dataType) => ({ dataType }))} />
    </div>
  )
}
