import { Modal, Select } from 'antd'
import { useState } from 'react'

import { useUpdateArtifactSubcategory } from '@/api/analyzer.hook'
import { useListSubcategories } from '@/api/analyzer.hook'

import { cn } from '@/lib/style-helpers'

import { toast } from '@/components/ui/use-toast'

type SubcategorySelectProps = {
  category: string
  subcategory: string
  documentId: string
}

export const SubcategorySelect = ({
  category,
  subcategory,
  documentId,
}: SubcategorySelectProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { data: subcategories } = useListSubcategories()
  const { mutateAsync } = useUpdateArtifactSubcategory()
  const dropdownValue = category && subcategory ? `${category} - ${subcategory}` : ''

  const showConfirm = (value: string) => {
    Modal.confirm({
      centered: true,
      icon: null,
      title: 'Confirm Changes?',
      content: (
        <div>
          <h3 className='mb-1'>Adjusting subcategory may:</h3>
          <ul className='list-inside list-disc'>
            <li>Delete existing facts</li>
            <li>Recalculate third-party controls</li>
          </ul>
        </div>
      ),
      onOk: async () => {
        await mutateAsync(
          { artifactId: documentId, subCategoryId: value },
          {
            onSuccess: () => {
              toast({
                title: 'Subcategory updated successfully',
                status: 'success',
              })
            },
            onError: () => {
              toast({
                title: 'Failed to update subcategory',
                status: 'error',
              })
            },
          },
        )
      },
    })
  }

  return (
    <Select
      popupMatchSelectWidth={false}
      className={cn(
        'truncate text-wrap break-words opacity-0 transition-opacity duration-100 group-hover:opacity-100 [&>.ant-select-selector]:!cursor-pointer [&>.ant-select-selector]:!pl-0',
        {
          'opacity-100': dropdownValue.length > 0 || isOpen,
        },
      )}
      variant={dropdownValue.length > 0 ? 'borderless' : 'outlined'}
      onDropdownVisibleChange={setIsOpen}
      maxTagCount={1}
      tagRender={(props) => {
        return <span className='pl-0 text-sm text-gray-500'>{props.label}</span>
      }}
      maxTagPlaceholder={
        <span className='ml-2 font-bold text-gray-500'>+ {dropdownValue.length - 1}</span>
      }
      showSearch
      placeholder='Choose Subcategory'
      onChange={(value) => showConfirm(value)}
      value={dropdownValue}
      options={subcategories?.categories.map(({ id, category, subcategory }) => ({
        label: `${category} - ${subcategory}`,
        value: id,
      }))}
    />
  )
}
