import _ from 'lodash'
import { useMemo } from 'react'

import { useGetInherentRisk } from '@/api/company.hook'
import { InherentRiskGroup, InherentRiskSource } from '@/gen/inventory/v1/company_service_pb'

export const useInherentRiskItems = (companyId: string) => {
  const { data: inherentRiskGroups } = useGetInherentRisk({ companyId })

  return useSplitInherentRiskItems(inherentRiskGroups)
}

export const useSplitInherentRiskItems = (inherentRiskGroups: InherentRiskGroup[]) => {
  const irqItems: InherentRiskGroup[] = useMemo(() => {
    return _.map(inherentRiskGroups, (group) => {
      return new InherentRiskGroup({
        ...group,
        inherentRiskItems: _.filter(
          group.inherentRiskItems,
          (item) => item.source === InherentRiskSource.IRQ,
        ),
      })
    })
  }, [inherentRiskGroups])

  const lemaItems: InherentRiskGroup[] = useMemo(() => {
    return _.map(inherentRiskGroups, (group) => {
      return new InherentRiskGroup({
        ...group,
        inherentRiskItems: _.filter(
          group.inherentRiskItems,
          (item) => item.source !== InherentRiskSource.IRQ,
        ),
      })
    })
  }, [inherentRiskGroups])

  return {
    irqItems,
    lemaItems,
  }
}
