import { Code, ConnectError } from '@connectrpc/connect'

const maxRetries = 3
const connectRetryableErrorCodes = [Code.PermissionDenied, Code.NotFound, Code.AlreadyExists]

export const retryLogic = (failureCount: number, error: unknown) => {
  if (error instanceof ConnectError) {
    // Don't retry for specific error codes where retrying won't help
    if (connectRetryableErrorCodes.includes(error.code)) {
      return false
    }
  }

  return failureCount < maxRetries
}
