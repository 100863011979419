import { TrashIcon } from 'lucide-react'
import { useMemo } from 'react'

import { useOptimisticSetIRQItem } from '@/api/optimistic-set-irq.hook'
import { IRQChangeType } from '@/gen/inventory/v1/company_service_pb'

import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { useInherentRiskItems } from '@/lib/use-inherent-risk-items'

import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

type IrqClearAllButtonProps = {
  companyId: string
}

export const IrqClearAllButton = ({ companyId }: IrqClearAllButtonProps) => {
  const { isEnabled } = useFeatureFlagEnabled('irq-clear-all-button')
  const { mutateAsync: setIRQItem } = useOptimisticSetIRQItem(companyId)
  const { irqItems } = useInherentRiskItems(companyId)
  const hasItems = useMemo(() => {
    const flatIrqItems = irqItems.flatMap(({ inherentRiskItems }) => inherentRiskItems)
    return flatIrqItems.length > 0
  }, [irqItems])

  if (!isEnabled) return null

  return (
    <Button
      size={'sm'}
      disabled={!hasItems}
      onClick={() => {
        irqItems.forEach(({ inherentRiskItems, categoryEnum }) => {
          inherentRiskItems.forEach(({ id }) => {
            setIRQItem({
              changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
              companyId,
              riskCategoryId: id,
              category: categoryEnum,
            })
          })
        })
      }}
      variant={'outline'}
    >
      <TextWithIcon icon={<TrashIcon size={14} />} text='Clear All' />
    </Button>
  )
}
