import { useContext } from 'react'

import { Select } from '@/components/select/select'
import { MenuItem } from '@/components/select/type'
import { FilterProviderContext } from '@/components/table/filter/context'

type SelectFilterProps = {
  label: string | React.ReactNode
  filterKey: React.Key
  options: string[]
  optionRender?: (value: string) => React.ReactNode
}

export const SelectFilter = ({ label, filterKey, options, optionRender }: SelectFilterProps) => {
  const { filteredState, setSelected } = useContext(FilterProviderContext)
  const selectedValue = filteredState[filterKey]?.value?.[0]

  const menuItems: MenuItem<string>[] = [
    {
      type: 'menuSelectItem' as const,
      value: 'all',
      label: <span className='px-2 text-gray-400'>All</span>,
    },
    ...options.map((option) => ({
      type: 'menuSelectItem' as const,
      value: option,
      label: optionRender ? optionRender(option) : option,
    })),
  ]

  const labelString = typeof label === 'string' ? label : ''

  return (
    <div className='flex items-center'>
      {labelString && <span className='mr-2 text-gray-400'>{labelString}</span>}
      <Select
        className='outline-none'
        triggerAppearance='headless'
        value={selectedValue || 'all'}
        menuItems={menuItems}
        onValueChange={(value) => {
          if (value === 'all') {
            if (selectedValue) {
              setSelected(filterKey, selectedValue, false, optionRender)
            }
          } else {
            if (selectedValue) {
              setSelected(filterKey, selectedValue, false, optionRender)
            }
            setSelected(filterKey, value, true, optionRender, 'select')
          }
        }}
      />
    </div>
  )
}
