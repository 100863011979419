import { VariantProps, cva } from 'class-variance-authority'
import { ChevronDownIcon } from 'lucide-react'
import { MouseEvent, PointerEvent, ReactNode, useState } from 'react'

import { cn } from '@/lib/style-helpers'

import { TextWithIcon } from '@/components/text-with-icon'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

const commonTriggerStyles =
  'border border-solid whitespace-nowrap rounded py-2 px-4 flex gap-1.5 items-center border-gray-200'
const commonDisabledStyles = 'cursor-not-allowed opacity-50'
const commonTransitionStyles = 'transition duration-300 ease-in-out'

const dropdownVariants = cva('z-max flex flex-col gap-1 rounded', {
  variants: {
    variant: {
      default: 'bg-gray-700 text-white',
      secondary: 'text-gray-700',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

const dropdownTriggerVariants = cva('', {
  variants: {
    triggerAppearance: {
      headless: '',
      default: `${commonTriggerStyles} text-gray-400`,
      secondary: `${commonTriggerStyles} text-gray-700`,
    },
    triggerDisabled: {
      true: commonDisabledStyles,
      false: '',
    },
  },
  defaultVariants: {
    triggerAppearance: 'default',
    triggerDisabled: false,
  },
  compoundVariants: [
    {
      triggerAppearance: 'default',
      triggerDisabled: false,
      className: `${commonTransitionStyles} hover:bg-gray-700 hover:text-white`,
    },
    {
      triggerAppearance: 'secondary',
      triggerDisabled: false,
      className: `${commonTransitionStyles} hover:bg-gray-200`,
    },
  ],
})

type menuItem = {
  content: string | ReactNode
  icon?: ReactNode
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
  itemDisabled?: boolean
  hide?: boolean
}

export type DropdownProps = {
  triggerContent: ReactNode
  menuItems: (menuItem | string)[]
  openOnRightClick?: boolean
} & VariantProps<typeof dropdownTriggerVariants> &
  VariantProps<typeof dropdownVariants>

export const Dropdown = ({
  triggerContent,
  menuItems,
  triggerAppearance,
  triggerDisabled,
  variant,
  openOnRightClick = false,
}: DropdownProps) => {
  const [open, setOpen] = useState(false)

  const handleContextMenu = (e: MouseEvent<HTMLButtonElement>) => {
    if (openOnRightClick) {
      e.preventDefault()
      setOpen(true)
    }
  }

  const handlePointerDown = (e: PointerEvent<HTMLButtonElement>) => {
    if (openOnRightClick && e.button === 0) {
      e.preventDefault()
    }
  }

  return (
    <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
      <DropdownMenuTrigger
        disabled={!!triggerDisabled}
        className={dropdownTriggerVariants({ triggerAppearance, triggerDisabled })}
        onContextMenu={handleContextMenu}
        onPointerDown={handlePointerDown}
      >
        {triggerContent}
        {triggerAppearance !== 'headless' && <ChevronDownIcon className='w-4' />}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align={triggerAppearance === 'headless' ? 'end' : 'center'}
        className={cn('z-max flex flex-col gap-1 rounded', dropdownVariants({ variant }))}
      >
        {menuItems.map((item, index) => {
          if (typeof item === 'string') {
            return (
              <div
                key={index}
                className='px-2 py-1.5 text-xs font-bold uppercase tracking-widest text-white'
              >
                {item}
              </div>
            )
          }
          const { content, onClick, icon, itemDisabled, hide = false } = item
          return (
            !hide && (
              <DropdownMenuItem
                onClick={!itemDisabled ? (e) => onClick?.(e) : undefined}
                className='cursor-pointer'
                key={index}
                disabled={itemDisabled}
              >
                <TextWithIcon text={content} icon={icon} />
              </DropdownMenuItem>
            )
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
