import { DataTypeInfo } from '@/gen/inventory/v1/company_service_pb'

import { sortDataTypeSource } from '@/const/priority'

import { DataTypeBadge } from '@/components/badge/data-type'

type DataTypeProps = {
  dataType: DataTypeInfo
}

interface DataTypesProps {
  dataTypes: DataTypeProps[]
  maxDisplay?: number
}

export const DataTypes = ({ dataTypes, maxDisplay }: DataTypesProps) => {
  const sortedDataTypes = dataTypes.sort(({ dataType: a }, { dataType: b }) => {
    if (a.source === b.source) {
      return a.dataType.localeCompare(b.dataType)
    }
    return sortDataTypeSource(a.source, b.source)
  })

  const displayLimit = maxDisplay ?? sortedDataTypes.length // Default to show all if maxDisplay is not provided
  const hiddenCount = sortedDataTypes.length - displayLimit
  const visibleDataTypes = sortedDataTypes.slice(0, displayLimit)

  return [
    ...visibleDataTypes.map((dataType, i) => <DataType key={i} {...dataType} />),
    hiddenCount > 0 && <DataTypeBadge key='hidden'>{`+${hiddenCount}`}</DataTypeBadge>,
  ]
}

const DataType = ({ dataType }: DataTypeProps) => {
  return <DataTypeBadge key={dataType.dataType}>{dataType.dataType}</DataTypeBadge>
}
