import { useUser } from '@descope/react-sdk'
import {
  BellIcon,
  CircleHelpIcon,
  ClockIcon,
  ContactIcon,
  FilePenLineIcon,
  FileTextIcon,
  GoalIcon,
  KeyIcon,
  PlugIcon,
  UserIcon,
} from 'lucide-react'

import { useOrgId } from '@/lib/auth/orgid.hook'
import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { useIsSupportUser } from '@/lib/support-user'

import { AssessmentCadenceSection } from '@/pages/settings-page/assessment-cadence-section/assessment-cadence-section'
import { ControlSection } from '@/pages/settings-page/controls-section/controls-section'
import { ThirdPartyDiscoveryPage } from '@/pages/settings-page/discovery-section/discovery-page'
import { NotificationSection } from '@/pages/settings-page/notification-section/notification-section'
import { ProjectsSection } from '@/pages/settings-page/projects-section/projects-section'
import { SsoSection } from '@/pages/settings-page/sso-section/sso-section'
import { UserSection } from '@/pages/settings-page/user-section/user-section'

import { TabItem } from '@/components/tabs/types'

import { ArtifactsSection } from './artifacts-section/artifacts-section'
import { IntegrationSection } from './integration-section/integration-section'
import { TemplatesSection } from './questionnaires-section/templates-section'

export const useSettingsItems = () => {
  const { isVisible: ssoConfigVisible, isDisabled: ssoConfigDisabled } = useIsSsoEnabled()
  const { isEnabled: questionnaireEnabled } = useFeatureFlagEnabled('questionnaire-module-v2')
  const isSupportUser = useIsSupportUser()

  const settingsItems: (TabItem | string)[] = [
    {
      index: 'integrations',
      label: 'Integrations',
      labelIcon: <PlugIcon size={14} />,
      component: <IntegrationSection />,
    },
    {
      index: 'controls',
      label: 'Controls',
      labelIcon: <GoalIcon size={14} />,
      component: <ControlSection />,
    },
    {
      index: 'usersAndAccess',
      label: 'Users & Access',
      labelIcon: <UserIcon size={14} />,
      component: <UserSection />,
    },
    {
      index: 'artifacts',
      label: 'Artifacts',
      labelIcon: <FileTextIcon size={14} />,
      component: <ArtifactsSection />,
    },
    {
      index: 'notifications',
      label: 'Notifications',
      labelIcon: <BellIcon size={14} />,
      component: <NotificationSection />,
    },
  ]

  if (ssoConfigVisible) {
    settingsItems.push({
      index: 'sso',
      label: 'SSO',
      labelIcon: <KeyIcon size={14} />,
      component: <SsoSection />,
      itemDisabled: ssoConfigDisabled,
    })
  }

  settingsItems.push('Assessment', {
    index: 'projects',
    label: 'Projects',
    labelIcon: <ContactIcon size={14} />,
    component: <ProjectsSection />,
  })

  if (questionnaireEnabled) {
    settingsItems.push({
      index: 'questionnaire',
      label: 'Questionnaires',
      labelIcon: <FilePenLineIcon size={14} />,
      component: <TemplatesSection />,
    })
  }

  settingsItems.push({
    index: 'cadence',
    label: 'Cadence',
    labelIcon: <ClockIcon size={14} />,
    component: <AssessmentCadenceSection />,
  })

  if (isSupportUser) {
    settingsItems.push('Others', {
      index: 'discovery',
      label: 'Discovery',
      labelIcon: <CircleHelpIcon size={14} />,
      component: <ThirdPartyDiscoveryPage />,
    })
  }

  return settingsItems
}

const useIsSsoEnabled = () => {
  const { isEnabled: featureFlagEnabled } = useFeatureFlagEnabled('sso-settings')
  const { user, isUserLoading } = useUser()
  const { orgId } = useOrgId()
  if (isUserLoading || !user) {
    return {
      isVisible: false,
      isDisabled: true,
    }
  }

  const userRoles = user.userTenants?.find((tenant) => tenant.tenantId === orgId)?.roleNames
  return {
    isVisible: featureFlagEnabled,
    isDisabled: !userRoles?.includes('Tenant Admin'),
  }
}
