import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  End,
  Home,
  PageDown,
  PageUp,
} from '@fluentui/keyboard-keys'
import { VariantProps } from 'class-variance-authority'
import { ChevronDownIcon, XIcon } from 'lucide-react'
import { ReactNode, useCallback, useRef, useState } from 'react'

import { useOutsideClick } from '@/lib/outside-click.hook'
import { cn } from '@/lib/style-helpers'

import { MenuItem } from '@/components/select/type'
import { selectVariants } from '@/components/select/variants'
import { Button } from '@/components/ui/button'
import {
  Select as SelectBase,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Textarea } from '@/components/ui/textarea'

import { Item } from './item'

type SelectWithCommentProps<T extends number | string> = {
  title?: string
  value?: T
  placeholder?: string
  menuItems: MenuItem<T>[]
  onSave?: (value: T, comment: string) => void
  onSendComment?: (comment: string) => void
  selectedValueRenderer?: (value: T) => ReactNode
} & VariantProps<typeof selectVariants>

export const SelectWithComment = <T extends number | string>({
  menuItems,
  title,
  value,
  onSave,
  onSendComment,
  triggerAppearance,
  placeholder,
  selectedValueRenderer,
}: SelectWithCommentProps<T>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [intendedValue, setIntendedValue] = useState<T | null>(null)
  const [commentsMap, setCommentsMap] = useState<Record<string, string>>({})
  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick(ref, () => setIsOpen(false))

  const handleCommentChange = useCallback(
    (newComment: string) => {
      if (intendedValue !== null) {
        setCommentsMap((prev) => ({
          ...prev,
          [intendedValue.toString()]: newComment,
        }))
        if (onSendComment) onSendComment(newComment)
      }
    },
    [onSendComment, intendedValue],
  )

  const reset = () => {
    setIsOpen(false)
    setIntendedValue(null)
    setCommentsMap({})
  }

  return (
    <SelectBase
      onValueChange={(newValue) => {
        // The internal select supports only strings but we want native support for numbers. This is why
        // we convert numbers to strings and here we need to convert strings back to numbers
        // in case the original input was numeric
        setIntendedValue((typeof value === 'number' ? Number(newValue) : newValue.toString()) as T)
      }}
      open={isOpen}
      value={value && value.toString()}
    >
      <SelectTrigger
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(!isOpen)
          // This hack is for making sure a click-outside is registered.
          // For some reason without it other open Select components will not close on a click outside even when the click was in a different select component
          document.dispatchEvent(new Event('mousedown'))
          if (!isOpen) {
            setIntendedValue(null)
            setCommentsMap({})
          }
        }}
        className={selectVariants({ triggerAppearance })}
      >
        <div className={cn({ 'w-full': triggerAppearance === 'full' })}>
          {selectedValueRenderer && value ? (
            selectedValueRenderer(value)
          ) : (
            <SelectValue placeholder={<div className='w-full text-left'>{placeholder}</div>} />
          )}
        </div>
        <ChevronDownIcon
          strokeWidth={1}
          className='size-6 rounded p-1 opacity-50 group-hover:bg-gray-200'
        />
      </SelectTrigger>
      <SelectContent onClick={(e) => e.stopPropagation()} ref={ref} className='z-max'>
        <SelectGroup>
          {title && (
            <>
              <SelectLabel className='flex items-center justify-between gap-4 py-3 font-normal text-gray-700'>
                {title}
                <XIcon
                  onClick={reset}
                  className='cursor-pointer rounded p-0.5 text-gray-400 duration-200 ease-in-out hover:bg-gray-200'
                  size={18}
                />
              </SelectLabel>
              <SelectSeparator />
            </>
          )}

          <div className='max-h-112 overflow-y-auto'>
            {menuItems.map((item, index) => {
              if (item.type === 'menuSelectItem') {
                item.isPotentialSelection = item.value === intendedValue
              }

              return (
                <div key={index}>
                  <Item item={item} />
                  <InputComment
                    warningMessage={
                      item.type === 'menuSelectItem' ? item.warningMessage : undefined
                    }
                    warningMessageRenderer={
                      item.type === 'menuSelectItem' ? item.warningMessageRenderer : undefined
                    }
                    isOpen={
                      !!(
                        intendedValue &&
                        item.type === 'menuSelectItem' &&
                        item.value === intendedValue
                      )
                    }
                    comment={intendedValue ? commentsMap[intendedValue.toString()] || '' : ''}
                    setComment={handleCommentChange}
                  />
                </div>
              )
            })}
          </div>
        </SelectGroup>
        <Separator className='my-4' />
        <Button
          disabled={!intendedValue}
          onClick={() => {
            if (onSave && intendedValue) {
              onSave(intendedValue, commentsMap[intendedValue.toString()] || '')
              reset()
            }
          }}
          className='float-end mx-4 mb-4 px-8'
        >
          Save
        </Button>
      </SelectContent>
    </SelectBase>
  )
}

type InputCommentProps = {
  isOpen: boolean
  comment: string
  setComment: (comment: string) => void
  warningMessage?: ReactNode
  warningMessageRenderer?: (value: ReactNode) => ReactNode
}

const NAVIGATION_KEYS = new Set([
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  PageUp,
  PageDown,
  Home,
  End,
])

const InputComment = ({
  isOpen,
  comment,
  warningMessage,
  setComment,
  warningMessageRenderer,
}: InputCommentProps) => {
  if (!isOpen) return null

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (NAVIGATION_KEYS.has(e.key)) {
      e.stopPropagation()
    }
  }

  return (
    <div className='mx-4 my-2'>
      <div className='text-md font-light'>Add a comment</div>
      <div className='w-full'>
        {warningMessage && warningMessageRenderer
          ? warningMessageRenderer(warningMessage)
          : warningMessage}
      </div>
      <Textarea
        className='mt-2'
        placeholder='Document context on this change in a comment.'
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}
