import { PlainMessage } from '@bufbuild/protobuf'
import { Select } from 'antd'
import _ from 'lodash'
import { SparklesIcon, TriangleAlertIcon, XIcon } from 'lucide-react'
import { useCallback, useMemo } from 'react'

import { useOptimisticSetIRQItem } from '@/api/optimistic-set-irq.hook'
import {
  IRQChangeType,
  InherentRiskCategoryEnum,
  InherentRiskItem,
  InherentRiskSource,
  Permission,
  SetIRQItemRequest,
  Source,
} from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { useTrackCallback } from '@/lib/analytics/events'
import { riskLevelToColor } from '@/lib/color'

import { SourcesAvatarGroup } from '@/components/sources-avatar-group'
import { TextWithIcon } from '@/components/text-with-icon'
import { Button } from '@/components/ui/button'

type IrqCategorySelectProps = {
  categoryEnum: InherentRiskCategoryEnum
  options: InherentRiskItem[]
  selectedOptions?: InherentRiskItem[]
  suggestedOptions?: InherentRiskItem[]
  multipleSelect?: boolean
  companyId: string
}

const projectionSource: PlainMessage<Source> = {
  name: 'Projection',
  imgUrl: '',
}

export const IrqCategorySelect = ({
  options,
  categoryEnum,
  selectedOptions,
  suggestedOptions,
  companyId,
  multipleSelect = false,
}: IrqCategorySelectProps) => {
  const { mutateAsync } = useOptimisticSetIRQItem(companyId)

  const updateIRQ = useCallback(
    (request: Partial<SetIRQItemRequest>) => {
      mutateAsync({ ...request, companyId })
    },
    [mutateAsync, companyId],
  )

  const suggestionsSources: Record<string, (PlainMessage<Source> | undefined)[]> = useMemo(() => {
    return _.mapValues(_.groupBy(suggestedOptions, 'id'), (items) =>
      items.map(
        (item) =>
          item.integrationSource ||
          (item.source === InherentRiskSource.PROJECTED ? projectionSource : undefined),
      ),
    )
  }, [suggestedOptions])

  const suggestionsPermissions: Record<string, Permission[]> = useMemo(() => {
    return _.mapValues(_.groupBy(suggestedOptions, 'id'), (items) =>
      items.flatMap((item) => item.permissions!),
    )
  }, [suggestedOptions])

  const suggestedAndNotSelectedOptions = useMemo(
    () =>
      _.uniqBy(
        suggestedOptions?.filter(
          (suggested) => !selectedOptions?.some((selected) => selected.id === suggested.id),
        ),
        'id',
      ),
    [suggestedOptions, selectedOptions],
  )

  const suggestedAndNotSelectedSources = useMemo(
    () =>
      _.uniq(
        suggestedAndNotSelectedOptions
          .filter((suggested) => suggestionsSources[suggested.id!])
          .flatMap((suggested) => suggestionsSources[suggested.id!]),
      ),
    [suggestionsSources, suggestedAndNotSelectedOptions],
  )

  const itemMap: Record<string, InherentRiskItem> = useMemo(
    () => Object.fromEntries(options.map((item) => [item.id, item])),
    [options],
  )

  const trackSuggestionClick = useTrackCallback('third-party.irq.suggestions.click')

  return (
    <div className='max-w-xl'>
      <Select
        filterOption={(input, option) => {
          if (!option) return false
          return option.displayName.toLowerCase().includes(input.toLowerCase())
        }}
        size='large'
        fieldNames={{ label: 'displayName', value: 'id' }}
        onClear={() =>
          selectedOptions?.forEach(({ id }) =>
            updateIRQ({
              category: categoryEnum,
              changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
              riskCategoryId: id,
            }),
          )
        }
        optionRender={({ value }) => {
          if (!value || !itemMap[value]) return <></>

          const suggested = suggestedOptions?.filter((suggested) => suggested.id === value) || []
          const isSuggested = suggested.length > 0
          const sources: PlainMessage<Source>[] = suggested
            .flatMap((s) => suggestionsSources[s.id!])
            .filter((src) => !!src)

          return (
            <div className='flex items-center gap-4'>
              <SeverityToDot severity={itemMap[value].severity} />
              <span className='flex-1 truncate'>{itemMap[value].displayName}</span>
              {isSuggested && (
                <SourcesAvatarGroup
                  kind='default'
                  className='inherent-risk-sources absolute right-10 grow justify-end'
                  sources={sources.reverse()}
                  categoryEnum={categoryEnum}
                />
              )}
            </div>
          )
        }}
        mode={multipleSelect ? 'multiple' : undefined}
        allowClear
        className='relative w-full'
        placeholder={
          <div className='w-0 overflow-visible'>
            {multipleSelect ? 'Select one or more options' : 'Select one option'}
          </div>
        }
        options={options}
        onDeselect={(riskCategoryId) =>
          updateIRQ({
            category: categoryEnum,
            changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
            riskCategoryId,
          })
        }
        onSelect={(riskCategoryId) => {
          if (!multipleSelect) {
            const isSelected = selectedOptions?.some(({ id }) => id === riskCategoryId)

            if (isSelected) {
              // If already selected, just remove it
              // Antd doesn't support onDeselect on single select
              updateIRQ({
                category: categoryEnum,
                changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
                riskCategoryId,
              })
            } else {
              const updates = [
                // Remove existing selections
                ...(selectedOptions?.map(({ id }) => ({
                  category: categoryEnum,
                  changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
                  riskCategoryId: id,
                })) || []),
                // Add new selection
                {
                  category: categoryEnum,
                  changeType: IRQChangeType.IRQ_CHANGE_TYPE_ADD,
                  riskCategoryId,
                },
              ]

              updates.forEach((update) => updateIRQ(update))
            }
          } else {
            updateIRQ({
              category: categoryEnum,
              changeType: IRQChangeType.IRQ_CHANGE_TYPE_ADD,
              riskCategoryId,
            })
          }
        }}
        value={selectedOptions?.map((option) => option.id)}
        tagRender={({ value }) => {
          if (!value || !itemMap[value]) return <></>
          return (
            <InherentRiskItemTag
              category={categoryEnum}
              companyId={companyId}
              item={itemMap[value]}
            />
          )
        }}
      />
      {suggestedAndNotSelectedOptions && suggestedAndNotSelectedOptions.length > 0 && (
        <div className='group my-1 rounded border border-yellow-100 p-2'>
          <span className='my-1 flex items-center gap-0.5 text-sm'>
            <TriangleAlertIcon size={12} className='mr-0.5 text-yellow-500' /> Suggested{' '}
            <SparklesIcon size={12} className='mr-0.5' /> items not selected:
            <SourcesAvatarGroup
              kind='default'
              className='inherent-risk-sources grow justify-end pr-4 group-hover:opacity-40'
              sources={suggestedAndNotSelectedSources.reverse()}
              categoryEnum={categoryEnum}
            />
          </span>
          {suggestedAndNotSelectedOptions.map((suggested) => {
            const trackingProps = {
              sources: suggestionsSources?.[suggested.id!].map((s) => s?.name).join(', '),
              claim: suggested.displayName,
              permissions: suggestionsPermissions?.[suggested.id!].join(', '),
              explanation: suggested.explanation,
              categoryEnum,
              companyId,
            }
            return (
              <div className='flex cursor-pointer items-center' key={suggested.id}>
                <div className='flex grow'>
                  <div
                    className='flex grow items-center gap-2'
                    onClick={() => {
                      if (!multipleSelect) {
                        selectedOptions?.forEach(({ id }) =>
                          updateIRQ({
                            category: categoryEnum,
                            changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
                            riskCategoryId: id,
                          }),
                        )
                      }
                      updateIRQ({
                        category: categoryEnum,
                        changeType: IRQChangeType.IRQ_CHANGE_TYPE_ADD,
                        riskCategoryId: suggested.id,
                      })
                      trackSuggestionClick({
                        ...trackingProps,
                        changeType: IRQChangeType.IRQ_CHANGE_TYPE_ADD,
                        riskCategoryId: suggested.id,
                      })
                    }}
                  >
                    <InherentRiskItemTag
                      hideRemove
                      category={categoryEnum}
                      companyId={companyId}
                      item={suggested}
                    />
                    <Button
                      size='sm'
                      className='px-0.5 text-sm font-light text-gray-400 hover:text-gray-300 hover:no-underline'
                      variant={'link'}
                    >
                      <span className='text-nowrap'>Click to add</span>
                    </Button>
                  </div>
                  <SourcesAvatarGroup
                    kind='inherent-risk'
                    className='inherent-risk-sources justify-end pr-2 opacity-0 group-hover:opacity-100'
                    sources={suggestionsSources?.[suggested.id!].reverse() || []}
                    permissions={suggestionsPermissions?.[suggested.id!] || []}
                    claim={suggested.displayName}
                    explanation={suggested.explanation}
                    trackingProps={trackingProps}
                    categoryEnum={categoryEnum}
                  />
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

type InherentRiskItemTagProps = {
  item: InherentRiskItem
  companyId: string
  category?: InherentRiskCategoryEnum
  hideRemove?: boolean
}

export const InherentRiskItemTag = ({
  item,
  companyId,
  category,
  hideRemove,
}: InherentRiskItemTagProps) => {
  const { mutateAsync } = useOptimisticSetIRQItem(companyId)

  const updateIRQ = useCallback(
    (request: Partial<SetIRQItemRequest>) => {
      mutateAsync({ ...request, companyId })
    },
    [mutateAsync, companyId],
  )

  return (
    <TextWithIcon
      className='m-1 h-6 rounded-full border px-2 text-md duration-150 ease-in-out hover:bg-gray-50'
      iconPosition='end'
      icon={
        !hideRemove && (
          <XIcon
            size={16}
            className='cursor-pointer rounded p-0.5 duration-200 ease-in-out hover:bg-gray-200'
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() =>
              updateIRQ({
                category,
                changeType: IRQChangeType.IRQ_CHANGE_TYPE_REMOVE,
                riskCategoryId: item.id,
              })
            }
          />
        )
      }
      text={
        <div className='flex items-center gap-2'>
          <SeverityToDot severity={item.severity} />
          {item.displayName}
        </div>
      }
    />
  )
}

type SeverityToDotProps = {
  severity: RiskLevel
}

const SeverityToDot = ({ severity }: SeverityToDotProps) => (
  <div
    className='inline-block size-1.5 rounded-full'
    style={{
      backgroundColor: riskLevelToColor[severity],
    }}
  />
)
