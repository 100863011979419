import { CheckCircle2Icon } from 'lucide-react'

import { cn } from '@/lib/style-helpers'

type SelectListItem<T extends number | string> = {
  value: T
  label: React.ReactNode
  disabled?: boolean
}

type SelectListProps<T extends number | string> = {
  items: SelectListItem<T>[]
  selectedValue: T
  onSelect: (value: T) => void
}

export const SelectList = <T extends number | string>({
  items,
  selectedValue,
  onSelect,
}: SelectListProps<T>) => {
  return (
    <div className='flex flex-col gap-2'>
      {items.map((item, index) => (
        <SelectItem key={index} item={item} selectedValue={selectedValue} onSelect={onSelect} />
      ))}
    </div>
  )
}

type SelectItemProps<T extends number | string> = {
  item: SelectListItem<T>
  selectedValue: T
  onSelect: (value: T) => void
}

const SelectItem = <T extends number | string>({
  item,
  selectedValue,
  onSelect,
}: SelectItemProps<T>) => {
  return (
    <div
      onClick={() => !item.disabled && onSelect(item.value)}
      className={cn(
        'flex items-center justify-between rounded border-2 px-2 py-3 duration-150 ease-in-out',
        selectedValue === item.value && 'border-purple-600',
        item.disabled ? 'cursor-not-allowed opacity-70' : 'cursor-pointer hover:bg-gray-100',
      )}
    >
      {item.label}
      {selectedValue === item.value && <CheckCircle2Icon className='text-purple-600' size={18} />}
    </div>
  )
}
