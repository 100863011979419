import { PlainMessage } from '@bufbuild/protobuf'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ControlDataQueryKey } from '@/api/export-company.hook'
import {
  getConifgurableFactTypes,
  getControl,
  getControlTimeline,
  listControlScopes,
  listControlSettings,
  listControls,
  updateControl,
  updateControlSettings,
} from '@/gen/inventory/v1/control_service-ControlInventoryService_connectquery'
import { ListControlsRequest } from '@/gen/inventory/v1/control_service_pb'
import {
  getFinding,
  listFindings,
} from '@/gen/inventory/v1/finding_service-FindingInventoryService_connectquery'

export const useListControlScopes = () => {
  const queryState = useQuery(listControlScopes.useQuery())
  return {
    ...queryState,
    controlScopeTypes: queryState.data?.controlScopeTypes,
  }
}

export const useUpdateControlSettings = () => {
  const queryClient = useQueryClient()

  return useMutation(updateControlSettings.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listControlSettings.getQueryKey())
      queryClient.invalidateQueries(listControls.getQueryKey())
      queryClient.invalidateQueries(getControl.getQueryKey())
      queryClient.invalidateQueries(getFinding.getQueryKey())
      queryClient.invalidateQueries(listFindings.getQueryKey())
    },
  })
}

export const useControlSettings = () => {
  const queryState = useQuery(listControlSettings.useQuery())

  return {
    ...queryState,
    data: {
      controlSettings: queryState.data?.controlSettings.sort(
        (a, b) => a.orderPriority - b.orderPriority,
      ),
      factTypeToControlTypeSettings: queryState.data?.factTypeToControlTypeSettings,
    },
  }
}

export const useListControls = ({
  enabled = true,
  ...request
}: PlainMessage<ListControlsRequest> & { enabled?: boolean }) => {
  const queryState = useQuery({ ...listControls.useQuery(request), enabled })

  return {
    ...queryState,
    data: queryState.data?.controls.sort((a, b) => a.orderPriority - b.orderPriority) || [],
  }
}

export const useGetControl = (controlId?: string) => {
  const queryState = useQuery({ ...getControl.useQuery({ controlId }), enabled: !!controlId })

  return {
    ...queryState,
    data: queryState.data,
  }
}

export const useUpdateControl = (controlId: string) => {
  const queryClient = useQueryClient()

  return useMutation(updateControl.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listControls.getQueryKey())
      queryClient.invalidateQueries(getControl.getQueryKey({ controlId }))
      queryClient.invalidateQueries(getControlTimeline.getQueryKey({ controlId }))
      queryClient.invalidateQueries([ControlDataQueryKey])
    },
  })
}

export const useControlTimeline = (controlId: string) => {
  const queryState = useQuery(getControlTimeline.useQuery({ controlId }))
  return {
    ...queryState,
    data: queryState.data?.items || [],
  }
}

export const useGetConifgurableFactTypes = () => {
  const queryState = useQuery(getConifgurableFactTypes.useQuery())
  return {
    ...queryState,
    data: queryState.data,
  }
}
