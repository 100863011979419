import { useGetCompany, useUpdateCompany } from '@/api/company.hook'
import { useOptimisticSetIRQItem } from '@/api/optimistic-set-irq.hook'
import { IRQChangeType } from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { riskLevelLabel } from '@/const/label'

import { EventProperties, useTrackCallback } from '@/lib/analytics/events'
import { useUrlParams } from '@/lib/url-param.hook'

export const useSubmitIRQ = (companyId: string) => {
  const { data: company } = useGetCompany(companyId)
  const { mutateAsync: updateCompany, isLoading: isUpdatingCompany } = useUpdateCompany(companyId)
  const { urlParams } = useUrlParams()
  const trackRiskUpdate = useTrackCallback('third-party.risk.update')
  const trackIRQSubmit = useTrackCallback('third-party.irq.submit')

  const { mutateAsync: updateIrq, isLoading: isUpdatingIRQ } = useOptimisticSetIRQItem(companyId)

  const submitIRQ = async (
    newRiskLevel: RiskLevel,
    suggestedRiskLevel: RiskLevel,
    comment: string,
    extraTrackingProps?: EventProperties,
  ) => {
    const previousRiskLevel = company?.company?.risk || RiskLevel.UNSPECIFIED
    if (newRiskLevel !== company?.company?.risk) {
      trackRiskUpdate({
        tab: urlParams.companyDrawerTab,
        company: company?.company?.profile?.name,
        riskLevel: riskLevelLabel[newRiskLevel],
        previousRiskLevel: riskLevelLabel[previousRiskLevel],
        suggestedRiskLevel: riskLevelLabel[suggestedRiskLevel],
        companyId,
        comment,
        ...extraTrackingProps,
      })
      await updateCompany({
        companyRiskLevel: newRiskLevel,
        id: companyId,
        comment: comment || undefined,
      })
    }

    trackIRQSubmit({
      tab: urlParams.companyDrawerTab,
      company: company?.company?.profile?.name,
      riskLevel: riskLevelLabel[newRiskLevel],
      previousRiskLevel: riskLevelLabel[previousRiskLevel],
      suggestedRiskLevel: riskLevelLabel[suggestedRiskLevel],
      id: companyId,
      comment,
      ...extraTrackingProps,
    })

    await updateIrq({
      companyId: companyId,
      changeType: IRQChangeType.IRQ_CHANGE_TYPE_FINISHED,
      comment: comment || undefined,
    })
  }

  return { submitIRQ, isLoading: isUpdatingCompany || isUpdatingIRQ }
}
