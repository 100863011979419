import { PartialMessage, PlainMessage } from '@bufbuild/protobuf'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { User } from '@/gen/customer/management/v1/service_pb'
import {
  getProject,
  listProjects,
  setProjectMember,
} from '@/gen/inventory/v1/assessment_service-AssessmentService_connectquery'
import {
  GetProjectResponse,
  ProjectAttributeChangeType,
  SetProjectMemberRequest,
} from '@/gen/inventory/v1/assessment_service_pb'

export const useOptimisticSetProjectMember = (projectId: string) => {
  const queryClient = useQueryClient()
  const mutation = setProjectMember.useMutation()

  return useMutation(mutation.mutationFn, {
    ...mutation,
    onMutate: async (variables) => {
      await queryClient.cancelQueries(getProject.getQueryKey({ projectId }))

      const previousData = queryClient.getQueryData<PlainMessage<GetProjectResponse>>(
        getProject.getQueryKey({ projectId }),
      )

      queryClient.setQueryData<PlainMessage<GetProjectResponse> | undefined>(
        getProject.getQueryKey({ projectId }),
        (oldData) => updateProjectData(oldData, variables),
      )

      return { previousData }
    },
    onError: (_error, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(getProject.getQueryKey({ projectId }), context.previousData)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(getProject.getQueryKey({ projectId }))
      queryClient.invalidateQueries(listProjects.getQueryKey())
    },
  })
}

const updateProjectData = (
  oldData: PlainMessage<GetProjectResponse> | undefined,
  variables: PartialMessage<SetProjectMemberRequest>,
): PlainMessage<GetProjectResponse> | undefined => {
  if (!oldData) {
    return oldData
  }

  const { userId, changeType } = variables

  const newData: PlainMessage<GetProjectResponse> = {
    ...oldData,
    project: {
      id: oldData.project?.id ?? '',
      name: oldData.project?.name ?? '',
      ...oldData.project,
      members: (() => {
        if (changeType === ProjectAttributeChangeType.ADD) {
          const memberAlreadyExists = oldData.project?.members?.some(
            (member) => member.id === userId,
          )
          if (memberAlreadyExists) {
            return oldData.project?.members ?? []
          }
          return [...(oldData.project?.members || []), { id: userId } as PlainMessage<User>]
        } else if (changeType === ProjectAttributeChangeType.REMOVE) {
          return (oldData.project?.members || []).filter((member) => member.id !== userId)
        }
        return oldData.project?.members ?? []
      })(),
    },
  }

  return newData
}
