import { Steps as AntdSteps, Modal } from 'antd'
import { Loader2Icon } from 'lucide-react'
import { ReactElement, useMemo, useState } from 'react'

import { useListProjects, useStartAssessment } from '@/api/assessments.hook'
import { useGetCompany, useUpdateCompany } from '@/api/company.hook'
import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { useTrackCallback } from '@/lib/analytics/events'
import { cn } from '@/lib/style-helpers'
import { useCalculateInherentRisk } from '@/lib/use-calculate-inherent-risk'

import { useSubmitIRQ } from '@/pages/assessment/irq/use-submit-irq'
import { AssessmentStep } from '@/pages/company-drawer/company-overview/set-irq-modal/assessment'
import { IrqStep } from '@/pages/company-drawer/company-overview/set-irq-modal/irq-step'

import { Button } from '@/components/ui/button'

import { SelectInherentRiskStep } from './select-inherent-risk-step'

export type StepProps = {
  setIsNextEnabled: (isNextEnabled: boolean) => void
}

type Step = {
  id: string
  title: string
  component: ReactElement
}

type SetIrqModalProps = {
  companyId: string
  mode?: 'edit' | 'set'
  from?: string
}

const IRQ_STEP_ID = 'irq'
const SELECT_INHERENT_RISK_STEP_ID = 'select-inherent-risk'
const ASSESSMENT_STEP_ID = 'assessment'

export const useSetIrqModal = ({ companyId, from, mode = 'set' }: SetIrqModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { data: company } = useGetCompany(companyId)
  const { projects } = useListProjects()
  const { mutateAsync: updateCompany, isLoading: isUpdatingCompany } = useUpdateCompany(companyId)
  const { mutateAsync: startAssessment, isLoading: isStartingAssessment } =
    useStartAssessment(companyId)
  const [selectedStatus, setSelectedStatus] = useState<CompanyStatus>(CompanyStatus.IN_ASSESSMENT)
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([])
  const suggestedRiskLevel = useCalculateInherentRisk(companyId)
  const trackAssessmentStart = useTrackCallback('third-party.assessment.start')
  const [comment, setComment] = useState('')
  const { submitIRQ, isLoading: isSubmittingIRQ } = useSubmitIRQ(companyId)
  const [selectedRiskLevel, setSelectedRiskLevel] = useState<RiskLevel>(RiskLevel.UNSPECIFIED)

  const showModal = () => setIsOpen(true)

  const [currentStep, setCurrentStep] = useState(0)

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const steps: Step[] = useMemo(() => {
    const steps = [
      { id: IRQ_STEP_ID, title: 'IRQ', component: <IrqStep companyId={companyId} /> },
      {
        id: SELECT_INHERENT_RISK_STEP_ID,
        title: 'Assign Inherent Risk',
        component: (
          <SelectInherentRiskStep
            suggestedRiskLevel={suggestedRiskLevel}
            comment={comment}
            setComment={setComment}
            setSelectedRiskLevel={setSelectedRiskLevel}
            selectedRiskLevel={selectedRiskLevel}
          />
        ),
      },
    ]

    if (mode === 'set') {
      steps.push({
        id: ASSESSMENT_STEP_ID,
        title: 'Assessment',
        component: (
          <AssessmentStep
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedProjectIds={selectedProjectIds}
            setSelectedProjectIds={setSelectedProjectIds}
          />
        ),
      })
    }

    return steps
  }, [
    companyId,
    mode,
    suggestedRiskLevel,
    comment,
    selectedRiskLevel,
    selectedStatus,
    selectedProjectIds,
  ])

  const closeModal = () => {
    setSelectedStatus(CompanyStatus.IN_ASSESSMENT)
    setSelectedRiskLevel(RiskLevel.UNSPECIFIED)
    setComment('')
    setSelectedProjectIds([])
    setCurrentStep(0)
    setIsOpen(false)
  }
  const isLastStep = currentStep === steps.length - 1
  const isFirstStep = currentStep === 0

  const onFinish = async () => {
    const projectNames = selectedProjectIds
      .map((id) => projects.find((p) => p.id === id)?.name)
      .filter((name) => name) as string[]

    const riskLevel =
      selectedRiskLevel === RiskLevel.UNSPECIFIED ? suggestedRiskLevel : selectedRiskLevel
    await submitIRQ(riskLevel, suggestedRiskLevel, comment, { projectNames })

    if (mode === 'set') {
      if (projects.length > 0) {
        for (const projectId of selectedProjectIds) {
          await startAssessment({ projectId, companyId })
        }
      }
      await updateCompany({ status: selectedStatus, id: companyId })
      trackAssessmentStart({
        from,
        companyId,
        companyName: company?.company?.profile?.name,
        projectNames,
      })
    }
  }

  const next = async () => {
    if (!isLastStep) {
      setCurrentStep(currentStep + 1)
    } else {
      await onFinish()
      closeModal()
    }
  }

  const isLoading = isSubmittingIRQ || isUpdatingCompany || isStartingAssessment

  const isFinishEnabled = useMemo(() => {
    if (mode === 'set' && steps[currentStep].id === ASSESSMENT_STEP_ID && projects.length > 0) {
      return selectedStatus !== CompanyStatus.IN_ASSESSMENT || selectedProjectIds.length > 0
    }
    return true
  }, [mode, selectedProjectIds, selectedStatus, currentStep, steps, projects])

  const renderModal = () => (
    <Modal
      key={isOpen ? 'open' : 'closed'}
      width={700}
      styles={{
        content: { padding: 0 }, // since we have colored header, we don't need padding
      }}
      open={isOpen}
      onCancel={(e) => {
        e.stopPropagation()
        closeModal()
      }}
      footer={
        <div
          // Prevent OnRow onClick from firing
          onClick={(e) => e.stopPropagation()}
          className='flex items-end justify-between px-15 py-6'
        >
          <Button variant={'ghost'} onClick={() => closeModal()}>
            Cancel
          </Button>
          <div className='flex gap-2'>
            {!isFirstStep && (
              <Button variant={'outline'} onClick={prev}>
                Back
              </Button>
            )}
            <Button
              className='flex items-center gap-1'
              disabled={isLoading || !isFinishEnabled}
              onClick={next}
            >
              {isLoading && <Loader2Icon className='size-3.75 animate-spin' />}
              {isLastStep ? (mode === 'edit' ? 'Save & Close' : 'Finish 🎉 ') : 'Next'}
            </Button>
          </div>
        </div>
      }
    >
      <div
        // Prevent OnRow onClick from firing
        onClick={(e) => e.stopPropagation()}
      >
        <div className='w-full rounded-t bg-gray-50 px-20 pt-10'>
          <h1 className='text-3xl font-bold'>
            {mode === 'edit'
              ? 'Edit Inherent Risk Profile'
              : `Score this third-party's inherent risk`}
          </h1>
          <AntdSteps
            className={cn('py-8', {
              'w-2/3': mode === 'edit',
            })}
            size='small'
            current={currentStep}
            items={steps}
          />
        </div>
        <div className='px-20'>{steps[currentStep].component}</div>
      </div>
    </Modal>
  )

  return { showModal, renderModal }
}
