import _ from 'lodash'
import { HomeIcon, MinusIcon, ShieldIcon, UserSquare2Icon } from 'lucide-react'
import { useMemo } from 'react'

import { useListProjects } from '@/api/assessments.hook'
import { Company } from '@/gen/inventory/v1/company_service_pb'

import { assessmentDueStatusLabel, companyStatusLabel, riskLevelLabel } from '@/const/label'
import { companyStatusPriority, riskLevelPriority } from '@/const/priority'

import {
  calculateAssessmentDueDate,
  calculateAssessmentDueStatus,
} from '@/lib/assessment-due-status'
import { formatCadence, formatDate, relativeTimeFormatted } from '@/lib/date'
import { useFeatureFlagEnabled } from '@/lib/featureflag'
import { cn } from '@/lib/style-helpers'

import { QuestionnairesProgressSummary } from '@/pages/third-party-inventory/questionnaires-progress-summary'
import { ThirdPartyColumnCell } from '@/pages/third-party-inventory/third-party-column-cell'

import { AssessmentDueStatus } from '@/components/badge/assessment-due-status'
import { ProjectBadge } from '@/components/badge/project'
import { CompanyVerificationBadge } from '@/components/badge/third-party-status'
import { ControlDistribution } from '@/components/control-distribution'
import { DataTypeTableCell } from '@/components/data-type/data-types-table-cell'
import { FindingDistribution } from '@/components/finding-distribution'
import { CompanyRiskIcon } from '@/components/icons/company-risk'
import { RiskLevelSymbol } from '@/components/icons/risk-severity'
import { SourcesAvatarGroup } from '@/components/sources-avatar-group'
import { ColumnType } from '@/components/table/table.type'
import { TextWithIcon } from '@/components/text-with-icon'
import { UserScoreInfo } from '@/components/user-score-info'

import TierSymbol from '@/assets/tier-symbol.svg'

const EXTRA_SMALL_COLUMN_WIDTH = 85
const SMALL_COLUMN_WIDTH = 120
const MEDIUM_COLUMN_WIDTH = 190
const LARGE_COLUMN_WIDTH = 220
const EXTRA_LARGE_COLUMN_WIDTH = 240

const dateAddedColumn: ColumnType<Company> = {
  width: SMALL_COLUMN_WIDTH,
  title: 'Date Added',
  render: ({ createdAt }: Company) => (
    <span className='text-nowrap text-md font-light'>
      {createdAt && formatDate(createdAt?.toDate())}
    </span>
  ),
  accessor: ({ createdAt }) => {
    return {
      sortValue: createdAt && createdAt.toDate(),
    }
  },
}

const businessOwnerColumn: ColumnType<Company> = {
  width: MEDIUM_COLUMN_WIDTH,
  title: 'Business Owner',
  key: 'businessOwner',
  render: ({ businessOwnerName }: Company) => (
    <TextWithIcon
      className={cn('w-full truncate', {
        'text-gray-400': !businessOwnerName,
      })}
      text={businessOwnerName || 'Unassigned'}
      icon={<UserSquare2Icon className='size-3.75 min-w-3.75' strokeWidth={1.5} />}
    />
  ),
}

const thirdPartyColumn: ColumnType<Company> = {
  title: <TextWithIcon text='Third-Party' icon={<HomeIcon className='size-3' />} />,
  key: 'thirdParty',
  search: true,
  render: (company: Company) => <ThirdPartyColumnCell company={company} />,
  accessor: ({ profile, solutions }) => ({
    sortValue: profile?.name,
    searchValue: profile?.name + ' ' + profile?.shortDescription + ' ' + solutions.join(' '),
  }),
}

const assessmentDueDateColumn: ColumnType<Company> = {
  width: EXTRA_LARGE_COLUMN_WIDTH,
  key: 'assessmentDueDate',
  filter: true,
  accessor: ({ assessmentCadence, statusLastUpdate }) => {
    const dueDate = calculateAssessmentDueDate(
      statusLastUpdate?.toDate(),
      assessmentCadence?.seconds,
    )

    return {
      sortValue: dueDate,
      filterValue: assessmentDueStatusLabel[calculateAssessmentDueStatus(dueDate)],
    }
  },
  title: 'Assessment Due',
  render: ({ assessmentCadence, statusLastUpdate }: Company) => {
    const dueDate = calculateAssessmentDueDate(
      statusLastUpdate?.toDate(),
      assessmentCadence?.seconds,
    )

    const dueDateText = dueDate
      ? ` • ${formatDate(dueDate)} (${relativeTimeFormatted(dueDate)})`
      : ''

    return (
      <span className='text-nowrap text-xs text-gray-500'>
        {<AssessmentDueStatus dueDate={dueDate} />}
        {formatCadence(assessmentCadence?.seconds)}
        {dueDateText}
      </span>
    )
  },
}

const subprocessorFilter: ColumnType<Company> = {
  width: 0,
  hidden: true, // Used for filtering
  title: 'subprocessor',
  key: 'subprocessor',
  filter: true,
  filterTitle: 'Sub-processor',
  accessor: ({ isSubprocessor, isSuggestedSubprocessor }) => {
    let filterValue = 'No'
    if (isSubprocessor) {
      filterValue = 'Yes'
    } else if (isSuggestedSubprocessor) {
      filterValue = 'Suggested'
    }

    return {
      filterValue,
    }
  },
}

const dataTypesFilter: ColumnType<Company> = {
  hidden: true, // Used for filtering
  title: 'Data Types',
  key: 'dataTypesFilter',
  filter: true,
  filterTitle: 'Data Types',
  accessor: ({ dataTypes }) => {
    return {
      filterValue: dataTypes.map(({ dataType }) => dataType),
    }
  },
}

const inherentRiskColumn: ColumnType<Company> = {
  width: EXTRA_SMALL_COLUMN_WIDTH,
  filterTitle: 'Inherent Risk',
  title: (
    <div className='flex w-5 items-center justify-center'>
      <TierSymbol />
    </div>
  ),
  key: 'riskLevel',
  defaultSortOrder: 'ascend',
  render: ({ risk, calculatedRisk }: Company) => (
    <CompanyRiskIcon showTooltip riskLevel={risk} calculatedRisk={calculatedRisk} />
  ),
  filter: true,
  accessor: ({ risk }) => ({
    filterValue: riskLevelLabel[risk],
    sortValue: riskLevelPriority[risk],
  }),
}

const findingsColumn: ColumnType<Company> = {
  width: LARGE_COLUMN_WIDTH,
  key: 'Findings',
  title: <TextWithIcon text='Findings' icon={<RiskLevelSymbol />} />,
  render: ({ findingsByLevel }: Company) => (
    <FindingDistribution
      noFindingsComponent={<TextWithIcon className='text-gray-400' text='No Findings' />}
      findingCountByLevel={findingsByLevel}
    />
  ),
}

const statusUpdatedColumn: ColumnType<Company> = {
  width: SMALL_COLUMN_WIDTH,
  title: 'Status Updated',
  responsive: ['xxl'],
  render: ({ statusLastUpdate }: Company) => (
    <span className='text-nowrap text-md font-light'>
      {statusLastUpdate && formatDate(statusLastUpdate?.toDate())}
    </span>
  ),
  accessor: ({ statusLastUpdate }) => {
    return {
      sortValue: statusLastUpdate && statusLastUpdate.toDate(),
    }
  },
}

export const inventoryColumns: ColumnType<Company>[] = [
  inherentRiskColumn,
  thirdPartyColumn,
  findingsColumn,
  subprocessorFilter,
  {
    width: MEDIUM_COLUMN_WIDTH,
    title: 'Status',
    key: 'status',
    ellipsis: true,
    filter: true,
    render: ({ status }: Company) => <CompanyVerificationBadge status={status} />,
    accessor: ({ status }) => {
      return {
        filterValue: companyStatusLabel[status],
        sortValue: companyStatusPriority[status],
      }
    },
  },
  {
    width: EXTRA_LARGE_COLUMN_WIDTH,
    responsive: ['xl'],
    title: 'Datatypes',
    key: 'dataTypes',
    sort: false,
    render: ({ dataTypes }: Company) => <DataTypeTableCell dataTypes={dataTypes} />,
  },
  dataTypesFilter,
  statusUpdatedColumn,
  {
    width: SMALL_COLUMN_WIDTH,
    sort: false,
    title: 'Sources',
    dataIndex: 'sources',
    render: (sources) => <SourcesAvatarGroup sources={sources} />,
    filter: true,
    accessor: ({ sources }) => {
      return {
        filterValue: sources.map(({ name }) => name),
      }
    },
  },
  dateAddedColumn,
]

export const inventoryColumnsWithoutStatus = inventoryColumns.filter(
  (column) => column.key !== 'status',
)

export const useGetInAssessmentColumns = () => {
  const { isEnabled: isQuestionnaireEnabled } = useFeatureFlagEnabled('questionnaire-module-v2')
  const { data: projects } = useListProjects(true)
  const projectsMap = useMemo(() => _.keyBy(projects?.projects, 'id'), [projects])

  const inAssessmentColumns: ColumnType<Company>[] = [
    {
      title: 'Project',
      dataIndex: 'ongoingProjectsIds',
      hidden: true,
      filterType: 'select',
      filter: projects?.projects.length && projects?.projects.length > 0 ? true : undefined,
      optionRender: (value, isTag) => {
        if (isTag) {
          return projectsMap[value]?.name
        }
        return <ProjectBadge projectName={projectsMap[value]?.name} />
      },
      accessor: ({ ongoingProjectsIds }) => {
        return {
          filterValue: ongoingProjectsIds,
        }
      },
    },
    inherentRiskColumn,
    thirdPartyColumn,
    findingsColumn,
    subprocessorFilter,
    {
      width: MEDIUM_COLUMN_WIDTH,
      title: <TextWithIcon text='Unvalidated Controls' icon={<ShieldIcon className='size-3' />} />,
      key: 'unvalidatedControls',
      render: ({ controlsByStatus }: Company) => (
        <ControlDistribution hideValidated controlCountByLevel={controlsByStatus} />
      ),
    },
    {
      hidden: !isQuestionnaireEnabled,
      width: LARGE_COLUMN_WIDTH,
      filter: true,
      title: 'Questionnaire',
      render: ({ id, totalQuestionnaires }: Company) => {
        if (totalQuestionnaires === 0) {
          return <MinusIcon className='text-gray-400' />
        }
        return <QuestionnairesProgressSummary companyId={id} />
      },
      accessor: ({ totalQuestionnaires, totalOngoingQuestionnaires }) => {
        if (totalQuestionnaires === 0) {
          return {
            sortValue: 2,
            filterValue: 'N/A',
          }
        }
        if (totalOngoingQuestionnaires === 0) {
          return {
            sortValue: 1,
            filterValue: 'Completed',
          }
        }
        return {
          sortValue: 0,
          filterValue: 'In Progress',
        }
      },
    },
    businessOwnerColumn,
  ]
  return inAssessmentColumns
}

export const useAssessmentRequiredColumns = () => {
  const assessmentRequiredColumns: ColumnType<Company>[] = [
    {
      width: EXTRA_SMALL_COLUMN_WIDTH,
      filterTitle: 'Inherent Risk',
      title: (
        <div className='flex w-5 items-center justify-center'>
          <TierSymbol />
        </div>
      ),
      key: 'riskLevel',
      defaultSortOrder: 'ascend',
      render: ({ risk, calculatedRisk }: Company) => (
        <CompanyRiskIcon showTooltip riskLevel={risk} calculatedRisk={calculatedRisk} />
      ),
      filter: true,
      accessor: ({ risk }) => ({
        filterValue: riskLevelLabel[risk],
        sortValue: riskLevelPriority[risk],
      }),
    },
    thirdPartyColumn,
    subprocessorFilter,
    {
      width: SMALL_COLUMN_WIDTH,
      title: <TextWithIcon text=' Scored By' icon={<TierSymbol />} />,
      key: 'lastScorer',
      render: ({ id }: Company) => <UserScoreInfo variant='compact' companyId={id} />,
    },
    {
      width: LARGE_COLUMN_WIDTH,
      key: 'Findings',
      title: <TextWithIcon text='Findings' icon={<RiskLevelSymbol />} />,
      render: ({ findingsByLevel }: Company) => (
        <FindingDistribution
          noFindingsComponent={<TextWithIcon className='text-gray-400' text='No Findings' />}
          findingCountByLevel={findingsByLevel}
        />
      ),
    },
    businessOwnerColumn,
    dateAddedColumn,
  ]
  return assessmentRequiredColumns
}

export const useAssessedColumns = () => {
  const assessedColumns: ColumnType<Company>[] = [
    inherentRiskColumn,
    thirdPartyColumn,
    assessmentDueDateColumn,
    findingsColumn,
    statusUpdatedColumn,
    dateAddedColumn,
    subprocessorFilter,
  ]
  return assessedColumns
}
