import { Timestamp } from '@bufbuild/protobuf'
import { useMemo } from 'react'

import { useGetCompanyActivityLog } from '@/api/company.hook'

import { LEMA_USER_ID } from '@/const/contact'

export const useLastIrqChange = (companyId: string) => {
  const { data: activityLogData, isLoading } = useGetCompanyActivityLog(companyId)
  const lastIRQChange = useMemo(() => {
    const irqLogs = activityLogData?.filter(
      (item) =>
        item.activityLogItem.case === 'riskChange' || item.activityLogItem.case === 'irqUpdate',
    )

    const sortedIrqLogs = irqLogs?.sort(
      (a, b) => (b?.time?.toDate().getTime() || 0) - (a?.time?.toDate().getTime() || 0),
    )

    const lastScorer = sortedIrqLogs[0]
    if (!lastScorer) {
      const time = activityLogData?.[0]?.time || Timestamp.fromDate(new Date())
      return {
        userId: LEMA_USER_ID,
        time: time,
      }
    }

    return { userId: lastScorer?.userId || LEMA_USER_ID, time: lastScorer?.time }
  }, [activityLogData])

  return { lastIRQChange, isLoading }
}
