import { Form, Input, Modal, Select } from 'antd'
import { cva } from 'class-variance-authority'
import _ from 'lodash'
import { LockIcon, PlusIcon, Trash2Icon, XIcon } from 'lucide-react'
import { useMemo, useState } from 'react'

import {
  useCreateProject,
  useDeleteProject,
  useGetProject,
  useListProjects,
  useUpdateProjectName,
} from '@/api/assessments.hook'
import { useListUsers } from '@/api/list-users.hook'
import { useOptimisticSetProjectControlScope } from '@/api/optimistic-set-project-control-scope.hook'
import { useOptimisticSetProjectMember } from '@/api/optimistic-set-project-member.hook'
import {
  ControlScope,
  Project,
  ProjectAttributeChangeType,
  ScopeStatus,
} from '@/gen/inventory/v1/assessment_service_pb'

import { useTrackCallback } from '@/lib/analytics/events'

import { EditableText } from '@/components/editable-text'
import { Loading } from '@/components/loading'
import { StringNameAvatar } from '@/components/string-name-avatar'
import { VerticalTabs } from '@/components/tabs/vertical'
import { TextWithIcon } from '@/components/text-with-icon'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Tooltip } from '@/components/ui/tooltip'
import { useToast } from '@/components/ui/use-toast'
import { UserAvatar } from '@/components/user-avatar'

const { confirm } = Modal

export const ProjectsSection = () => {
  const { projects, isLoading } = useListProjects()
  const { showModal, renderModal } = useAddProjectModal()

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className='max-w-5xl'>
      <h2 className='mb-3 self-start text-3xl font-bold'>Projects</h2>
      <div className='mb-6'>
        Assign users to projects for delegating responsibilities within Lema.
      </div>
      {projects.length === 0 ? (
        <EmptyProjectsCard />
      ) : (
        <VerticalTabs
          variant='secondary'
          items={[
            ...projects
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((project) => ({
                label: (
                  <TextWithIcon
                    icon={
                      <StringNameAvatar name={project.name} className='size-4 rounded text-xs' />
                    }
                    text={project.name}
                  />
                ),
                component: <ProjectItem projectId={project.id} />,
                index: project.id,
              })),
          ]}
          actionItem={
            <Button variant='text' className='text-gray-500' onClick={() => showModal()}>
              <TextWithIcon icon={<PlusIcon className='size-4' />} text='Add Project' />
            </Button>
          }
        />
      )}
      {renderModal()}
    </div>
  )
}

type ProjectItemProps = {
  projectId: Project['id']
}

const ProjectItem = ({ projectId }: ProjectItemProps) => {
  const { data: users } = useListUsers()
  const { mutateAsync: setProjectMember } = useOptimisticSetProjectMember(projectId)
  const trackUpdateProject = useTrackCallback('project.update')
  const trackDeleteProject = useTrackCallback('project.delete')
  const { toast } = useToast()

  const { mutateAsync: updateProjectName } = useUpdateProjectName()
  const { mutateAsync: deleteProject } = useDeleteProject()
  const { project, members, isLoading, controlScopes } = useGetProject(projectId)

  const userIdToUserMap = useMemo(() => {
    return _.keyBy(users, 'id')
  }, [users])

  if (isLoading) {
    return <Loading />
  }

  const handleSaveProjectName = async (newName: string, prevName?: string): Promise<void> => {
    try {
      trackUpdateProject({
        projectId,
        newName,
        prevName,
        updateType: 'name',
      })
      await updateProjectName({ projectId, newName })
    } catch (error) {
      toast({
        status: 'error',
        title: 'Failed to update project name',
      })
    }
  }

  const handleDeleteProject = () => {
    confirm({
      okType: 'danger',
      icon: null,
      title: 'Delete this project?',
      content: (
        <div className='font-light'>
          This project will be deleted. All work related to this project will still exist, but you
          will no longer be able to complete new assessments under this project.
        </div>
      ),
      okText: <TextWithIcon icon={<Trash2Icon className='size-4' />} text='Delete' />,
      onOk: async () => {
        await deleteProject({ projectId })
        trackDeleteProject({ projectId, name: project?.name })
      },
    })
  }

  const handleMemberUpdate = async (userId: string, changeType: ProjectAttributeChangeType) => {
    await setProjectMember({
      projectId,
      userId,
      changeType,
    })
    trackUpdateProject({
      projectId,
      projectName: project?.name,
      updateType: 'member',
      changeType: changeType === ProjectAttributeChangeType.ADD ? 'add' : 'remove',
      memberId: userId,
      memberName: userIdToUserMap[userId]?.name,
      memberEmail: userIdToUserMap[userId]?.email,
    })
  }

  return (
    <div className='rounded border p-4'>
      <div className='flex items-center justify-between'>
        <TextWithIcon
          className='gap-1.5 text-3xl font-bold'
          icon={
            <StringNameAvatar
              name={project?.name || ''}
              className='size-5 rounded text-sm font-normal'
            />
          }
          text={
            <EditableText
              className='h-8 text-3xl font-bold'
              text={project?.name || ''}
              onSave={(newName) => handleSaveProjectName(newName, project?.name)}
            />
          }
        />
        <Tooltip
          trigger={
            <Button size={'sm'} variant='text' onClick={handleDeleteProject}>
              <Trash2Icon className='size-4' />
            </Button>
          }
        >
          Delete Project
        </Tooltip>
      </div>
      <h3 className='mt-4 text-lg font-semibold'>Members</h3>

      <Select
        variant='borderless'
        tagRender={({ value }) => (
          <div className='m-1 flex h-6 items-center gap-1 rounded bg-gray-100 pl-1.5'>
            <UserAvatar userId={value} className='!size-3.5 text-xs' withTooltip={false} />
            <span>{userIdToUserMap[value]?.name}</span>
            <Button
              variant='text'
              size='sm'
              onMouseDown={(e) => {
                e.stopPropagation()
                handleMemberUpdate(value, ProjectAttributeChangeType.REMOVE)
              }}
            >
              <XIcon className='size-3' />
            </Button>
          </div>
        )}
        options={users
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((user) => ({
            label: user.name,
            value: user.id,
            key: user.id,
          }))}
        value={members?.map((member) => member.id).sort()}
        placeholder={'Name or email'}
        mode='multiple'
        filterOption={(input, option) => {
          if (!option) return false
          const user = userIdToUserMap[option?.value]
          if (!user) return false
          return (
            user.name.toLowerCase().includes(input.toLowerCase()) ||
            user.email.toLowerCase().includes(input.toLowerCase())
          )
        }}
        className='w-full'
        size='large'
        onDeselect={(value) => handleMemberUpdate(value, ProjectAttributeChangeType.REMOVE)}
        onSelect={(value) => handleMemberUpdate(value, ProjectAttributeChangeType.ADD)}
      />

      <h3 className='mb-2 mt-4 text-lg font-semibold'>Control Scopes</h3>
      <div className='flex flex-wrap gap-1.5'>
        {controlScopes?.map((scope) => (
          <ControlScopeBadge
            key={scope.id}
            scope={scope}
            projectId={projectId}
            projectName={project?.name}
          />
        ))}
      </div>
    </div>
  )
}

const controlScopeBadgeVariant = cva('cursor-pointer flex items-center gap-1 text-md h-6', {
  variants: {
    status: {
      [ScopeStatus.ACTIVE]: 'bg-purple-50 border-purple-700 text-purple-700',
      [ScopeStatus.UNAVAILABLE]: 'opacity-50 cursor-not-allowed',
      [ScopeStatus.INACTIVE]: '',
      [ScopeStatus.UNSPECIFIED]: '',
    },
  },
})

const ControlScopeBadge = ({
  scope,
  projectId,
  projectName,
}: {
  scope: ControlScope
  projectId: string
  projectName?: string
}) => {
  const { mutateAsync: setProjectControlScope } = useOptimisticSetProjectControlScope(projectId)
  const trackUpdateProject = useTrackCallback('project.update')

  const changeType =
    scope.status === ScopeStatus.ACTIVE
      ? ProjectAttributeChangeType.REMOVE
      : ProjectAttributeChangeType.ADD

  const isUnavailable = scope.status === ScopeStatus.UNAVAILABLE

  const handleScopeUpdate = async () => {
    if (isUnavailable) return
    await setProjectControlScope({
      projectId,
      scopeId: scope.id,
      changeType,
    })
    trackUpdateProject({
      projectId,
      updateType: 'scope',
      changeType: changeType === ProjectAttributeChangeType.ADD ? 'add' : 'remove',
      scopeId: scope.id,
      scopeName: scope.name,
      projectName,
    })
  }

  return (
    <Tooltip
      tooltipDisabled={!isUnavailable}
      trigger={
        <Badge
          variant='outline'
          className={controlScopeBadgeVariant({ status: scope.status })}
          onClick={handleScopeUpdate}
        >
          {isUnavailable && <LockIcon className='size-2.75' />}
          {scope.name}
        </Badge>
      }
    >
      Another project covers this scope
    </Tooltip>
  )
}

const useAddProjectModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { mutateAsync: createProject } = useCreateProject()
  const trackCreateProject = useTrackCallback('project.create')
  const { toast } = useToast()
  const [form] = Form.useForm()

  const closeModal = () => {
    setIsOpen(false)
    form.resetFields()
  }

  const showModal = () => {
    setIsOpen(true)
  }

  const handleSubmit = async (values: { name: string }) => {
    try {
      const { projectId } = await createProject({ name: values.name })
      trackCreateProject({
        projectId,
        name: values.name,
      })
      toast({
        status: 'success',
        title: 'Project created',
      })
      closeModal()
    } catch (error) {
      toast({
        status: 'error',
        title: 'Failed to create project',
      })
    }
  }

  const renderModal = () => (
    <Modal
      key={isOpen ? 'open' : 'closed'}
      width={500}
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => closeModal()}
      title='New Project'
    >
      <Form form={form} onFinish={handleSubmit} layout='vertical' autoComplete='off'>
        <Form.Item name='name' rules={[{ required: true, message: 'Please enter a project name' }]}>
          <Input placeholder='Project Name' autoFocus />
        </Form.Item>
      </Form>
    </Modal>
  )

  return { showModal, renderModal }
}

const EmptyProjectsCard = () => {
  const { showModal, renderModal } = useAddProjectModal()
  return (
    <div className='flex h-full flex-col items-center justify-center gap-3 rounded border border-dashed border-purple-400 py-14'>
      <h4 className='font-semibold'>No Projects Yet</h4>
      <span>Assign users and scopes to projects to keep your teams organized</span>
      <Button onClick={() => showModal()}>Create Project</Button>
      {renderModal()}
    </div>
  )
}
