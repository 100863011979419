import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  assessorInviteRecipient,
  deleteQuestionnaire,
  finalizeQuestionnaire,
  getQuestionnaire,
  getQuestionnaireArtifactPreviewUrl,
  listQuestionnaire,
  ongoingQuestionnairesSummary,
  updateQuestionnaireMetadata,
} from '@/gen/questionnaire/v1/assessor_service-AssessorService_connectquery'

import { useTrackCallback } from '@/lib/analytics/events'

import { useToast } from '@/components/ui/use-toast'

export const useGetQuestionnaire = ({ questionnaireId }: { questionnaireId: string }) => {
  const queryState = useQuery(getQuestionnaire.useQuery({ id: questionnaireId }))
  return {
    ...queryState,
    questionnaire: queryState.data?.questionnaire,
  }
}

export const useListQuestionnaire = ({
  companyId,
  withInactive,
  ongoingAssessmentId,
}: {
  companyId: string
  withInactive: boolean
  ongoingAssessmentId?: string
}) => {
  const queryState = useQuery(
    listQuestionnaire.useQuery({
      orgCompanyId: companyId,
      withInactive,
      ongoingAssessmentId,
    }),
  )

  return {
    ...queryState,
    questionnaires: queryState.data?.questionnaires || [],
  }
}

export const useDeleteQuestionnaire = () => {
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { queryKey: listQuestionnaireKey } = listQuestionnaire.useQuery()

  return useMutation(deleteQuestionnaire.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(listQuestionnaireKey)
      toast({
        status: 'success',
        title: 'Questionnaire Deleted Successfully',
      })
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'An error has occurred',
      })
    },
  })
}

export const useGetQuestionnaireArtifactPreviewUrl = ({
  questionnaireArtifactId,
  filename,
}: {
  questionnaireArtifactId: string
  filename: string
}) => {
  const { queryKey, queryFn } = getQuestionnaireArtifactPreviewUrl.useQuery({
    questionnaireArtifactId,
    filename,
  })

  const queryState = useQuery(queryKey, queryFn, {
    staleTime: 300000, // 5 minutes it's the expiration time of the preview URL
  })

  return { ...queryState, previewUrl: queryState.data?.previewUrl }
}

export const useAssessorInviteRecipient = (companyName: string) => {
  const queryClient = useQueryClient()
  const trackCallback = useTrackCallback('questionnaire.invite.send')
  const { queryKey: getQuestionnaireKey } = getQuestionnaire.useQuery()
  const { queryKey: listQuestionnaireKey } = listQuestionnaire.useQuery()
  return useMutation(assessorInviteRecipient.useMutation().mutationFn, {
    onSuccess: (_, { recipients }) => {
      queryClient.invalidateQueries(getQuestionnaireKey)
      queryClient.invalidateQueries(listQuestionnaireKey)
      trackCallback({
        inviterRole: 'assessor',
        recipientsCount: recipients?.length ?? 0,
        companyName,
      })
    },
  })
}

export const useMutateFinalizeQuestionnaire = () => {
  const queryClient = useQueryClient()
  const { queryKey: getQuestionnaireKey } = getQuestionnaire.useQuery()
  const { queryKey: listQuestionnaireKey } = listQuestionnaire.useQuery()
  return useMutation(finalizeQuestionnaire.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQuestionnaireKey)
      queryClient.invalidateQueries(listQuestionnaireKey)
    },
  })
}

export const useUpdateQuestionnaireMetadata = () => {
  const queryClient = useQueryClient()
  const { queryKey: getQuestionnaireKey } = getQuestionnaire.useQuery()
  const { queryKey: listQuestionnaireKey } = listQuestionnaire.useQuery()
  return useMutation(updateQuestionnaireMetadata.useMutation().mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQuestionnaireKey)
      queryClient.invalidateQueries(listQuestionnaireKey)
    },
  })
}

export const useOngoingQuestionnairesSummary = (companyId: string) => {
  const queryState = useQuery(ongoingQuestionnairesSummary.useQuery({ orgCompanyId: companyId }))
  return { ...queryState, summary: queryState.data?.questionnaireStatusSummary }
}
