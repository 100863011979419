import { ClassNameValue } from 'tailwind-merge'

import { useGetUserById } from '@/api/get-user-by-id.hook'

import { getUserInitials } from '@/lib/string-utils'
import { cn } from '@/lib/style-helpers'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Tooltip } from '@/components/ui/tooltip'

type UserAvatarProps = {
  userId: string
  showEmail?: boolean
  className?: ClassNameValue
  withTooltip?: boolean
}

export const UserAvatar = ({
  userId,
  showEmail,
  className,
  withTooltip = true,
}: UserAvatarProps) => {
  const { user } = useGetUserById(userId)
  const userInitials = getUserInitials(user?.name ?? '')
  const avatar = (
    <Avatar className={cn('size-6 bg-gray-200 text-xs', className)}>
      <AvatarImage
        src={user?.avatarUrl}
        alt={user?.name}
        title={user?.name}
        className={cn(className)}
      />
      <AvatarFallback>{userInitials}</AvatarFallback>
    </Avatar>
  )
  if (!withTooltip) return avatar

  return (
    <div className={'flex items-center gap-2'}>
      <Tooltip trigger={avatar}>
        <div className={cn('flex flex-col items-center gap-1', showEmail && 'px-3 py-1.5')}>
          <span className={cn(showEmail && 'font-semibold')}>{user?.name}</span>
          {showEmail && <span>{user?.email}</span>}
        </div>
      </Tooltip>
    </div>
  )
}
