import { CoralogixRum } from '@coralogix/browser'
import type { CoralogixBrowserSdkConfig, UserContextConfig } from '@coralogix/browser/src/types'
import { UserResponse } from '@descope/core-js-sdk'

export type BuildMode = 'development' | 'staging' | 'production' | 'production_us'

const hostnameReplacer = (url: string) => {
  const urlObj = new URL(url)
  const hostname = urlObj.hostname
  return url.replace(hostname, '{lemmata}')
}

const getApplicationName = () => {
  const env = import.meta.env.VITE_CGX_ENV
  if (env === 'dev') {
    const namespace = getNamespace()
    if (namespace) {
      return `dev/${namespace}`
    }
  }
  return import.meta.env.VITE_CGX_APPLICATION_NAME
}

export const coralogixOptions = (buildMode: BuildMode): CoralogixBrowserSdkConfig => {
  const opts: CoralogixBrowserSdkConfig = {
    public_key: import.meta.env.VITE_CGX_PUBLIC_KEY,
    application: getApplicationName(),
    version: import.meta.env.VITE_BUILD_VERSION,
    coralogixDomain: import.meta.env.VITE_CGX_DOMAIN,
    debug: false,
    environment: buildMode,
    beforeSend: (event) => {
      if (event.event_context.type === 'error') {
        const id = CoralogixRum.screenshot('creating a screenshot due to an error!')
        event.screenshotId = id
      }

      return event
    },
    urlBlueprinters: {
      pageUrlBlueprinters: [hostnameReplacer],
      networkUrlBlueprinters: [hostnameReplacer],
    },
    traceParentInHeader: {
      enabled: true,
      options: {
        propagateTraceHeaderCorsUrls: [
          /https:\/\/us\.lema\.ai.*/,
          /https:\/\/eu\.lema\.ai.*/,
          /https:\/\/uk1\.lema\.ai.*/,
          /https:\/\/df\.lemmata\.dev.*/,
        ],
        allowedTracingUrls: [
          /https:\/\/(?!auth(?:eu)?\.lema\.ai).*lema\.ai(?!\/analytics)(?!\/questionnaire-uploads)/,
          /https:\/\/.*\.lemmata\.dev(?!\/questionnaire-uploads)/,
        ],
      },
    },
    networkExtraConfig: [
      {
        url: /https:\/\/(?!auth(?:eu)?\.lema\.ai).*lema\.ai(?!\/analytics)/,
        collectReqPayload: true,
        collectResPayload: true,
      },
      {
        url: /https:\/\/.*\.lemmata\.dev/,
        collectReqPayload: true,
        collectResPayload: true,
      },
    ],
    sessionRecordingConfig: {
      enable: true,
      autoStartSessionRecording: true,
      recordConsoleEvents: true,
      sessionRecordingSampleRate: 100,
    },
    sessionConfig: {
      alwaysTrackSessionsWithErrors: true,
      keepSessionAfterReload: true,
      sessionSampleRate: 100,
    },
    instrumentations: {
      errors: true,
      fetch: true,
      xhr: false,
      custom: true,
      long_tasks: true,
      resources: false,
      interactions: true,
      web_vitals: true,
    },
    ignoreUrls: [
      /.*\\.tsx?/, // will ignore all requests match this regex - all tsx files
      /.*\\.svg/, // will ignore all requests match this regex - all svg files
      /.*\\.png/, // will ignore all requests match this regex - all png files
      /.*\\.jpg/, // will ignore all requests match this regex - all jpg files
      /.*\\.jpeg/, // will ignore all requests match this regex - all jpeg files
      /.*\\.gif/, // will ignore all requests match this regex - all gif files
      /.*\\.webp/, // will ignore all requests match this regex - all webp files
      /.*\\.ico/, // will ignore all requests match this regex - all ico files
      /.*\\.woff/, // will ignore all requests match this regex - all woff files
      /.*posthog\.com/, // will ignore all requests match this regex - posthog
      /.*datadoghq.*/, // will ignore all requests match this regex - datadog
      /https?:\/\/[^/]*\/src.*/, // will ignore all requests match this regex - src files
      /https?:\/\/[^/]*\/@fs.*/, // will ignore all requests match this regex - @fs files
      /https?:\/\/[^/]*\/v3.analytics/, // will ignore all requests match this regex - v3.analytics
      /https?:\/\/[^/]*\/v2.analytics/, // will ignore all requests match this regex - v2.analytics
      /https?:\/\/[^/]*\/analytics/, // will ignore all requests match this regex - v1.analytics
      /https?:\/\/static\.descope\.com.*/, // will ignore all requests match this regex - descope static files
    ],
  }

  if (buildMode === 'staging' || buildMode === 'production' || buildMode === 'production_us') {
    opts.proxyUrl = '/v3.analytics'
  }

  return opts
}

const getNamespace = () => {
  const hostname = window.location.hostname
  const namespace = hostname.match(/gateway-(?<namespace>.*)\.sdlc\.lemmata\.dev/)?.groups
    ?.namespace
  return namespace
}

export const coralogixUserContext = (user: UserResponse): UserContextConfig => {
  if (!user) {
    return {
      user_id: '',
      user_email: '',
      user_name: '',
      user_metadata: {},
    }
  }
  return {
    user_id: user.userId,
    user_email: user.email,
    user_name: user.name || '',
    user_metadata: {
      roles: user.roleNames,
      org_ids: user.userTenants?.map((ut) => ut.tenantId),
      org_names: user.userTenants?.map((ut) => ut.tenantName),
    },
  }
}
