// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file inventory/v1/assessment_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateProjectRequest, CreateProjectResponse, DeleteProjectRequest, DeleteProjectResponse, FinalizeAssessmentRequest, FinalizeAssessmentResponse, GetAssessmentRequest, GetAssessmentResponse, GetProjectRequest, GetProjectResponse, GetRoomByAssessmentIdRequest, GetRoomByAssessmentIdResponse, GetTokenRequest, GetTokenResponse, ListAssessmentCadenceSettingsRequest, ListAssessmentCadenceSettingsResponse, ListAssessmentsRequest, ListAssessmentsResponse, ListOngoingAssessmentsRequest, ListOngoingAssessmentsResponse, ListProjectsRequest, ListProjectsResponse, SetAssessmentCadenceSettingRequest, SetAssessmentCadenceSettingResponse, SetProjectControlScopeRequest, SetProjectControlScopeResponse, SetProjectMemberRequest, SetProjectMemberResponse, StartAssessmentRequest, StartAssessmentResponse, UpdateProjectNameRequest, UpdateProjectNameResponse } from "./assessment_service_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "inventory.v1.AssessmentService";

/**
 * @generated from service inventory.v1.AssessmentService
 */
export const AssessmentService = {
  typeName: "inventory.v1.AssessmentService",
  methods: {
    /**
     * @generated from rpc inventory.v1.AssessmentService.StartAssessment
     */
    startAssessment: {
      name: "StartAssessment",
      I: StartAssessmentRequest,
      O: StartAssessmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.FinalizeAssessment
     */
    finalizeAssessment: {
      name: "FinalizeAssessment",
      I: FinalizeAssessmentRequest,
      O: FinalizeAssessmentResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.ListAssessments
     */
    listAssessments: {
      name: "ListAssessments",
      I: ListAssessmentsRequest,
      O: ListAssessmentsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.ListOngoingAssessments
     */
    listOngoingAssessments: {
      name: "ListOngoingAssessments",
      I: ListOngoingAssessmentsRequest,
      O: ListOngoingAssessmentsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.GetAssessment
     */
    getAssessment: {
      name: "GetAssessment",
      I: GetAssessmentRequest,
      O: GetAssessmentResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.GetRoomByAssessmentId
     */
    getRoomByAssessmentId: {
      name: "GetRoomByAssessmentId",
      I: GetRoomByAssessmentIdRequest,
      O: GetRoomByAssessmentIdResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.GetToken
     */
    getToken: {
      name: "GetToken",
      I: GetTokenRequest,
      O: GetTokenResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.ListProjects
     */
    listProjects: {
      name: "ListProjects",
      I: ListProjectsRequest,
      O: ListProjectsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.GetProject
     */
    getProject: {
      name: "GetProject",
      I: GetProjectRequest,
      O: GetProjectResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.ListAssessmentCadenceSettings
     */
    listAssessmentCadenceSettings: {
      name: "ListAssessmentCadenceSettings",
      I: ListAssessmentCadenceSettingsRequest,
      O: ListAssessmentCadenceSettingsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.SetAssessmentCadenceSetting
     */
    setAssessmentCadenceSetting: {
      name: "SetAssessmentCadenceSetting",
      I: SetAssessmentCadenceSettingRequest,
      O: SetAssessmentCadenceSettingResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.CreateProject
     */
    createProject: {
      name: "CreateProject",
      I: CreateProjectRequest,
      O: CreateProjectResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.DeleteProject
     */
    deleteProject: {
      name: "DeleteProject",
      I: DeleteProjectRequest,
      O: DeleteProjectResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.UpdateProjectName
     */
    updateProjectName: {
      name: "UpdateProjectName",
      I: UpdateProjectNameRequest,
      O: UpdateProjectNameResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.SetProjectMember
     */
    setProjectMember: {
      name: "SetProjectMember",
      I: SetProjectMemberRequest,
      O: SetProjectMemberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc inventory.v1.AssessmentService.SetProjectControlScope
     */
    setProjectControlScope: {
      name: "SetProjectControlScope",
      I: SetProjectControlScopeRequest,
      O: SetProjectControlScopeResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

const $queryService = createQueryService({  service: AssessmentService,});

/**
 * @generated from rpc inventory.v1.AssessmentService.StartAssessment
 */
export const startAssessment: UnaryFunctionsWithHooks<StartAssessmentRequest, StartAssessmentResponse> = {   ...$queryService.startAssessment,  ...createUnaryHooks($queryService.startAssessment)};

/**
 * @generated from rpc inventory.v1.AssessmentService.FinalizeAssessment
 */
export const finalizeAssessment: UnaryFunctionsWithHooks<FinalizeAssessmentRequest, FinalizeAssessmentResponse> = {   ...$queryService.finalizeAssessment,  ...createUnaryHooks($queryService.finalizeAssessment)};

/**
 * @generated from rpc inventory.v1.AssessmentService.ListAssessments
 */
export const listAssessments: UnaryFunctionsWithHooks<ListAssessmentsRequest, ListAssessmentsResponse> = {   ...$queryService.listAssessments,  ...createUnaryHooks($queryService.listAssessments)};

/**
 * @generated from rpc inventory.v1.AssessmentService.ListOngoingAssessments
 */
export const listOngoingAssessments: UnaryFunctionsWithHooks<ListOngoingAssessmentsRequest, ListOngoingAssessmentsResponse> = {   ...$queryService.listOngoingAssessments,  ...createUnaryHooks($queryService.listOngoingAssessments)};

/**
 * @generated from rpc inventory.v1.AssessmentService.GetAssessment
 */
export const getAssessment: UnaryFunctionsWithHooks<GetAssessmentRequest, GetAssessmentResponse> = {   ...$queryService.getAssessment,  ...createUnaryHooks($queryService.getAssessment)};

/**
 * @generated from rpc inventory.v1.AssessmentService.GetRoomByAssessmentId
 */
export const getRoomByAssessmentId: UnaryFunctionsWithHooks<GetRoomByAssessmentIdRequest, GetRoomByAssessmentIdResponse> = {   ...$queryService.getRoomByAssessmentId,  ...createUnaryHooks($queryService.getRoomByAssessmentId)};

/**
 * @generated from rpc inventory.v1.AssessmentService.GetToken
 */
export const getToken: UnaryFunctionsWithHooks<GetTokenRequest, GetTokenResponse> = {   ...$queryService.getToken,  ...createUnaryHooks($queryService.getToken)};

/**
 * @generated from rpc inventory.v1.AssessmentService.ListProjects
 */
export const listProjects: UnaryFunctionsWithHooks<ListProjectsRequest, ListProjectsResponse> = {   ...$queryService.listProjects,  ...createUnaryHooks($queryService.listProjects)};

/**
 * @generated from rpc inventory.v1.AssessmentService.GetProject
 */
export const getProject: UnaryFunctionsWithHooks<GetProjectRequest, GetProjectResponse> = {   ...$queryService.getProject,  ...createUnaryHooks($queryService.getProject)};

/**
 * @generated from rpc inventory.v1.AssessmentService.ListAssessmentCadenceSettings
 */
export const listAssessmentCadenceSettings: UnaryFunctionsWithHooks<ListAssessmentCadenceSettingsRequest, ListAssessmentCadenceSettingsResponse> = {   ...$queryService.listAssessmentCadenceSettings,  ...createUnaryHooks($queryService.listAssessmentCadenceSettings)};

/**
 * @generated from rpc inventory.v1.AssessmentService.SetAssessmentCadenceSetting
 */
export const setAssessmentCadenceSetting: UnaryFunctionsWithHooks<SetAssessmentCadenceSettingRequest, SetAssessmentCadenceSettingResponse> = {   ...$queryService.setAssessmentCadenceSetting,  ...createUnaryHooks($queryService.setAssessmentCadenceSetting)};

/**
 * @generated from rpc inventory.v1.AssessmentService.CreateProject
 */
export const createProject: UnaryFunctionsWithHooks<CreateProjectRequest, CreateProjectResponse> = {   ...$queryService.createProject,  ...createUnaryHooks($queryService.createProject)};

/**
 * @generated from rpc inventory.v1.AssessmentService.DeleteProject
 */
export const deleteProject: UnaryFunctionsWithHooks<DeleteProjectRequest, DeleteProjectResponse> = {   ...$queryService.deleteProject,  ...createUnaryHooks($queryService.deleteProject)};

/**
 * @generated from rpc inventory.v1.AssessmentService.UpdateProjectName
 */
export const updateProjectName: UnaryFunctionsWithHooks<UpdateProjectNameRequest, UpdateProjectNameResponse> = {   ...$queryService.updateProjectName,  ...createUnaryHooks($queryService.updateProjectName)};

/**
 * @generated from rpc inventory.v1.AssessmentService.SetProjectMember
 */
export const setProjectMember: UnaryFunctionsWithHooks<SetProjectMemberRequest, SetProjectMemberResponse> = {   ...$queryService.setProjectMember,  ...createUnaryHooks($queryService.setProjectMember)};

/**
 * @generated from rpc inventory.v1.AssessmentService.SetProjectControlScope
 */
export const setProjectControlScope: UnaryFunctionsWithHooks<SetProjectControlScopeRequest, SetProjectControlScopeResponse> = {   ...$queryService.setProjectControlScope,  ...createUnaryHooks($queryService.setProjectControlScope)};
