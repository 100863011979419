// @generated by protoc-gen-connect-query v0.5.3 with parameter "target=ts"
// @generated from file inventory/v1/artifact_service.proto (package inventory.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListCompanyArtifactRequest, ListCompanyArtifactResponse, ListSubprocessorsRequest, ListSubprocessorsResponse, ListUserSourcedArtifactRequest, ListUserSourcedArtifactResponse } from "./artifact_service_pb.js";
import { MethodIdempotency, MethodKind } from "@bufbuild/protobuf";
import { createQueryService, createUnaryHooks, UnaryFunctionsWithHooks } from "@connectrpc/connect-query";

export const typeName = "inventory.v1.ArtifactService";

/**
 * @generated from service inventory.v1.ArtifactService
 */
export const ArtifactService = {
  typeName: "inventory.v1.ArtifactService",
  methods: {
    /**
     * @generated from rpc inventory.v1.ArtifactService.ListUserSourcedArtifact
     */
    listUserSourcedArtifact: {
      name: "ListUserSourcedArtifact",
      I: ListUserSourcedArtifactRequest,
      O: ListUserSourcedArtifactResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * @generated from rpc inventory.v1.ArtifactService.ListCompanyArtifact
     */
    listCompanyArtifact: {
      name: "ListCompanyArtifact",
      I: ListCompanyArtifactRequest,
      O: ListCompanyArtifactResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
    /**
     * This not really a part of the ArtifactService, We consider removing it or moving it to a different service
     *
     * @generated from rpc inventory.v1.ArtifactService.ListSubprocessors
     */
    listSubprocessors: {
      name: "ListSubprocessors",
      I: ListSubprocessorsRequest,
      O: ListSubprocessorsResponse,
      kind: MethodKind.Unary,
      idempotency: MethodIdempotency.NoSideEffects,
    },
  }
} as const;

const $queryService = createQueryService({  service: ArtifactService,});

/**
 * @generated from rpc inventory.v1.ArtifactService.ListUserSourcedArtifact
 */
export const listUserSourcedArtifact: UnaryFunctionsWithHooks<ListUserSourcedArtifactRequest, ListUserSourcedArtifactResponse> = {   ...$queryService.listUserSourcedArtifact,  ...createUnaryHooks($queryService.listUserSourcedArtifact)};

/**
 * @generated from rpc inventory.v1.ArtifactService.ListCompanyArtifact
 */
export const listCompanyArtifact: UnaryFunctionsWithHooks<ListCompanyArtifactRequest, ListCompanyArtifactResponse> = {   ...$queryService.listCompanyArtifact,  ...createUnaryHooks($queryService.listCompanyArtifact)};

/**
 * This not really a part of the ArtifactService, We consider removing it or moving it to a different service
 *
 * @generated from rpc inventory.v1.ArtifactService.ListSubprocessors
 */
export const listSubprocessors: UnaryFunctionsWithHooks<ListSubprocessorsRequest, ListSubprocessorsResponse> = {   ...$queryService.listSubprocessors,  ...createUnaryHooks($queryService.listSubprocessors)};
