import { type VariantProps, cva } from 'class-variance-authority'
import { ChevronRight } from 'lucide-react'
import { ReactNode, useEffect, useMemo, useState } from 'react'

import { cn } from '@/lib/style-helpers'
import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { Loading } from '@/components/loading'
import { TextWithIcon } from '@/components/text-with-icon'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Tooltip } from '@/components/ui/tooltip'

import { TabItem, TabsProps, getTabItemIndex } from './types'

const triggerVariant = cva('h-9 text-base', {
  variants: {
    variant: {
      default:
        'hover:bg-gray-100 rounded data-[state=active]:bg-gray-700 data-[state=active]:text-white data-[state=active]:shadow-sm',
      secondary:
        'border-b-2 border-transparent hover:text-purple-600 data-[state=active]:border-purple-500 [&[data-state=active]_svg]:text-purple-600 [&_svg]:text-gray-400 [&_svg]:hover:text-purple-600',
    },
  },
})

const tabsListVariant = cva('mb-6 flex h-fit w-full justify-between gap-4 rounded px-9 py-2  ', {
  variants: {
    variant: {
      default: 'bg-gray-50 text-gray-500',
      secondary: 'text-gray-700',
    },
  },
})

type HorizontalTabsItem = 'separator' | 'arrow' | ReactNode | TabItem

type HorizontalTabsProps = TabsProps<HorizontalTabsItem> & {
  disabled?: boolean
  isLoading?: boolean
  urlParamKey?: UrlParam
  onChange?: (tab: string) => void
} & VariantProps<typeof triggerVariant>

export const HorizontalTabs = ({
  items,
  className,
  disabled,
  isLoading = false,
  urlParamKey,
  actionItem,
  variant = 'default',
  onChange,
}: HorizontalTabsProps) => {
  const { urlParams, updateParam } = useUrlParams()
  const [value, setValue] = useState<string | undefined>(undefined)

  const handleValueChange = (value: string) => {
    setValue(value)
    if (urlParamKey) {
      updateParam(urlParamKey, value)
    }
    onChange?.(value)
  }

  const isTabItem = (item: HorizontalTabsItem): item is TabItem =>
    typeof item === 'object' && item !== null && 'label' in item && !('type' in item)

  const defaultItem = items.filter(isTabItem).find(({ itemDisabled }) => !itemDisabled)
  const defaultIndex = defaultItem && getTabItemIndex(defaultItem)
  const initialValue = urlParamKey ? urlParams[urlParamKey] || defaultIndex : defaultIndex

  const currentTab = useMemo(() => {
    return items.find((item) => isTabItem(item) && getTabItemIndex(item) === value)
  }, [items, value])
  useEffect(() => {
    if (isTabItem(currentTab) && currentTab.itemDisabled) {
      setValue(defaultIndex)
      if (urlParamKey && defaultIndex) {
        updateParam(urlParamKey, defaultIndex)
      }
    } else if (urlParamKey && urlParams[urlParamKey]) {
      setValue(urlParams[urlParamKey])
    }
  }, [urlParamKey, defaultIndex, items, urlParams, updateParam, currentTab])

  return (
    <Tabs
      className='h-full'
      defaultValue={initialValue}
      value={value}
      onValueChange={handleValueChange}
    >
      <TabsList className={cn(tabsListVariant({ variant }), className)}>
        <div className='flex justify-start gap-4'>
          {items.map((tabItem, i) => {
            if (tabItem === 'separator') {
              return <div key={i} className='h-8 w-px bg-gray-200' />
            }

            if (tabItem === 'arrow') {
              return <ChevronRight key={i} strokeWidth={1.5} className='my-auto text-gray-300' />
            }

            if (!isTabItem(tabItem)) {
              return tabItem
            }

            const index = getTabItemIndex(tabItem)
            const { label, itemDisabled, labelIcon, disabledTooltip } = tabItem
            return (
              <Tooltip
                key={index}
                tooltipDisabled={!disabledTooltip || !itemDisabled}
                trigger={
                  <TabsTrigger
                    key={index}
                    disabled={disabled || itemDisabled}
                    className={triggerVariant({ variant })}
                    value={index}
                  >
                    <TextWithIcon icon={labelIcon} text={label} />
                  </TabsTrigger>
                }
              >
                {disabledTooltip}
              </Tooltip>
            )
          })}
        </div>
        {actionItem}
      </TabsList>
      {isLoading ? (
        <Loading />
      ) : (
        items.map((tabItem) => {
          if (!isTabItem(tabItem)) {
            return null
          }

          const index = getTabItemIndex(tabItem)
          return (
            <TabsContent className='h-full px-8' key={index} value={index}>
              {tabItem.component}
            </TabsContent>
          )
        })
      )}
    </Tabs>
  )
}
