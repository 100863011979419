import { useCallback, useState } from 'react'

import { FilterType } from '@/components/table/table.type'

export type valueRender = (value: string, isTag?: boolean) => React.ReactNode

export type FilteredState = Record<
  React.Key,
  {
    value: string[] | null
    valueRender?: valueRender
    filterType?: FilterType
  }
>

export type SetSelectedFn = (
  filterKey: React.Key,
  value: string,
  selected: boolean,
  valueRender?: valueRender,
  filterType?: FilterType,
) => void

export type FilterContext = {
  showFilterTags: boolean
  setShowFilterTags: (showFilterTags: boolean) => void
  filteredState: FilteredState
  setSelected: SetSelectedFn
  toggleSelected: (filterKey: React.Key, value: string, valueRender?: valueRender) => void
  isSelected: (filterKey: React.Key, value: string) => boolean
  clearFilter: (filterKey: React.Key) => void
  clearFilters: () => void
}

export const useFilter = (): FilterContext => {
  const [showFilterTags, setShowFilterTags] = useState<boolean>(false)
  const [filteredState, setFilters] = useState<FilteredState>({})

  const addFilter = useCallback(
    (filterKey: React.Key, value: string, valueRender?: valueRender, filterType?: FilterType) =>
      setFilters((prevFilters) => {
        const currentState = prevFilters[filterKey] || { value: [], valueRender, filterType }
        return {
          ...prevFilters,
          [filterKey]: {
            ...currentState,
            value: [...(currentState.value || []), value],
            valueRender,
            filterType,
          },
        }
      }),
    [],
  )

  const removeFilter = useCallback((filterKey: React.Key, value: string) => {
    setFilters((prevFilters) => {
      const currentState = prevFilters[filterKey]
      if (!currentState) return prevFilters

      const updatedValues = currentState.value?.filter((item) => item !== value) || []
      const updatedFilters = { ...prevFilters }

      if (updatedValues.length === 0) {
        delete updatedFilters[filterKey]
      } else {
        updatedFilters[filterKey] = {
          ...currentState,
          value: updatedValues,
        }
      }

      if (Object.keys(updatedFilters).length === 0) {
        setShowFilterTags(false)
      }
      return updatedFilters
    })
  }, [])

  const setSelected = useCallback(
    (
      filterKey: React.Key,
      value: string,
      selected: boolean,
      valueRender?: valueRender,
      filterType?: FilterType,
    ) => {
      if (selected) {
        addFilter(filterKey, value, valueRender, filterType)
      } else {
        removeFilter(filterKey, value)
      }
    },
    [addFilter, removeFilter],
  )

  const isSelected = useCallback(
    (filterKey: React.Key, value: string) => {
      return !!(filteredState[filterKey]?.value || []).includes(value)
    },
    [filteredState],
  )

  const toggleSelected = useCallback(
    (filterKey: React.Key, value: string, valueRender?: valueRender) => {
      setSelected(filterKey, value, !isSelected(filterKey, value), valueRender)
    },
    [setSelected, isSelected],
  )

  const clearFilter = useCallback((filterKey: React.Key) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters }
      if (updatedFilters[filterKey]) {
        updatedFilters[filterKey] = {
          ...updatedFilters[filterKey],
          value: null,
        }
      }
      return updatedFilters
    })
  }, [])

  const clearFilters = useCallback(() => {
    setFilters({})
    setShowFilterTags(false)
  }, [])

  return {
    showFilterTags,
    setShowFilterTags,
    filteredState,
    setSelected,
    toggleSelected,
    isSelected,
    clearFilter,
    clearFilters,
  }
}
