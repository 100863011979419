export type AssessmentDueStatus = 'upToDate' | 'dueSoon' | 'overdue' | 'noDueDate'

export const calculateAssessmentDueStatus = (dueDate?: Date): AssessmentDueStatus => {
  const now = new Date()
  const oneMonthFromNow = new Date()
  oneMonthFromNow.setMonth(now.getMonth() + 1)
  if (!dueDate) {
    return 'noDueDate'
  }

  if (dueDate < now) {
    return 'overdue'
  }

  if (dueDate <= oneMonthFromNow) {
    return 'dueSoon'
  }

  return 'upToDate'
}

export const calculateAssessmentDueDate = (statusLastUpdate?: Date, cadenceSeconds?: bigint) => {
  if (!statusLastUpdate || !cadenceSeconds) {
    return undefined
  }

  return new Date(statusLastUpdate.getTime() + Number(cadenceSeconds) * 1000)
}
