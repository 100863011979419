import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { NextAssessmentAction } from '@/pages/company-drawer/company-overview/next-assessment-action'
import { useUpcomingPreAssessmentStep } from '@/pages/company-drawer/use-upcoming-pre-assessment-step'

type NextPreAssessmentActionButtonProps = {
  companyId: string
  status: CompanyStatus
  from: string
}

export const NextPreAssessmentActionButton = ({
  companyId,
  status,
  from,
}: NextPreAssessmentActionButtonProps) => {
  const upcomingStep = useUpcomingPreAssessmentStep(companyId, status)

  if (!upcomingStep) {
    return null
  }

  return (
    <NextAssessmentAction
      from={from}
      upcomingStep={upcomingStep}
      companyId={companyId}
      variant={'link'}
    />
  )
}
