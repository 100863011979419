import LemaLogo from '@/assets/lema-logo-inverse.svg?url'
import { useQuery } from '@tanstack/react-query'

import { getUserById } from '@/gen/customer/management/v1/service-CustomerManagementService_connectquery'
import { User } from '@/gen/customer/management/v1/service_pb'

const LemaActor = 'Lema'

export const useGetUserById = (userId: string) => {
  const queryState = useQuery({
    ...getUserById.useQuery({ userId }),
    enabled: userId !== LemaActor,
  })
  if (userId === LemaActor) {
    return {
      user: {
        id: LemaActor,
        name: LemaActor,
        avatarUrl: LemaLogo,
      } as User,
    }
  }

  return {
    ...queryState,
    user: queryState.data?.user,
  }
}
