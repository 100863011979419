import _ from 'lodash'
import React, { useMemo } from 'react'

import { useListInherentRiskCategories } from '@/api/company.hook'
import { InherentRiskCategoryEnum, InherentRiskItem } from '@/gen/inventory/v1/company_service_pb'

import { InherentRiskCategoryEnumTitle } from '@/const/label'
import { sortRiskLevel } from '@/const/priority'

import { useInherentRiskItems } from '@/lib/use-inherent-risk-items'

import { IrqCategorySelect } from '@/pages/assessment/irq/irq-category-select'
import { UnknownError } from '@/pages/error-pages/unknown-error'

import { Loading } from '@/components/loading'
import { Separator } from '@/components/ui/separator'

type IrqProps = {
  companyId: string
}

const SingleSelectCategories = [
  InherentRiskCategoryEnum.SPEND,
  InherentRiskCategoryEnum.OUTAGE_BIZ_IMPACT,
  InherentRiskCategoryEnum.OUTAGE_OPERATIONAL_IMPACT,
  InherentRiskCategoryEnum.DATA_SUBPROCESSOR,
]

export const Irq = ({ companyId }: IrqProps) => {
  const { irqItems, lemaItems } = useInherentRiskItems(companyId)

  const {
    data: inherentRiskCategories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useListInherentRiskCategories(companyId)

  const categoryEnumToOptions = useMemo(
    () =>
      inherentRiskCategories.reduce(
        (acc, { categoryEnum, items }) => {
          acc[categoryEnum] = items
          return acc
        },
        {} as Record<InherentRiskCategoryEnum, InherentRiskItem[]>,
      ),
    [inherentRiskCategories],
  )

  const categoryToCategoryEnums = useMemo(
    () =>
      irqItems.reduce(
        (acc, { category, categoryEnum }) => {
          if (!acc[category]) acc[category] = []
          acc[category].push(categoryEnum)
          return acc
        },
        {} as Record<string, InherentRiskCategoryEnum[]>,
      ),
    [irqItems],
  )

  const getCategoryOptions = (categoryEnum: InherentRiskCategoryEnum): InherentRiskItem[] => {
    const options = categoryEnumToOptions[categoryEnum] || []
    // Subprocessor is the only boolean category, and 'No' isn't returned by the server
    // because it's the default value, so we add it here. This is a hacky solution, but it works
    if (categoryEnum === InherentRiskCategoryEnum.DATA_SUBPROCESSOR && options?.length === 1) {
      return [...options, { id: 'No', displayName: 'No' }] as InherentRiskItem[]
    }
    return options
  }

  const getSelectedOptions = (categoryEnum: InherentRiskCategoryEnum) => {
    const options = irqItems
      .find((irqItem) => irqItem.categoryEnum === categoryEnum)
      ?.inherentRiskItems.sort((a, b) => {
        const severityComparison = sortRiskLevel(a.severity, b.severity)
        return severityComparison === 0
          ? a.displayName.localeCompare(b.displayName)
          : severityComparison
      })

    // Subprocessor is the only boolean category, and we want 'No' to be selected by default
    if (categoryEnum === InherentRiskCategoryEnum.DATA_SUBPROCESSOR && options?.length === 0) {
      return [{ id: 'No', displayName: 'No' }] as InherentRiskItem[]
    }
    return options
  }

  if (categoriesLoading) return <Loading />
  if (categoriesError) return <UnknownError />

  return (
    <div>
      {_.entries(categoryToCategoryEnums).map(([category, categoryEnums], index) => {
        const isMultipleCategoryEnums = categoryEnums.length > 1
        return (
          <React.Fragment key={index}>
            <Separator className='my-8' />
            <h4 className='mb-2 text-nowrap text-lg font-bold'>{category}</h4>
            <div className='flex flex-col gap-3'>
              {categoryEnums.map((categoryEnum) => {
                const isMultipleSelect = !SingleSelectCategories.includes(categoryEnum)
                return (
                  <div key={categoryEnum}>
                    <div className='mb-1'>
                      {isMultipleCategoryEnums && (
                        <h5 className='text-nowrap font-semibold'>
                          {InherentRiskCategoryEnumTitle[categoryEnum]}:
                        </h5>
                      )}
                      <span>{categoryToQuestion[categoryEnum]}</span>
                    </div>

                    <IrqCategorySelect
                      suggestedOptions={lemaItems
                        .filter((irqItem) => irqItem.categoryEnum === categoryEnum)
                        .flatMap((irqItem) => irqItem.inherentRiskItems)}
                      companyId={companyId}
                      selectedOptions={getSelectedOptions(categoryEnum)}
                      categoryEnum={categoryEnum}
                      multipleSelect={isMultipleSelect}
                      options={getCategoryOptions(categoryEnum)}
                    />
                  </div>
                )
              })}
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

const categoryToQuestion: Record<InherentRiskCategoryEnum, string> = {
  [InherentRiskCategoryEnum.UNSPECIFIED]: '',
  [InherentRiskCategoryEnum.DATA]: 'What types of data does the third-party process or store?',
  [InherentRiskCategoryEnum.ATTACK_SURFACE]:
    'What permissions does the third-party have to your infrastructure?',
  [InherentRiskCategoryEnum.LOB]: 'Which departments use this third-party?',
  [InherentRiskCategoryEnum.OUTAGE_OPERATIONAL_IMPACT]:
    'What is the impact of the third-party outage on your internal operations?',
  [InherentRiskCategoryEnum.OUTAGE_BIZ_IMPACT]:
    'What is the impact of the third-party outage on your customers?',
  [InherentRiskCategoryEnum.INTEG_METHOD]:
    'How is the third-party integrated into your product or core services?',
  [InherentRiskCategoryEnum.SPEND]:
    'What is the estimated yearly budget allocated to this third-party?',
  [InherentRiskCategoryEnum.DATA_SUBPROCESSOR]: 'Is this third-party considered a sub-processor?',
}
