import { CompanyStatus } from '@/gen/inventory/v1/company_status_pb'

import { AssessmentFlowTab } from '@/pages/assessment/assessment-flow-step-enum'
import { useIsIrqCompleted } from '@/pages/assessment/irq/use-is-irq-completed'

export const useUpcomingPreAssessmentStep = (
  companyId: string,
  companyStatus?: CompanyStatus,
): AssessmentFlowTab | null => {
  const isIrqCompleted = useIsIrqCompleted(companyId)

  if (
    companyStatus === CompanyStatus.ASSESSMENT_REQUIRED ||
    companyStatus === CompanyStatus.RE_ASSESSMENT_REQUIRED
  ) {
    if (isIrqCompleted) {
      return AssessmentFlowTab.OVERVIEW
    }
  }

  if (!isIrqCompleted) {
    return AssessmentFlowTab.IRQ
  }

  return null
}
