import stc from 'string-to-color'

import { getUserInitials } from '@/lib/string-utils'
import { cn } from '@/lib/style-helpers'

import { Avatar, AvatarFallback } from '@/components/ui/avatar'

type StringNameAvatarProps = {
  name: string
  className?: string
}

export const StringNameAvatar = ({ name, className }: StringNameAvatarProps) => {
  const userInitials = getUserInitials(name)
  const color = stc(name)
  return (
    <Avatar className={cn('uppercase text-white', className)} style={{ backgroundColor: color }}>
      <AvatarFallback>{userInitials}</AvatarFallback>
    </Avatar>
  )
}
