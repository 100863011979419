import { Duration } from '@bufbuild/protobuf'
import { Select } from 'antd'

import { useListAssessmentCadenceSettings, useSetAssessmentCadence } from '@/api/assessments.hook'

import { sortRiskLevel } from '@/const/priority'

import { SECONDS_IN_A_YEAR, formatCadence } from '@/lib/date'

import { CompanyRiskBadge } from '@/components/badge/company-risk'

const options = [
  SECONDS_IN_A_YEAR,
  SECONDS_IN_A_YEAR * 2,
  SECONDS_IN_A_YEAR * 3,
  SECONDS_IN_A_YEAR * 4,
  SECONDS_IN_A_YEAR * 5,
  null,
]

export const AssessmentCadenceSection = () => {
  const { data: settings } = useListAssessmentCadenceSettings()
  const { mutate: setCadence } = useSetAssessmentCadence()

  return (
    <div>
      <h2 className='mb-5.5 self-start text-3xl font-bold'>Assessment</h2>
      <h3 className='mb-1 text-xl font-bold'>Assessment Cadence</h3>
      <p className='mb-8'>
        Set assessment cadences for your third-parties to help you track assessments.
      </p>
      <div className='flex flex-col gap-3'>
        <div className='mb-1 flex items-center gap-24'>
          <div className='w-24 text-xs font-bold uppercase tracking-widest text-gray-500'>
            Category
          </div>
          <div className='w-72 text-xs font-bold uppercase tracking-widest text-gray-500'>
            Cadence
          </div>
        </div>
        {settings?.settings
          .sort((a, b) => sortRiskLevel(a.riskLevel, b.riskLevel))
          .map((setting) => (
            <div key={setting.riskLevel} className='flex items-center gap-24'>
              <div className='w-24'>
                <CompanyRiskBadge className='p-0' riskLevel={setting.riskLevel} />
              </div>
              <Select
                className='w-72'
                size='large'
                value={formatCadence(setting.cadence?.seconds)}
                options={options.map((option) => ({
                  label: formatCadence(option),
                  value: option,
                }))}
                onSelect={(cadence) => {
                  setCadence({
                    riskLevel: setting.riskLevel,
                    cadence:
                      cadence === null ? undefined : new Duration({ seconds: BigInt(cadence) }),
                  })
                }}
              />
            </div>
          ))}
      </div>
    </div>
  )
}
