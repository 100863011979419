import { cva } from 'class-variance-authority'
import { CheckCircle2Icon, Clock, MinusIcon, TriangleAlertIcon } from 'lucide-react'

import { assessmentDueStatusLabel } from '@/const/label'

import type { AssessmentDueStatus } from '@/lib/assessment-due-status'
import { calculateAssessmentDueStatus } from '@/lib/assessment-due-status'

import { Badge } from '@/components/ui/badge'

const assessmentDueStatusIcon: Record<AssessmentDueStatus, React.ReactNode> = {
  upToDate: <CheckCircle2Icon className='size-3.5' />,
  dueSoon: <Clock className='size-3.5' />,
  overdue: <TriangleAlertIcon className='size-3.5' />,
  noDueDate: <MinusIcon className='size-3.5' />,
} as const

const AssessmentDueStatusIcon = ({ status }: { status: AssessmentDueStatus }) => {
  return assessmentDueStatusIcon[status]
}

const assessmentDueStatusBadgeVariant = cva(
  'flex w-fit text-md text-gray-700 items-center gap-1 rounded px-1.5 border-none h-4.5',
  {
    variants: {
      status: {
        upToDate: 'bg-green-50 text-green-500',
        dueSoon: 'p-0',
        overdue: 'bg-gray-100',
      },
    },
    defaultVariants: {
      status: 'upToDate',
    },
  },
)

type AssessmentDueStatusBadgeProps = {
  dueDate?: Date
}

export function AssessmentDueStatus({ dueDate }: AssessmentDueStatusBadgeProps) {
  const status = calculateAssessmentDueStatus(dueDate)

  if (status === 'noDueDate') {
    return <MinusIcon className='size-4' />
  }

  return (
    <Badge className={assessmentDueStatusBadgeVariant({ status })} variant='outline'>
      <AssessmentDueStatusIcon status={status} />
      <span className='text-nowrap'>{assessmentDueStatusLabel[status]}</span>
    </Badge>
  )
}
