import { PlainMessage } from '@bufbuild/protobuf'
import { CheckIcon } from 'lucide-react'
import { ReactElement } from 'react'

import { FindingCountStat } from '@/gen/inventory/v1/company_service_pb'
import { RiskLevel } from '@/gen/inventory/v1/risk_pb'

import { RISK_LEVELS } from '@/lib/proto-types'

import { RiskLevelIcon } from '@/components/icons/risk-severity'
import { TextWithIcon } from '@/components/text-with-icon'

type FindingDistributionProps = {
  findingCountByLevel: PlainMessage<FindingCountStat>[]
  hideZero?: boolean
  hideNumbers?: boolean
  noFindingsComponent?: ReactElement
}

export const FindingDistribution = ({
  findingCountByLevel,
  hideZero,
  hideNumbers,
  noFindingsComponent,
}: FindingDistributionProps) => {
  const countByLevel: {
    level: RiskLevel
    count: number
  }[] = []

  for (const { no: level } of RISK_LEVELS) {
    const count = findingCountByLevel.find((item) => item.riskLevel === level)?.count || 0
    if (hideZero && count === 0) {
      continue
    }

    countByLevel.push({
      level,
      count,
    })
  }

  return (
    <div className='flex flex-nowrap gap-4'>
      {countByLevel.length === 0
        ? noFindingsComponent || (
            <TextWithIcon
              className='gap-1.5 text-gray-500'
              text='No findings'
              icon={<CheckIcon size={14} className='text-green-500' />}
            />
          )
        : countByLevel.map(({ level, count }) => (
            <div key={level} className='flex flex-nowrap items-center gap-1'>
              <RiskLevelIcon level={level} />
              <span>{hideNumbers ? '-' : count}</span>
            </div>
          ))}
    </div>
  )
}
