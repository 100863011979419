import { ClipboardCheckIcon } from 'lucide-react'
import { useCallback } from 'react'

import { Project } from '@/gen/inventory/v1/assessment_service_pb'

import { UrlParam, useUrlParams } from '@/lib/url-param.hook'

import { AssessmentFlowTab } from '@/pages/assessment/assessment-flow-step-enum'
import { CompanyDrawerTabs } from '@/pages/company-drawer/company-drawer-tabs-enum'
import { ActionItem, ActionItemVariant } from '@/pages/company-drawer/company-overview/action-item'
import { useSetIrqModal } from '@/pages/company-drawer/company-overview/set-irq-modal/use-set-irq-modal'

import { StringNameAvatar } from '@/components/string-name-avatar'
import { TextWithIcon } from '@/components/text-with-icon'

type NextAssessmentActionProps = {
  upcomingStep: AssessmentFlowTab
  companyId: string
  project?: Project
  variant?: ActionItemVariant
  from: string
}

export const NextAssessmentAction = ({
  upcomingStep,
  companyId,
  project,
  variant = 'default',
  from,
}: NextAssessmentActionProps) => {
  const { replaceParams } = useUrlParams()
  const { showModal, renderModal } = useSetIrqModal({ companyId, from })
  const navigateToAssessment = useCallback(
    (step: AssessmentFlowTab) => {
      if (step === AssessmentFlowTab.IRQ) {
        showModal()
      } else {
        replaceParams({
          update: [
            [UrlParam.THIRD_PARTY_ID, companyId],
            [UrlParam.COMPANY_DRAWER_TAB, CompanyDrawerTabs.ASSESSMENT],
            [UrlParam.STEP, step],
            [UrlParam.PROJECT_ID, project?.id ?? ''],
          ],
        })
      }
    },
    [replaceParams, showModal, companyId, project],
  )

  const stepToText: Record<AssessmentFlowTab, string> = {
    overview: 'Start assessment',
    irq: 'Validate Inherent Risk',
    evidence: 'Assessment next step: Upload artifacts',
    controls: 'Assessment next step: Review controls',
    conclusion: 'Assessment next step: Add conclusion',
  }

  return (
    <>
      <ActionItem
        text={
          <TextWithIcon
            text={stepToText[upcomingStep]}
            icon={
              project?.name && (
                <StringNameAvatar name={project?.name} className='size-3.5 rounded text-xs' />
              )
            }
          />
        }
        icon={<ClipboardCheckIcon size={14} />}
        onClick={() => navigateToAssessment(upcomingStep)}
        variant={variant}
      />
      {renderModal()}
    </>
  )
}
